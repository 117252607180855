import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import TrackItemById from "./TrackItemFromId";
import NewTracksContext from "./newTracksContext";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class NewTracksDraggableList extends Component {
  static contextType = NewTracksContext;

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const tracks = reorder(
      this.context.tracks,
      result.source.index,
      result.destination.index
    );

    this.context.onUpdateOrder(tracks);
  };

  render() {
    return (
      <>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <p style={{ fontSize: "18px", marginTop: "30px" }}>Sequence:</p>

          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={
                  snapshot.isDraggingOver
                    ? {
                        backgroundColor: "rgba(0, 0, 0, 0.021)",
                        padding: "10px"
                      }
                    : { padding: "10px 20px" }
                }
              >
                {this.context.tracks.map((trackId, index) => (
                  <Draggable key={trackId} draggableId={trackId} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`row fxfItem ${
                          snapshot.isDragging ? "dragging" : ""
                        }`}
                      >
                        <TrackItemById id={trackId} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  }
}

export default NewTracksDraggableList;
