import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Spinner } from "../shared/Loading";
import draftToHtml from "draftjs-to-html";

const GETCONFIG = gql`
  query getConfig {
    getConfig {
      termsConditions
      privacy
      copyright
      why
      email
      founded
    }
  }
`;

const Terms = () => {
  return (
    <div className="container terms">
      <br />
      <br />
      <Query query={GETCONFIG}>
        {({ data, loading, error }) => {
          if (loading) return <Spinner />;
          if (error) return "Something went wrong :(";

          const { termsConditions } = data.getConfig;
          const terms = draftToHtml(JSON.parse(termsConditions));

          return <div dangerouslySetInnerHTML={{ __html: terms }} />;
        }}
      </Query>
    </div>
  );
};

export default Terms;
