import React from "react";

import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { LoadingBtn } from "../../shared/Loading";

// const CREATE_NEW_TRACKS_COLLECTION = gql`
//   mutation createNewTrack($date: String!, $content: [ID]!) {
//     createNewTrack(newTracks: { date: $date, content: $content }) {
//       id
//       tracks {
//         id
//       }
//     }
//   }
// `;

const SAVE_NEW_TRACKS = gql`
  mutation editNewTracks($date: String!, $content: [ID]!) {
    editNewTrack(newTracks: { date: $date, content: $content }) {
      id
      tracks {
        id
      }
    }
  }
`;

const SaveNewTracks = props => {
  const { showSave, tracks, onSavedChange, isListCreated } = props;
  return (
    <Mutation
      mutation={
        SAVE_NEW_TRACKS
        // isListCreated ? SAVE_NEW_TRACKS : CREATE_NEW_TRACKS_COLLECTION
      }
      onCompleted={data => {
        onSavedChange();
      }}
    >
      {(editNewTrack, { loading, error, data }) => {
        if (error) {
          console.log(error);
        }
        return (
          <>
            {loading ? (
              <LoadingBtn />
            ) : (
              showSave && (
                <button
                  onClick={() =>
                    editNewTrack({
                      variables: {
                        date: "2019-04-15T00:00:00.000Z",
                        content: [...tracks]
                      }
                    })
                  }
                  className="btn-black"
                >
                  {isListCreated ? "SAVE" : "CREATE"}
                </button>
              )
            )}
            {error && <span>Error :( </span>}
          </>
        );
      }}
    </Mutation>
  );
};

export default SaveNewTracks;
