import React from "react";
import IndexSearch from "./IndexSearch";

import { Query } from "react-apollo";
import IndexArtistsList from "./IndexArtistsList";
import IndexReleasesList from "./IndexReleasesList";
import { Spinner } from "../../shared/Loading";

import GET_ALL_RELEASES from "./GET_ALL_RELEASES_QUERY";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      releases: [],
      searchFor: "",
      showModal: false
    };
  }

  searchVal = val => this.setState({ searchFor: val });

  render() {
    return (
      <div className="row 4x4wrapper">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <br />
              <IndexSearch searchVal={this.searchVal} />
            </div>
          </div>

          <Query query={GET_ALL_RELEASES}>
            {({ data, loading, error }) => {
              if (loading) return <Spinner />;
              if (error) {
                console.log(error);
                return "something goes wrong";
              }
              const artists = data.getReleases.map(r => ({
                ...r.artist,
                label: r.label
              }));
              const releases = data.getReleases.map(r => ({ ...r }));
              return (
                <div className="row indexResults-row">
                  <IndexReleasesList
                    releases={releases}
                    searchFor={this.state.searchFor}
                  />
                  <IndexArtistsList
                    artists={artists}
                    searchFor={this.state.searchFor}
                  />
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }
}

export default IndexPage;
