import React from "react";
import { Mutation } from "react-apollo";

import queryString from "query-string";
import { gql } from "apollo-boost";
import { LoadingBtnLeft } from "../../shared/Loading";

const ADD_STRIPE_CODE = gql`
  mutation addStripeCode($code: String!) {
    addStripeCode(code: $code) {
      id
      accessToken
      livemode
      refreshToken
      tokenType
      stripePublishableKey
      stripeUserId
      scope
    }
  }
`;

const FinanceAuth = props => {
  const values = queryString.parse(props.location.search);
  const { code } = values;
  return (
    <div className="col-sm-12">
      <div>
        <br />
        <h3>Confirm stripe connection</h3>
        <p>This is last step. Please confirm to proceed</p>
        <br />
        <Mutation
          mutation={ADD_STRIPE_CODE}
          variables={{code: code}}
          onCompleted={data => {
            console.log(data);
            setTimeout(()=> {props.history.push("/dashboard/settings/finance");}, 2000)
            
          }}
        >
          {(addStripeCode, { data, loading, error }) => (
            <div>
              {loading ? (
                <LoadingBtnLeft />
              ) : (
                <button onClick={e => addStripeCode()} className="btn-black">
                  Confirm
                </button>
              )}
              {error && (
                <p>
                  Something went wrong :( <br />
                  Please try again or contact support
                </p>
              )}
              {data && (
                <p>
                  Succes! 
                </p>
              )}
            </div>
          )}
        </Mutation>
      </div>
    </div>
  );
};

export default FinanceAuth;
