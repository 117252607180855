import React from "react";
import { Switch, Route } from "react-router-dom";
import FinanceAuth from "./FinanceAuth";
import PaypalSignUp from "./PaypalSignUp";
// import { Query } from "react-apollo";

const FinanceContainer = props => {
  const me = props.me;
  return (
    <div className="container">
      <div className="row">
        <Switch>
          <Route
            exact
            path="/dashboard/settings/finance/auth"
            component={FinanceAuth}
          />

          <Route
            render={props => (
              <div className="col-sm-12">
                <PaypalSignUp {...me} />
              </div>
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

// ac_F9uOpgC8jorkBPCMDbWuj7rBU3LXSwie

export default FinanceContainer;
