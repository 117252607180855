import React from "react";
import ReactDom from "react-dom";
import PropTypes from 'prop-types';

const alertRoot = document.getElementById("alert-root");

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  el = document.createElement("div");
  
  componentDidMount() {
    alertRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    alertRoot.removeChild(this.el);
  }

  render() {
    return ReactDom.createPortal(
      <div className="vanAlert-wrapper">
        <div className="vanAlert" data-type={this.props.type}>
            { this.props.message }
        </div>
      </div>,
      this.el
    );
  }
}

Alert.propTypes = {
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
}

export default Alert;
