import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";


// import NumberFormat from "react-number-format";

import { Tooltip } from 'reactstrap';
import DatePicker from "react-datepicker";
import CustomdatePicker from "../shared/CustomDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { Fieldset } from "../shared/formFields";
import Uploader from "../shared/Uploader";

import TerritorySelect from "./TerritorySelect";
import TracksUploader from "../shared/TracksUploader";

const releaseFormValidation = Yup.object().shape({
  title: Yup.string().required("required"),
  cover: Yup.string().required("cover art is required"),
  price: Yup.number()
    .required("required")
    .positive("must be greater than zero"),
  type: Yup.mixed().oneOf(["EP", "Single", "Album"], "required"),
  artistId: Yup.string().required(
    "go to Inventory > Artists tab to Add Artist"
  ),
  publicationDate: Yup.string().required("required"),
  tracks: Yup.array()
    .min(1, "upload audio files")
    .required("upload audio files")
});

class ReleaseDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle = () => this.setState({ tooltipOpen: !this.state.tooltipOpen });

  setInitialValues = data => {
    let values = {
      artistId: "",
      artistName: "authorName",
      title: "",
      label: "labelName", // onnly for presentation, hide when user === artist
      labelId: "",
      type: "Choose",
      territories: ["worldwide"],
      publicationDate: new Date().toISOString(),
      cover: "",
      price: 1,
      acceptMediaUse: false,
      tracks: [],
      duration: "0"
    };

    // Case label
    if (data.role === "Label") {
      values.labelId = data.id;
      values.label = data.userName;
      // predefined artist
      if (data.selectedArtistId && data.selectedArtistName) {
        values.artistId = data.selectedArtistId;
        values.artistName = data.selectedArtistName;
      }
    }

    //Case Artist - need no label
    if (data.role === "Artist") {
      values.artistId = data.id;
      values.artistName = data.userName;

      delete values.label;
      delete values.labelId;
    }

    return values;
  };

  buildTrackList = (tracksList, artist, publicationDate) => {
    const sortedTrackList = tracksList.sort((a,b) => (a.title.toString().toLowerCase() > b.title.toString().toLowerCase() ? 1 : -1));
    const tracks = sortedTrackList.map((t, i) => ({
      // trackFile: t.trackFile,
      title: t.title,
      trackOrder: i + 1,
      about: "",
      price: 1,
      artist,
      publicationDate,
      duration: t.duration.toString(),
      awsKey: t.awsKey,
      awsBucketName: t.awsBucketName
    }));
    return tracks;
  };

  render() {
    const { role } = this.props;
    const artists = this.props.artist || [];
    const initialValues = this.setInitialValues(this.props);

    return (
      <div className="register-form__container">
        <Formik
          initialValues={initialValues}
          validationSchema={releaseFormValidation}
          onSubmit={values => {
            const pubDate = new Date(
              moment(values.publicationDate).format("YYYY-MM-DD")
            ).toISOString();

            const tracksWithArtist = this.buildTrackList(
              values.tracks,
              values.artistName,
              pubDate
            );

            const dataOnSubmit = {
              ...values,
              price: parseFloat(values.price),
              publicationDate: pubDate,
              tracks: tracksWithArtist
            };
            this.props.onSubmit(dataOnSubmit);
          }}
          render={({
            values,
            setFieldValue,
            errors,
            touched,
            setFieldTouched
          }) => (
            <Form className="register-form">
              <div className="row">
                <div className="col">
                  <div className="form-group row">
                    {role !== "Artist" && (
                      <Fieldset
                        name="artistId"
                        label="Artist"
                        fieldClass="form-control"
                        labelClass="col-sm-3 col-form-label"
                        fieldContainerClass="col-sm-9"
                        component="select"
                        onChange={e => {
                          if (e.target.value) {
                            const selectedArtistId = e.target.value;
                            const name = artists.find(
                              a => a.id === selectedArtistId
                            ).userName;
                            setFieldValue("artistId", selectedArtistId);
                            setFieldValue("artistName", name);
                          }
                        }}
                      >
                        <option />
                        {artists.map(artist => (
                          <option key={artist.id} value={artist.id}>
                            {artist.userName}
                          </option>
                        ))}
                      </Fieldset>
                    )}
                  </div>

                  {role !== "Artist" && (
                    <div className="form-group row">
                      <Fieldset
                        name="label"
                        type="text"
                        label="Label"
                        fieldClass="form-control"
                        labelClass="col-sm-3 col-form-label"
                        fieldContainerClass="col-sm-9"
                        disabled={true}
                      />
                    </div>
                  )}

                  <div className="form-group row">
                    <Fieldset
                      name="title"
                      type="text"
                      label="Title"
                      fieldClass="form-control"
                      labelClass="col-sm-3 col-form-label"
                      fieldContainerClass="col-sm-9"
                      placeholder="Enter release title"
                    />
                  </div>

                  {/* suggest state if US is selected otherwise show bare input     */}

                  <div className="form-group row">
                    <Fieldset
                      name="type"
                      label="Type"
                      fieldClass="form-control"
                      labelClass="col-sm-3 col-form-label"
                      fieldContainerClass="col-sm-9"
                      component="select"
                    >
                      <option value="Release Type">Release Type</option>
                      <option value="Album">Album</option>
                      <option value="Single">Single</option>
                      <option value="EP">EP</option>
                    </Fieldset>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-3">
                      <label htmlFor="">Territory</label>
                    </div>
                    <div className="col">
                      <TerritorySelect
                        onChange={data => {
                          const territory = data.map(t => t.value);
                          setFieldValue("territories", territory);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target="releaseDateTooltip" toggle={this.toggle}>
                      this date should reflect the first date of release in market ("street date") — even if a date in the past
                    </Tooltip>
                    <div className="col-sm-3">
                      <label htmlFor="">Release Date</label>
                    </div>
                    <div className="col" id="releaseDateTooltip">
                      <DatePicker
                        customInput={
                          <CustomdatePicker onClick={e => e.preventDefault()} />
                        }
                        selected={new Date(values.publicationDate)}
                        onChange={val => {
                          setFieldValue("publicationDate", new Date(val));
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/* form left coll end */}
                <div className="col">
                  <div className="form-group row">
                    <div className="col-sm-6">
                      <p>Cover Art:</p>
                      <br />
                      <br />
                      <span className="upload-info">
                        JPG or PNG;
                        <br />
                        at least 512x512;
                        <br />
                        max. 1 MB
                      </span>
                      <br />
                      <br />
                      <hr />
                    </div>

                    <div className="col-sm-6">
                      <Uploader
                        upload={true}
                        onGetBaseString={base64Img => {
                          setFieldValue("cover", base64Img);
                        }}
                      />
                    </div>
                    <div className="col-sm-12">
                      {errors.cover ? (
                        <p
                          style={{
                            color: "red",
                            textAlign: "right",
                            paddingRight: "20px",
                            fontSize: "12px"
                          }}
                        >
                          {errors.cover}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {/* <div style={{ marginTop: "50px" }} className="form-group row">
                    <br />
                    <label className="col-sm-4 col-form-label" htmlFor="price">
                      price (USD)
                    </label>
                    <div className="col-sm-4">
                      <span className="input-error"> </span>
                      <div className="currencyInput-wrap">
                        <NumberFormat
                          value={values.price}
                          decimalScale={2}
                          thousandSeparator={true}
                          prefix={"$"}
                          fixedDecimalScale={true}
                          allowNegative={false}
                          onValueChange={values => {
                            const { floatValue } = values;
                            // formattedValue = $2,223
                            // value ie, 2223
                            setFieldValue("price", floatValue);
                          }}
                        />
                      </div>
                      {errors.price ? (
                        <p
                          style={{
                            color: "red",
                            textAlign: "right",
                            paddingRight: "20px",
                            fontSize: "12px"
                          }}
                        >
                          {errors.price}
                        </p>
                      ) : null}
                    </div>
                  </div> */}
                </div>
                {/* form right coll end */}
              </div>
              {/* top row end */}

              <TracksUploader
                touched={touched}
                setFieldTouched={e => setFieldTouched("tracks", true)}
                submitionError={errors.tracks}
                onGetTracks={tracks => {
                  console.group("TRACKS FILES");
                  console.groupEnd();

                  setFieldValue("tracks", tracks);
                }}
              />

              <button className="btn-black" type="submit">
                Add Release
              </button>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default ReleaseDetailsForm;
