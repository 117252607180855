import React, { Component } from "react";
import PropTypes from "prop-types";

import { getBase64 } from "../../utils/utils";
import editIco from "../../images/edit.svg";

class Uploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpload: false,
      formImg: this.props.image || "",
      baseImg: "",
      error: {
        show: false,
        message: ""
      }
    };
    this.input = React.createRef();
  }

  isFileValid = file => {
    const maxSize = 1000000;
    const fileTypes = /(\.|\/)(jpg|png|jpeg|gif)$/i;

    if (file.size > maxSize) {
      return { error: "File max size is 1MB" };
    }

    if (!fileTypes.test(file.type)) {
      return { error: "Allowed file types: jpg, png" };
    }

    return true;
  };

  onInputChange = e => {
    const file = e.target.files[0];
    if (!file) return;

    if(!this.props.nosizeValidation) {
      const validation = this.isFileValid(file);
  
      if (validation.error) {
        this.setState({ error: { show: true, message: validation.error } });
        return;
      }
    }

    if(this.props.nosizeValidation) {
      this.setState({ loading: true });
  
        getBase64(file).then(result => {
          this.setState({
            loading: false,
            error: { show: false },
            formImg: URL.createObjectURL(file)
          });
          this.props.onGetBaseString(this.props.upload ? file : result);
        });
    } else {

      var fr = new FileReader();
  
      fr.onload = () => { // file is loaded
        var img = new Image();
    
        img.onload = () => {
            if(img.width !== img.height) {
              this.setState({ error: { show: true, message: 'Only square images are allowed. Please make sure image width and height are same.' } });
              return;
            }
  
            this.setState({ loading: true });
  
            getBase64(file).then(result => {
              this.setState({
                loading: false,
                error: { show: false },
                formImg: URL.createObjectURL(file)
              });
              this.props.onGetBaseString(this.props.upload ? file : result);
            });
        };
    
        img.src = fr.result; // is the data URL because called with readAsDataURL
      };
    
      fr.readAsDataURL(file);
    }

  };

  render() {
    const { containerClass } = this.props;
    const { error, formImg } = this.state;

    return (
      <div className={containerClass || "upload-area float-right"}>
        <input
          ref={this.input}
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={e => this.onInputChange(e)}
        />
        {error.show && <span className="input-error-image">{error.message}</span>}

        {formImg.length === 0 ? (
          <p>{this.props.copy || "Upload cover art"}</p>
        ) : (
          <div
            style={{ backgroundImage: `url('${formImg}')` }}
            className="uploaderImage"
          />
          //<img className="uploader-imageRender" src={formImg} alt="" />
        )}
        <img
          onClick={e => this.input.current.click()}
          className="uploader-editIco"
          src={editIco}
          alt=""
        />
      </div>
    );
  }
}

Uploader.propTypes = {
  onGetBaseString: PropTypes.func.isRequired,
  formImg: PropTypes.string
};

export default Uploader;
