import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

import { Fieldset } from "../shared/formFields";

import Uploader from "../shared/Uploader";
import loaderIco from "../../images/loader.svg";
import LocationSelect from "./LocationSelect";
const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env
// import ALLOWED_COUNTRIES from "../../data/allowed-countries";

class RegisterLabelForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.recaptchaRef.current.execute();
  }

  render() {
    const { handleSubmit, error, loading } = this.props;
    return (
      <div className="register-form__container">
        <Formik
          initialValues={{
            userName: "",
            firstName: "",
            lastName: "",
            email: "",
            about: "",
            password: "",
            confirm: "",
            picture: "",
            country: "United States",
            state: "",
            city: "",
            acceptUsage: false,
            acceptTerms: false,
            links: ""
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email address")
              .required("Required"),
            userName: Yup.string().required("Required"),
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            country: Yup.string()
              .test(
                "as",
                "at present, platform access is unavailable to your territory",
                val => {
                  return true;
                  // return ALLOWED_COUNTRIES.includes(val);
                }
              )
              .required("Required"),
            state: Yup.string().required("Required"),
            city: Yup.string().required("Required"),
            picture: Yup.string().required("label logo is required"),

            about: Yup.string().required("Required"),
            password: Yup.string()
              .required("Password required")
              .min(6, "Password is too short - should be 6 chars minimum."),
            confirm: Yup.string()
              .oneOf([Yup.ref("password"), null], "Passwords must match")
              .required("Password confirmation required"),
            acceptTerms: Yup.bool()
              .test(
                "acceptTerms",
                "registration requires agreement",
                value => value === true
              )
              .required("registration requires agreement")
          })}
          onSubmit={values => {
            if (!!this.recaptchaRef.current.getValue()) {
              handleSubmit(values);
            } else {
              this.recaptchaRef.current.execute();
            }
          }}
          render={({ values, setFieldValue, errors, submitCount }) => (
            <Form className="register-form">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <Fieldset
                      name="userName"
                      type="text"
                      label="Label Name"
                      fieldClass="form-control"
                      labelClass="col-sm-3 col-form-label"
                      fieldContainerClass="col-sm-9"
                    />
                  </div>
                  <div className="form-group row">
                    <Fieldset
                      name="firstName"
                      type="text"
                      label="First Name"
                      fieldClass="form-control"
                      labelClass="col-sm-3 col-form-label"
                      fieldContainerClass="col-sm-9"
                    />
                  </div>
                  <div className="form-group row">
                    <Fieldset
                      name="lastName"
                      type="text"
                      label="Last Name"
                      fieldClass="form-control"
                      labelClass="col-sm-3 col-form-label"
                      fieldContainerClass="col-sm-9"
                    />
                  </div>

                  <LocationSelect
                    onCountrySelect={country =>
                      setFieldValue("country", country)
                    }
                    onStateSelect={state => setFieldValue("state", state)}
                    onCitySelect={city => setFieldValue("city", city)}
                  />
                </div>
                {/* form left coll end */}

                <div className="col-md-6">
                  <div className="form-group row">
                    <div className="col-sm-6">
                      <p>Label Logo:</p>
                      <br />
                      <br />
                      <span className="upload-info">
                        JPG or PNG;
                        <br />
                        at least 512x512;
                        <br />
                        max. 5 MB
                      </span>
                      <hr />

                      <div className="row">
                        <div className="col-sm-8" />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <Uploader
                        copy="Upload logo"
                        onGetBaseString={base64Img => {
                          setFieldValue("picture", base64Img);
                        }}
                      />
                      {errors.picture && submitCount > 0 ? (
                        <p style={{ color: "red", float: "right" }}>
                          {errors.picture}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* form right coll end */}
              </div>
              {/* top row end */}

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group row">
                    <Fieldset
                      name="about"
                      component="textarea"
                      type="text"
                      label="Bio "
                      fieldClass="form-control"
                      labelClass="col-sm-1 col-form-label"
                      fieldContainerClass="col"
                      rows="4"
                    />
                  </div>

                  <div className="form-group row">
                    <Fieldset
                      name="links"
                      type="text"
                      label="Website &amp; social links"
                      fieldClass="form-control"
                      labelClass="col-sm-2 col-form-label"
                      fieldContainerClass="col"
                      rows="4"
                    />
                  </div>

                  <div className="form-group row">
                    <Fieldset
                      name="email"
                      type="email"
                      label="Email"
                      fieldClass="form-control"
                      labelClass="col-sm-1 col-form-label"
                      fieldContainerClass="col"
                    />

                    <Fieldset
                      name="password"
                      label="Password"
                      type="password"
                      placeholder="Password"
                      fieldClass="form-control"
                      labelClass="col-sm-1 col-form-label"
                      fieldContainerClass="col-sm-3"
                    />

                    <Fieldset
                      name="confirm"
                      type="password"
                      label="Confirm"
                      placeholder="Confirm password"
                      fieldClass="form-control"
                      labelClass="col-sm-1 col-form-label"
                      fieldContainerClass="col-sm-3"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-5">
                  <Field
                    name="acceptTerms"
                    type="checkbox"
                    checked={values.acceptTerms}
                  />
                  <span className="input-error">
                    {" "}
                    <ErrorMessage name="acceptTerms" />
                  </span>
                  <label
                    style={{
                      marginLeft: "15px",
                      marginTop: "10px",
                      verticalAlign: "middle"
                    }}
                    htmlFor="imageUseAgree"
                  >
                    <span>Accept </span>
                    <a target="_blank" href="/us/terms" className="black">
                      Terms &amp; conditions
                    </a>
                  </label>
                </div>

                <div className="col-sm-7">
                  {loading ? (
                    <img style={{ maxHeight: "36px" }} src={loaderIco} alt="" />
                  ) : (
                    <button
                      disabled={loading}
                      className="btn-black float-right"
                      type="submit"
                    >
                      Submit
                    </button>
                  )}
                </div>

                <div className="col text-left">
                  {error ? (
                    <p className="text-right" style={{ color: "red" }}>
                      {error.graphQLErrors.map(e => (
                        <span> {e.message} </span>
                      ))}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <ReCAPTCHA
                ref={this.recaptchaRef}
                size="invisible"
                sitekey={REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={a => {
                  if (!!a) {
                    setFieldValue("isHuman", true);
                  }
                }}
              />
            </Form>
          )}
        />
      </div>
    );
  }
}

RegisterLabelForm.propTypes = {
  loading: PropTypes.any.isRequired,
  error: PropTypes.any.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

RegisterLabelForm.defaultProps = {
  error: false
};
export default RegisterLabelForm;
