import gql from "graphql-tag";

const USER_PAYPAL = gql`
  query ME {
    me {
      id
      paypal {
        selfUrl
        actionUrl
        merchantId
        merchantIdInPayPal
        permissionsGranted
        accountStatus
        consentStatus
        productIntentID
        isEmailConfirmed
        returnMessage
      }
    }
  }
`;

export default USER_PAYPAL;
