import React from "react";
import { removeDuplicates } from "../../../utils/utils";
import IndexArtistItem from "./IndexArtistItem";

class IndexArtistsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      artists: this.props.artists,
      contactModal: {
        show: false,
        name: "",
        email: "",
        label: ""
      }
    };
  }

  showContact = info => {
    this.setState({
      contactModal: {
        show: true,
        ...info
      }
    });
  };

  hideContact = () => {
    this.setState({ contactModal: { show: false } });
  };

  render() {
    const { searchFor } = this.props;
    const { artists } = this.props;
    const { show, name, label, email } = this.state.contactModal;

    let artistsCollection = removeDuplicates(artists, "id");

    if (searchFor.length > 1) {
      artistsCollection = artistsCollection.filter(a =>
        a.userName.toLowerCase().includes(searchFor.toLowerCase())
      );
    }

    return (
      <div className="col-sm-6" style={{ paddingLeft: "30px" }}>
        <div className="row">
          <div className="indexResults-col__heading">
            <p>Artists:</p>
            <div className="indexResults-filter">
              {/* <p>
                Sort By:
                <button className="btn-bare">Date</button>
                <button className="btn-bare">Name</button>
              </p> */}
            </div>
          </div>
        </div>
        <div className="indexResults-coll">
          {artistsCollection.map(a => (
            <IndexArtistItem
              onShowContact={this.showContact}
              key={a.id}
              {...a}
            />
          ))}

          {show && (
            <div className="IndexModal-fixed">
              <div className="indexModal-header">
                <h3>Contact admin</h3>
                <hr />
              </div>
              <div className="IndexModal-body">
                <h3>{name}</h3>
                <p>
                  Email:
                  <a href={`mailto:${email?email:label?label.email:''}`}>
                    {email?email:label?label.email:''}
                  </a>
                </p>
                <p>Parent label: {label ? label.userName : name}</p>
                <br />
                <hr />
              </div>
              <div className="indexModal-body">
                <button onClick={e => this.hideContact()} className="btn-bare">
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default IndexArtistsList;
