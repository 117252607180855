import { gql } from "apollo-boost";

export default gql`
  query releases {
    me {
      id
      release {
        id
        title
        cover
        createdAt
        publicationDate
        status
        artist {
          id
          userName
          createdAt
        }
      }
    }
  }
`;
