import React from 'react';

const style = {
    image: {
      position: "fixed",
      left: 0,
      width: "110vw",
      height: "100vh"
    },

    error: {
      position: "relative",
      width: "90vw",
      height: "90vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "#fff",
      zIndex: 10
    }
}

const NotFound = () => {
  return ( 
  <div>
    <img style={style.image} src="https://i.giphy.com/media/xT5LMQrUFIsuvXVi4U/giphy-tumblr.gif" alt=""/>
    <div style={style.error}>
      <h1>404</h1>
      <p>Page not found :(</p>
    </div>
    </div>  );
}
 
export default NotFound;



