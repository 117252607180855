import React, { useEffect } from "react";
import ScriptLoader from "react-script-loader-hoc";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import UnlinkPayPalModal from "./UnlinkPayPalModal";
import USER_PAYPAL from "./user-paypal-query.js";
import { Tooltip, Button } from 'reactstrap';

const GET_PP_LINK = gql`
  query {
    getPaypalReferralUrl {
      selfUrl
      actionUrl
      merchantId
    }
  }
`;

const PaypalInfo = () => {
  return (
    <div className="row">
      <div className="col-sm-3">
        <img
          src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_74x46.jpg"
          border="0"
          alt="PayPal Logo"
          style={{ float: "left", padding: "0 21px 10px 0" }}
        />
      </div>
      <div className="col-sm-9">
        {/* <p>
          PayPal’s transaction fees are deducted from gross proceeds on
          purchases and support contributions. content provider (artist or
          label) will receive an 80% share of revenue on all purchases, less
          transaction fees. artist will receive an 80% share of revenue on
          support contributions, less transaction fees.{" "}
        </p> */}
        <p>
          PayPal’s transaction fees are deducted from gross proceeds on support
          contributions. artist receives an 80% share of gross proceeds from a
          support transaction, less PayPal fees.{" "}
        </p>
        <p>
          the default PayPal rate is a fee equating to 2.9% + $.30
          per-transaction. if you’re signing up for a PayPal account for the
          first time here, or if you have an existing PayPal Business account
          for which you’ve never modified your rate, this default rate will be
          applied to your account upon sign-up or sign-in.{" "}
        </p>
        <p>
          if you wish to change your PayPal account to the “micropayment” rate
          (5% + $.05 per-transaction), you can do so by contacting PayPal
          Merchant Sales at{" "}
          <a href="mailTo:Support@PayPal.com"> Support@PayPal.com</a>, or
          calling (844) 823-9382, to request that it be applied to your account.
          (you need only have a PayPal Personal account to receive support
          contributions. but if you wish to utilize the micropayment rate,
          and/or if your proceeds stand to exceed $20K or 200 transactions
          annually, you’ll need a PayPal Business account; new PayPal users
          should contact the company only after establishing a PayPal Business
          account.) &nbsp;{""}
          <span style={{ textDecoration: "underline" }}>
            note that changing to the micropayment rate will “hard-code" that
            rate to your account, so wherever else you use the account to
            receive payments, the micropayment rate will apply.
          </span>
        </p>
        <p>
          for transactions {`<$12`}, the micropayment rate is the most
          financially advantageous rate to the artist, as it stands to ensure
          the most net revenue.
          <span style={{ fontWeight: "bold" }}>
            {" "}
            because it’s expected that most support transactions on the platform
            will equate to less than $12 (and because the difference in
            resulting PayPal fees, for transactions above $12, is negligible),
            the micropayment rate is recommended to all artists{" "}
          </span>
          — unless it stands to lessen your net revenue share on other platforms
          where you use the same PayPal account, and where your average
          transaction equates to $12 or more. (in that event, one solution is to
          establish a new PayPal Business account for use here, and to assign it
          the micropayment rate.)
        </p>
        {/* <p>
          the default PayPal rate is a fee equating to 2.9% + $.30
          per-transaction. if you’re signing up for a PayPal account for the
          first time here, or if you have an existing PayPal Business account
          for which you’ve never modified your rate, this default rate will be
          applied to your account upon sign-up or sign-in.{" "}
        </p> */}
        {/* <p>
          if you wish to change your PayPal account to the “micropayment” rate
          (5% + $.05 per-transaction), you can do so by contacting PayPal
          Merchant Sales at{" "}
          <a href="mailTo:Support@PayPal.com">Support@PayPal.com</a>, or calling
          (844) 823-9382, to request that it be applied to your account. (you
          need only have a PayPal Personal account type to sell content. but if
          you wish to utilize the micropayment rate, and/or if your proceeds
          stand to exceed $20K or 200 transactions annually, you’ll need a
          PayPal Business account; new PayPal users should contact the company
          only after establishing a PayPal Business account.){" "}
          <span style={{ textDecoration: "underline" }}>
            note that changing to the micropayment rate will “hard-code" that
            rate to your account, so wherever else you use the account to
            receive payments, the micropayment rate will apply.
          </span>
        </p> */}
        {/* <p>
          for transactions {`<$12`}, the micropayment rate is the most
          financially advantageous rate to the artist/label, as it stands to
          ensure them the most net share.
          <span style={{ fontWeight: "bold" }}>
            {" "}
            because it’s expected that most purchase & support transactions on
            the platform will equate to less than $12 (and because the
            difference in fees that the rates amount to, for transactions above
            $12, is negligible), the micropayment rate is recommended to all
            users{" "}
          </span>
          — unless it stands to lessen your share on other platforms where you
          use the same PayPal account, and where your average transaction
          equates to $12 or more. (in that event, one solution is to establish a
          new PayPal Business account for use here, and to assign it the
          micropayment rate.)
        </p> */}
        <p>
          you can change your PayPal Business account to the micropayment rate
          at any time.
        </p>
      </div>
    </div>
  );
};

const DisconnectPaypal = ({ toggleShowModal, paypal }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <p style={{ color: "green" }}>
          you have successfully connected a PayPal account
        </p>
        <hr style={{ height: "4px", backgroundColor: "#ccc" }} />
      </div>
      <div className="col-sm-12">
        {paypal.accountStatus && (<p style={{ fontSize: "12px", margin: 0 }}>
          <span style={{ fontWeight: "bold" }}>Account type:</span>{" "}
          {paypal.accountStatus.replace("_", " ")}
        </p>)}
        <p style={{ fontSize: "12px", margin: 0 }}>
          <span style={{ fontWeight: "bold" }}>Account ID:</span>{" "}
          {paypal.merchantIdInPayPal}
        </p>
      </div>

      <div className="col-sm-12">
        <button
          style={{ margin: "30px 0" }}
          onClick={(e) => toggleShowModal(true)}
          className="btn-black"
        >
          Disconnect account
        </button>
      </div>
      <div className="col-sm-12">
        <hr style={{ height: "4px", backgroundColor: "#ccc" }} />
      </div>
    </div>
  );
};

const ConnectPaypal = ({ actionLink, setShowText, showText }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div style={{ margin: "30px 0" }} className="row">
      <div className="col-sm-12">

        <p>Authorize your account to start accepting payments</p>
        <hr style={{ margin: 0, paddingTop: '1rem', paddingBottom: '1rem' }} />

        <a
          style={{ marginTop: "10px" }}
          data-paypal-button="true"
          href={`${actionLink}&displayMode=minibrowser`}
          target="PPFrame"
          className="btn-black"
          id="TooltipExample"
        >
          Connect PayPal
        </a>
        <Tooltip placement="bottom" isOpen={tooltipOpen} target="TooltipExample" toggle={toggle}>
          <p style={{ margin: 0, padding: 10 }}>
            if you do not have a PayPal account, sign up for one on the PayPal site or app, and then return here to sign in to it
          </p>
        </Tooltip>
      </div>
      <div className="col-sm-12">

        <ol className="ordered-list" style={{ marginTop: 0, paddingTop: 16, listStyle: 'disc' }}>
          <li>
            {"to determine your current PayPal payment settings, visit your PayPal account and go to [cog] > ‘seller tools’ > ‘payment receiving preferences’"}
          </li>
          <li>
            for information on receiving foreign payments through PayPal, go <a href="https://www.paypal.com/us/brc/article/how-to-accept-foreign-currency-with-your-business-account" target="_blank">here</a>
          </li>
          <li>
            for information about PayPal's fees -- and the benefits of PayPal’s micropayment tier <Button style={{ padding: 0, height: 30, display: 'inline-flex' }} color="link" onClick={() => setShowText(toggle => !toggle)}>-- read here {showText ? <span style={{ fontSize: 12, alignSelf: 'center', marginLeft: 3, marginBottom: 2 }}>&#9650;</span> : <span style={{ fontSize: 12, alignSelf: 'center', marginLeft: 3, marginTop: -2 }}>&#9660;</span>}</Button>
          </li>
          {/* <li>
            <u>please</u> read the PayPal blurb  — <u>before</u> you start
            selling — for critical info on how to maximize your revenue share
          </li> */}
        </ol>
      </div>
    </div>
  );
};
const ConfirmPaypalEmail = ({ paypal }) => {
  if (!paypal) return null;
  if (!paypal.permissionsGranted) return null;
  if (paypal.isEmailConfirmed) return null;

  return (
    <div style={{ margin: "30px 0" }} className="row">
      <div className="col-sm-12">
        <p>Please log into PayPal to confirm your email</p>
        <hr />
      </div>
      <div className="col-sm-12">
        <a
          style={{ marginTop: "10px" }}
          data-paypal-button="true"
          href={`https://www.paypal.com/`}
          target="PPFrame"
          className="btn-black"
        >
          Confirm email
        </a>
        <hr />
      </div>
    </div>
  );
};

const PaypalSignUp = ({ scriptsLoadedSuccessfully, actionLink }) => {
  const [showModal, toggleShowModal] = React.useState(false);
  const [showText, setShowText] = React.useState(false);
  useEffect(() => {
    let js;
    let ref = document.getElementsByTagName("script")[0];

    if (!document.getElementById("paypal-js")) {
      js = document.createElement("script");
      js.id = "paypal-js";
      js.async = true;
      js.src =
        "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
      ref.parentNode.insertBefore(js, ref);
    }
  });

  return (
    <>
      <div className="row">
        <br />
        <div className="col-sm-6">
          <br />
          <h3>Finance</h3>
        </div>
      </div>
      <Query
        query={USER_PAYPAL}
        pollInterval={10000}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) {
            console.log("finance error");
            return `Error! ${error.message}`;
          }
          const { paypal } = data.me;
          if (!paypal)
            return (
              <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
                Your request is being processed, it may take up to 10 minutes.
              </p>
            );
          if (!paypal.permissionsGranted) {
            return (
              <React.Fragment>
                <ConnectPaypal showText={showText} setShowText={setShowText} actionLink={actionLink} />
                <div className={showText ? "m-fadeIn" : "m-fadeOut"}><PaypalInfo /></div>
              </React.Fragment>
            );
          }
          if (!paypal.isEmailConfirmed) {
            return (
              <React.Fragment>
                <ConfirmPaypalEmail paypal={paypal} />
                <PaypalInfo />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment>
              <DisconnectPaypal
                paypal={paypal}
                toggleShowModal={toggleShowModal}
              />
              <PaypalInfo />
            </React.Fragment>
          );
        }}
      </Query>
      {showModal && (
        <UnlinkPayPalModal onHide={(e) => toggleShowModal(false)} />
      )}
    </>
  );
};

const PP = ScriptLoader(
  "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js"
)(PaypalSignUp);

const PayPal = (props) => {
  return (
    <Query query={GET_PP_LINK}>
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;

        return (
          <PP actionLink={data.getPaypalReferralUrl.actionUrl} {...props} />
        );
      }}
    </Query>
  );
};

export default PayPal;
