import React from "react";
import EditPassword from "../../userSettings/account/EditPass";
import EditEmail from "../../userSettings/account/EditEmail";

const AdminAccount = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <h3 style={{ marginTop: "30px" }}>Account settings</h3>
          <hr />
        </div>
        <div className="col-sm-6">
          <EditPassword />
        </div>
        <div className="col-sm-6">
          <EditEmail />
        </div>
      </div>
    </div>
  );
};

export default AdminAccount;
