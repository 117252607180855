import React from "react";
import { NavLink } from "react-router-dom";
import { ChevronRight } from "../shared/SvgIcons";
import LogOutBtn from "../login/LogOutBtn";

const AdminSidebar = props => {
  return (
    <div className="adminSidebar">
      <div className="adminSidebar-inner">
        <div className="adminSidebar-links">
          <NavLink
            to="/admin/app"
            activeClassName="active"
            className="adminSidebar-link"
          >
            App <ChevronRight />
          </NavLink>

          <NavLink
            to="/admin/statistics"
            activeClassName="active"
            className="adminSidebar-link"
          >
            Statistics <ChevronRight />
          </NavLink>

          <NavLink
            to="/admin/users/new"
            activeClassName="active"
            className="adminSidebar-link"
          >
            Users <ChevronRight />
          </NavLink>

          <NavLink
            to="/admin/account"
            activeClassName="active"
            className="adminSidebar-link"
          >
            Account <ChevronRight />
          </NavLink>

          <NavLink
            to="/admin/transaction-report"
            activeClassName="active"
            className="adminSidebar-link"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Transaction Report <ChevronRight />
          </NavLink>
        </div>
        <div className="adminSidebar-bottom">
          <LogOutBtn
            history={props.history}
            ico={true}
            btnClass="adminSidebar-link"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
