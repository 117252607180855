import React from 'react';
import PropTypes from 'prop-types';

import { AUTH_TOKEN } from "../../constants";
import { ApolloConsumer } from "react-apollo";
import { LogOut } from '../shared/SvgIcons';



class LogOutBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  logOut = () => {
    localStorage.removeItem(AUTH_TOKEN);
    this.props.history.push('/')
  }

  render() { 
    return ( 
    
      <ApolloConsumer>
        {client => {
            return(
            <button 
              className={this.props.btnClass}
              onClick={e => {
              client.clearStore();
              this.logOut();

            }}>
              {this.props.btnText} 
              {this.props.ico && <LogOut/>}
          
            </button>
            )
        }}
        </ApolloConsumer>
    
    );
  }
}

LogOutBtn.propTypes = {
  history: PropTypes.any.isRequired,
  btnClass: PropTypes.string,
  btnTxt: PropTypes.string,
  ico: PropTypes.bool
}

LogOutBtn.defaultProps = {
  btnClass: "sidebar-link btn-bare", 
  btnText: "Logout",
  ico: false
}
 
export default LogOutBtn;