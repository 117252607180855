import React from 'react';
import {NavLink, Link} from 'react-router-dom'


const ReleaseArtistsTopBar = (props) => {

  const {location, role} = props;
  const showArtists = location.pathname === "/dashboard/artists";

  return (
    <div className="dashTopBar-container">
      <div className="dash-topbar">
        <div className="dash-topbar__left">

        {role !== "Artist" &&
        <NavLink exact to="/dashboard/artists" className="topDash-navlink" activeClassName="active">
            Artists
        </NavLink>
      }

        <NavLink exact to="/dashboard" className="topDash-navlink" activeClassName="active">
            Releases
        </NavLink>


        </div>

        <div className="dash-topbar__right">
          {showArtists ? (
            <Link
              to="/dashboard/addartist/add-existing"
              className="btn-black"
            >
              + Add Artist
              </Link>
          ) : (
              <Link
                to="/dashboard/addrelease"
                className="btn-black"
              >
                + Add Release
            </Link>
            )}
        </div>
      </div>
    </div>




  );
}

export default ReleaseArtistsTopBar;
