import React, { Component } from "react";
import { Formik, Form } from "formik";
// import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import * as Yup from "yup";
// import DatePicker from "react-datepicker";
// import CustomdatePicker from "../shared/CustomDatePicker";
import { SingleDatePicker } from "react-dates";

import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";

import { FieldsetRow } from "../shared/formFields";
import { Spinner, LoadingBtn } from "../shared/Loading";
import TextError from "../shared/Errors";
import PersonnelsForm from "./PersonnelsForm";
import Modal from "../shared/modal/Modal";

const GET_TRACK_BY_ID = gql`
  query getTrackById($trackId: ID!) {
    getTrack(trackId: $trackId) {
      id
      trackOrder
      title
      artist
      releaseArtist
      price
      publicationDate
      preRelease
      includeCredits
      written
      published
      produced
      engineered
      mixed
      mastered
      studio
      lyrics
      additionalCredits
      copyrightNote
      about
      videoId
      uploadId
      zypeId
      personnels {
        name
        instrument
      }
      release {
        id
        publicationDate
        status
      }
    }
  }
`;

const EDIT_TRACK = gql`
  mutation editTrackMutation(
    $trackId: ID
    $trackOrder: Int
    $title: String
    $artist: String
    $releaseArtist: Boolean
    $price: Float
    $publicationDate: DateTime
    $preRelease: Boolean
    $includeCredits: Boolean
    $written: String
    $published: String
    $produced: String
    $engineered: String
    $mixed: String
    $mastered: String
    $studio: String
    $lyrics: String
    $additionalCredits: String
    $copyrightNote: String
    $about: String
    $personnels: [PersonnelInput]
  ) {
    editTrack(
      trackId: $trackId
      track: {
        trackOrder: $trackOrder
        title: $title
        artist: $artist
        releaseArtist: $releaseArtist
        price: $price
        publicationDate: $publicationDate
        preRelease: $preRelease
        includeCredits: $includeCredits
        written: $written
        published: $published
        produced: $produced
        engineered: $engineered
        mixed: $mixed
        mastered: $mastered
        studio: $studio
        lyrics: $lyrics
        additionalCredits: $additionalCredits
        copyrightNote: $copyrightNote
        about: $about
        personnels: $personnels
      }
    ) {
      id
      trackOrder
      title
      artist
      releaseArtist
      price
      publicationDate
      preRelease
      includeCredits
      written
      published
      produced
      engineered
      mixed
      mastered
      studio
      lyrics
      additionalCredits
      copyrightNote
      about
      videoId
      uploadId
      zypeId
      personnels {
        id
        name
        instrument
      }
    }
  }
`;

class EditTrackDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      wasdateChanged: false
    };
  }
  render() {
    const { match } = this.props;
    const trackId = parseInt(match.params.trackId);
    return (
      <Query query={GET_TRACK_BY_ID} variables={{ trackId: parseInt(trackId) }}>
        {({ loading: queryLoading, error: queryError, data }) => {
          if (queryLoading) return <Spinner />;
          if (queryError) return <TextError error={queryError} />;
          if (!data.getTrack) return <h3>Resource not found</h3>;
          const {
            title,
            artist,
            releaseArtist,
            price,
            publicationDate,
            preRelease,
            includeCredits,
            written,
            published,
            produced,
            engineered,
            mixed,
            mastered,
            studio,
            lyrics,
            additionalCredits,
            copyrightNote,
            about,
            personnels,
            release
          } = data.getTrack;

          return (
            <Mutation
              mutation={EDIT_TRACK}
              onCompleted={data => {
                this.props.history.goBack();
              }}
            >
              {(editTrack, { loading, error }) => {
                return (
                  <Formik
                    initialValues={{
                      title: title || "",
                      artist: artist || "",
                      releaseArtist: releaseArtist || "",
                      price: parseFloat(price) || 1,
                      publicationDate: publicationDate,
                      preRelease: preRelease || false,
                      includeCredits: includeCredits || false,
                      written: written || "",
                      published: published || "",
                      produced: produced || "",
                      engineered: engineered || "",
                      mixed: mixed || "",
                      mastered: mastered || "",
                      studio: studio || "",
                      lyrics: lyrics || "",
                      additionalCredits: additionalCredits || "",
                      copyrightNote: copyrightNote || "",
                      about: about || "",
                      personnels: personnels.length
                        ? personnels
                        : [{ name: "", instrument: "" }]
                    }}
                    validationSchema={Yup.object().shape({
                      publicationDate: Yup.string()
                        .test(
                          "Date test",
                          "Date must not exceed publication release date",
                          val => {
                            const trackDate = moment(val).format("YYYY-MM-DD");
                            const releaseDate = moment(release.publicationDate)
                              .utc()
                              .format("YYYY-MM-DD");
                            return trackDate <= releaseDate;
                          }
                        )
                        .required(
                          "date must not exeed release publication date"
                        ),
                      price: Yup.number()
                        .required("required")
                        .positive("must be greater than zero")
                    })}
                    onSubmit={values => {
                      let editTrackPayload = {
                        ...values,

                        trackId: parseInt(trackId),
                        price: parseFloat(values.price),
                        personnels: values.personnels.map(p => ({
                          name: p.name,
                          instrument: p.instrument
                        }))
                      };

                      if (this.state.wasdateChanged) {
                        editTrackPayload.publicationDate = new Date(
                          moment(values.publicationDate).format("YYYY-MM-DD")
                        ).toISOString();
                      }

                      editTrack({ variables: editTrackPayload });
                    }}
                    render={({ values, setFieldValue, errors }) => (
                      <div
                        style={{ marginTop: "40px" }}
                        className="register-form_containee"
                      >
                        <Form className="register-form">
                          <div
                            style={{ paddingBottom: "30px" }}
                            className="row trackDetails-row"
                          >
                            <div className="col-sm-8">
                              <FieldsetRow
                                name="title"
                                type="text"
                                label="Track Title"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                                placeholder="Track Title Only — Omit any extraneous metadata"
                              />
                              <FieldsetRow
                                name="artist"
                                type="text"
                                label="artist"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                                placeholder="Enter release title"
                              />

                              <div
                                style={{ marginTop: "15px" }}
                                className="row"
                              >
                                <div className="col-sm-2">
                                  Track release date
                                </div>
                                <div className="col-sm-4">
                                  <SingleDatePicker
                                    // showClearDate={true}
                                    // isOutsideRange={() => false}
                                    disabled={release.status === "Published"}
                                    isOutsideRange={() => false}
                                    inputIconPosition="after"
                                    small={true}
                                    block={false}
                                    numberOfMonths={1}
                                    date={moment(values.publicationDate).utc()}
                                    onDateChange={date => {
                                      const trackDate = moment(date).format(
                                        "YYYY-MM-DD"
                                      );
                                      const releaseDate = moment(
                                        release.publicationDate
                                      )
                                        .utc()
                                        .format("YYYY-MM-DD");

                                      if (trackDate < releaseDate) {
                                        setFieldValue("preRelease", true);
                                      } else {
                                        setFieldValue("preRelease", false);
                                      }

                                      this.setState({ wasdateChanged: true });

                                      setFieldValue(
                                        "publicationDate",
                                        moment(date).utc()
                                      );
                                    }}
                                    focused={this.state.focused}
                                    onFocusChange={({ focused }) =>
                                      this.setState({ focused })
                                    }
                                    openDirection="up"
                                    hideKeyboardShortcutsPanel={true}
                                  />
                                  {/* <DatePicker
                                    disabled={release.status === "Published"}
                                    customInput={
                                      <CustomdatePicker
                                        onClick={e => e.preventDefault()}
                                      />
                                    }
                                    selected={new Date(values.publicationDate)}
                                    onChange={date => {
                                      const releasePubDate = moment(
                                        release.publicationDate
                                      );
                                      const calendarSelect = moment(date);

                                      const dif = calendarSelect.diff(
                                        releasePubDate,
                                        "days"
                                      );

                                      if (dif < 0) {
                                        setFieldValue("preRelease", true);
                                      } else {
                                        setFieldValue("preRelease", false);
                                      }

                                      setFieldValue(
                                        "publicationDate",
                                        new Date(date)
                                      );
                                    }}
                                  /> */}

                                  {errors.publicationDate && (
                                    <p
                                      style={{ fontSize: "10px", color: "red" }}
                                    >
                                      {errors.publicationDate}
                                    </p>
                                  )}
                                </div>

                                <div className="col-sm-4">
                                  <label
                                    style={{ marginRight: "10px" }}
                                    htmlFor="imageUseAgree"
                                  >
                                    Pre-release{" "}
                                    <button
                                      style={{
                                        padding: "0 3px",
                                        marginLeft: "2px"
                                      }}
                                      type="button"
                                      onClick={() =>
                                        this.setState({ showModal: true })
                                      }
                                      className="btn-bare"
                                    >
                                      [?]
                                    </button>
                                  </label>
                                  <input
                                    style={{ marginTop: "8px" }}
                                    type="checkbox"
                                    checked={values.preRelease}
                                    onChange={e => {
                                      setFieldValue(
                                        "preRelease",
                                        e.target.checked
                                      );
                                    }}
                                  />
                                </div>

                                {/* <Fieldset
                                  name="publicationDate"
                                  type="date"
                                  label="Date of publication"
                                  fieldClass="form-control"
                                  labelClass="col-sm-2 col-form-label"
                                  fieldContainerClass="col-sm-4"
                                  placeholder="Enter release title"
                                /> */}
                              </div>

                              {/* <div className="row trackCredits-row">
                                <label
                                  className="col-sm-2 col-form-label"
                                  htmlFor="price"
                                >
                                  price (USD)
                                </label>

                                <div className="col-sm-4">
                                  <span className="input-error"> </span>
                                  <div className="currencyInput-wrap">
                                    <NumberFormat
                                      allowNegative={false}
                                      value={values.price}
                                      decimalScale={2}
                                      thousandSeparator={true}
                                      prefix={"$"}
                                      fixedDecimalScale={true}
                                      onValueChange={values => {
                                        const { floatValue } = values;
                                        // formattedValue = $2,223
                                        // value ie, 2223
                                        setFieldValue("price", floatValue);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>

                          <div className="row trackCredits-row">
                            <div className="col-sm-6">
                              <FieldsetRow
                                name="written"
                                type="text"
                                label="written"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                              />
                              <FieldsetRow
                                name="produced"
                                type="text"
                                label="produced"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                                placeholder=""
                              />
                              <FieldsetRow
                                name="mixed"
                                type="text"
                                label="mixed"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                              />
                              <FieldsetRow
                                name="studio"
                                type="text"
                                label="studio"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                              />
                              <FieldsetRow
                                name="additionalCredits"
                                type="text"
                                label="additional credits"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                                component="textarea"
                                rows="2"
                              />
                              <FieldsetRow
                                name="copyrightNote"
                                type="text"
                                label="Copyright Notice"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                                component="textarea"
                                rows="2"
                              />
                              <FieldsetRow
                                name="about"
                                type="text"
                                label="About the Track"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label login"
                                fieldContainerClass="col-sm-10"
                                component="textarea"
                                rows="2"
                              />
                            </div>
                            <div className="col-sm-6">
                              <FieldsetRow
                                name="published"
                                type="text"
                                label="published"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                              />
                              <FieldsetRow
                                name="engineered"
                                type="text"
                                label="engineered"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                              />
                              <FieldsetRow
                                name="mastered"
                                type="text"
                                label="mastered"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                              />
                              <FieldsetRow
                                name="lyrics"
                                type="text"
                                label="lyrics"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col-sm-10"
                                component="textarea"
                                rows="11"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div
                              style={{ marginTop: "30px" }}
                              className="col-sm-1"
                            >
                              <p>
                                Additional <br /> Personnel
                              </p>
                            </div>
                            <PersonnelsForm
                              name="personnels"
                              personnels={values.personnels}
                            />
                          </div>
                          <div
                            className="row d-flex justify-content-between"
                            style={{ marginTop: "20px" }}
                          >
                            <div className="col" />

                            <div className="col">
                              {loading ? (
                                <LoadingBtn />
                              ) : (
                                <button
                                  disabled={false}
                                  className="btn-black float-right"
                                  type="submit"
                                >
                                  Save Track Credits
                                </button>
                              )}
                            </div>
                            <div className="col-sm-12">
                              {error && (
                                <p
                                  style={{ color: "red" }}
                                  className="text-right"
                                >
                                  Something went wrong :(
                                </p>
                              )}
                            </div>
                          </div>
                        </Form>
                        {this.state.showModal && (
                          <Modal
                            onHide={() => this.setState({ showModal: false })}
                          >
                            <p>
                              Set a publish date for this Track that is prior to
                              publish date of Release
                            </p>
                          </Modal>
                        )}
                      </div>
                    )}
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default EditTrackDetails;
