import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "react-router-dom";

const CURRENT_USER_QUERY = gql`
  query ME {
    me {
      id
      paypal {
        selfUrl
        actionUrl
        merchantId
        merchantIdInPayPal
        permissionsGranted
        accountStatus
        consentStatus
        productIntentID
        isEmailConfirmed
        returnMessage
      }
    }
  }
`;

const Notice = () => {
  return (
    <div className="paypalNotice">
      <p>Please connect your PayPal Account before publishing content</p>
      <div>
        <div>
          <img
            src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_74x46.jpg"
            border="0"
            alt="PayPal Logo"
          />
        </div>
        <Link className="btn-black" to="/dashboard/settings/finance/">
          Connect PayPal
        </Link>
      </div>
    </div>
  );
};
const EmailNotice = () => {
  return (
    <div className="paypalNotice">
        <p>Your email is not confirmed by PayPal.</p>
      <p>Please confirm your email and Connect PayPal account again.</p>
      <div>
        <div>
          <img
            src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_74x46.jpg"
            border="0"
            alt="PayPal Logo"
          />{" "}
        </div>
          <Link className="btn-black" to="/dashboard/settings/finance/">
              Connect PayPal
          </Link>
      </div>
    </div>
  );
};

const PayPalNotice = () => {
  return (
    <Query pollInterval={10000} query={CURRENT_USER_QUERY}>
      {({ loading, error, data }) => {
        if (!data) return null;
        if (error) {
          return null;
        }
        const { paypal } = data.me;
        return (
          <>
            {paypal && paypal.permissionsGranted ? (
              paypal.isEmailConfirmed ? null : (
                <EmailNotice />
              )
            ) : (
              <Notice />
            )}
          </>
        );
      }}
    </Query>
  );
};

export default PayPalNotice;
