import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Field, FieldArray } from "formik";

import { Minus, Plus } from '../shared/SvgIcons';

class PersonnelsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {

    const {personnels, name} = this.props

    return (
      <div className="col personel-form">
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <>
            {personnels.map((p, index) => (
              <div className="row connect-line" key={index} style={{ marginTop: "10px" }}>
                <div className="col">
                  <Field className="form-control" placeholder="Name" name={`personnels.${index}.name`} />
                </div> 
                <div className="col">
                  <Field className="form-control" placeholder="Instruments" name={`personnels.${index}.instrument`} />
                </div>

                <div className="col-sm-1 d-flex justify-content-end">
                  <button className="btn-black btn-personel" type="button" onClick={() => arrayHelpers.remove(index)}>
                    <Minus />
                  </button>
                </div>
              </div>
            ))}
            <div className="row justify-content-end">
              <div className="col d-flex justify-content-end">
                <button style={{ marginTop: "20px" }} className="btn-black float-right btn-personel" type="button" onClick={() => arrayHelpers.push({ name: "", instrument: "" })}>
                  <Plus />
                </button>

              </div>
            </div>
          </>

        )}
      />
      </div>

    );
  }
}

PersonnelsForm.propTypes = {
  personnels: PropTypes.any.isRequired,
}


export default PersonnelsForm;