import { gql } from "apollo-boost";

const GET_NEW_RELEASES = gql`
  query getNewReleases($date: DateTime!) {
    getNewReleases(date: $date) {
      id
      date
      releases {
        id
        title
        cover
        type
        publicationDate
        additionalAdminInfo
        territories
        artist {
          id
          userName
        }
      }
    }
  }
`;

export default GET_NEW_RELEASES;
