import React from "react";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";

import NewTracksContext from "./newTracksContext";

import { Spinner } from "../../shared/Loading";
import { Minus, Lines } from "../../shared/SvgIcons";
import Modal from "../../shared/modal/Modal";
import AdminTrackNotes from "./AdminTrackNotes";
import Territories from "../newReleases/TerritoriesList";
// import moment from "moment";

const GETTRACK = gql`
  query getTrackById($trackId: ID!) {
    getTrack(trackId: $trackId) {
      id
      title
      additionalAdminInfo
      publicationDate
      release {
        id
        title
        cover
        territories
        artist {
          id
          userName
        }
      }
    }
  }
`;

class TrackItemById extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdminNotesModal: false
    };
  }

  static contextType = NewTracksContext;

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.id === nextProps.id &&
      this.state.showAdminNotesModal === nextState.showAdminNotesModal
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <Query query={GETTRACK} variables={{ trackId: parseInt(this.props.id) }}>
        {({ data, loading, error }) => {
          if (loading) return <Spinner />;
          if (error) {
            console.log(error);
            return "error";
          }
          const {
            id,
            title,
            additionalAdminInfo,
            release: {
              cover: coverImage,
              title: releaseTitle,
              artist,
              territories
            }
          } = data.getTrack;

          return (
            <>
              <div className="col-sm-3">
                <div
                  style={{
                    backgroundImage: `url("${coverImage || coverImage}")`
                  }}
                  className="fxfItem-cover"
                />
              </div>

              <div className="col-sm-6">
                <p className="fxfItem-title">{title}</p>
                <p className="fxfItem-release">
                  {releaseTitle.substring(0, 17)}
                </p>
                <div className="fxfItem-artist">
                  {artist ? artist.userName : "#error"}{" "}
                  <Territories territories={territories} />
                </div>
                <p style={{ fontSize: "10px" }}>
                  <button
                    onClick={e => {
                      this.setState({ showAdminNotesModal: true });
                    }}
                  >
                    admin notes
                  </button>{" "}
                  :<span> {additionalAdminInfo}</span>
                </p>
              </div>

              <div className="col-sm-3 icons-col">
                <div className="fxfItem-icons float-right">
                  <>
                    <button
                      onClick={e => this.context.onRemoveTrack(id)}
                      className="fxfIem-btn"
                    >
                      <Minus icoClass="fxfIem-ico" />
                    </button>
                    <Lines />
                  </>
                </div>
              </div>
              {this.state.showAdminNotesModal && (
                <Modal
                  onHide={e => this.setState({ showAdminNotesModal: false })}
                >
                  <AdminTrackNotes
                    trackId={id}
                    additionalAdminInfo={additionalAdminInfo}
                    title={title}
                    cover={coverImage}
                  />
                </Modal>
              )}
            </>
          );
        }}
      </Query>
    );
  }
}

export default TrackItemById;
