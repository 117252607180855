import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { Mutation } from "react-apollo";

import gql from "graphql-tag";

import CURRENT_USER_QUERY from "../queries/me";
import { LoadingBtn } from "../shared/Loading";
import TextError from "../shared/Errors";

const DELETE_RELEASE = gql`
  mutation deleteReleaseMutation($releaseId: ID) {
    deleteRelease(releaseId: $releaseId)
  }
`;

class ReleaseBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }
  render() {
    const { showModal } = this.state;
    const { id, title, cover, publicationDate, status, isAdmin } = this.props;

    const editLink = isAdmin
      ? `/admin/app/index/release/${id}`
      : `/dashboard/release/${id}`;

    return (
      <>
        <div className="artist-box__container">
          <div
            className="artist-box__inner"
            style={{ backgroundImage: `url("${cover}")` }}
          >
            <Link to={editLink} className="artist-option">
              <p>Edit</p>
            </Link>
            <button
              className="artist-option"
              onClick={e => this.setState({ showModal: !showModal })}
            >
              <p>Delete</p>
            </button>
          </div>
          <h4>{title}</h4>
          <p className="releaseBox-date">
            {moment(publicationDate)
              .utc()
              .format("MM.DD.YYYY")}
          </p>
          <span className={`releaseBox-status  ${status.toLowerCase()}`}>
            {status}
          </span>
        </div>

        {showModal && (
          <div className="reset-pass__container">
            <div className="reset-pass__body">
              <p>
                are you sure you want to delete{" "}
                <span style={{ fontWeight: "bold" }}>{title}?</span>
              </p>

              <div className="reset-pass__form">
                <div className="form-group row">
                  <div className="col" />
                </div>

                <div style={{ flexWrap: "nowrap" }} className="btn-row">
                  <button
                    type="button"
                    onClick={e => this.setState({ showModal: !showModal })}
                    className="btn-bare"
                  >
                    Decline
                  </button>
                  <Mutation
                    mutation={DELETE_RELEASE}
                    refetchQueries={[{ query: CURRENT_USER_QUERY }]}
                    onCompleted={data => {
                      this.setState({ showModal: !showModal });
                    }}
                  >
                    {(deleteRelease, { loading, error, data }) => {
                      const variables = { releaseId: id };

                      return (
                        <>
                          {loading ? (
                            <LoadingBtn />
                          ) : (
                            <button
                              onClick={e => deleteRelease({ variables })}
                              type="button"
                              className="btn btn-danger"
                            >
                              Delete Release
                            </button>
                          )}

                          {error && (
                            <span style={{ position: "absolute", bottom: 0 }}>
                              <TextError error={error} />
                            </span>
                          )}
                        </>
                      );
                    }}
                  </Mutation>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ReleaseBox;
