import React from "react";
import { Route, Switch, /* Link */ } from "react-router-dom";
import SettingsTopBar from "./SettingsTopBar";
// import PermisionsSettings from "./permissions/PermisionSettings";

import { Query } from "react-apollo";
import { Spinner } from "../shared/Loading";
import CURRENT_USER_QUERY from "../queries/me";
import AccountSettings from "./account/AccountSettings";
import DetailsSettings from "./details/DetailsSettings";
import FinanceContainer from "./finance/FinanceContainer";

const SettingsContainer = () => {
  return (
    <div className="container">
      <SettingsTopBar />

      <div className="row no-gutters">
        <div className="col">
          <Query query={CURRENT_USER_QUERY}>
            {({ loading, error, data }) => {
              if (loading) return <Spinner />;
              if (error) return error.message;
              return (
                <Switch>
                  {/* <Route
                    path="/dashboard/settings/permisions"
                    render={props => <PermisionsSettings me={data} />}
                  /> */}

                  <Route
                    path="/dashboard/settings/account"
                    render={props => (
                      <AccountSettings me={data.me} {...props} />
                    )}
                  />
                  <Route
                    exact
                    path="/dashboard/settings/finance/paypal"
                    render={props => (
                      <FinanceContainer me={data.me} {...props} />
                    )}
                  />
                  {data.me.role !== 'Label' &&
                    <Route
                      path="/dashboard/settings/finance"
                      render={props => (
                        <FinanceContainer me={data.me} {...props} />
                      )}
                    />
                  }

                  <Route render={props => <DetailsSettings me={data.me} />} />
                </Switch>
              );
            }}
          </Query>
        </div>
      </div>
    </div>
  );
};

export default SettingsContainer;
