import React from "react";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { LoadingBtn } from "../shared/Loading";

const RESEND_VERIFICATION = gql`
  mutation resendVerification($email: EmailAddress!) {
    resendUserVerificationEmail(email: $email)
  }
`;

class ResendVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: ""
    };
  }
  render() {
    const { email, message } = this.state;
    return (
      <Mutation
        mutation={RESEND_VERIFICATION}
        onCompleted={data => {
          if (data.resendUserVerificationEmail) {
            this.setState({ message: `email has been re-sent to: ${email}` });
            setTimeout(() => {
              this.props.history.push("/");
            }, 3000);
          }
        }}
      >
        {(resend, { loading, error }) => {
          return (
            <>
              {message.length ? (
                <p style={{ fontSize: "12px", color: "green" }}>{message}</p>
              ) : (
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    resend({ variables: { email: email } });
                  }}
                >
                  <input
                    value={email}
                    onChange={e => this.setState({ email: e.target.value })}
                    className="form-control"
                    type="email"
                    style={{ marginBottom: "15px" }}
                  />

                  {loading ? (
                    <LoadingBtn />
                  ) : (
                    <button type="submit" className="btn-black">
                      send
                    </button>
                  )}
                </form>
              )}
            </>
          );
        }}
      </Mutation>
    );
  }
}

export default ResendVerification;
