import React from "react";

import Select from "react-select";

import data from "../../data/countries";

const countryOptions = data.map(country => {
  return {
    value: country.name,
    label: country.name
  };
});

export default props => {
  const defaultTerritories = props.territories
    ? props.territories.map(ter => ({ value: ter, label: ter }))
    : [{ value: "worldwide", label: "worldwide" }];

  return (
    <Select
      defaultValue={defaultTerritories}
      isMulti
      name="colors"
      options={[...countryOptions, { value: "worldwide", label: "worldwide" }]}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={props.onChange}
    />
  );
};
