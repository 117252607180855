import React from "react";
import { Link } from "react-router-dom";

const SidebarDetails = props => {
  const { userName, state, city, about, picture, /* id, */ role, frontendId } = props;

  return (
    <div className="sidebar-inner">
      <Link to={`/dashboard/`}>
        <img src={picture} alt="" className="sidebar-logo" />
      </Link>
      <h3 className="sidebar-title">{userName}</h3>
      <span className="sidebar-location">{`${city}, ${state}`}</span>
      <p className="sidebar-content">
        {about && about.substring(0, 100)}{" "}
        {about && about.length > 99 ? "..." : ""}
      </p>
      <span>ID: {frontendId}</span>
      <hr />
      <p>{role}</p>
    </div>
  );
};

export default SidebarDetails;
