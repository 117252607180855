import React from "react";
import { World } from "../../shared/SvgIcons";

const Territories = ({ territories }) => (
  <p style={{ fontSize: "12px", margin: 0 }}>
    {territories &&
      territories.map((t, i) => {
        if (t === "worldwide")
          return (
            <span key={i} className="badge badge-success">
              <World /> {t}
            </span>
          );
        return (
          <span key={i} className="badge badge-warning">
            {t}
          </span>
        );
      })}
  </p>
);

export default Territories;
