import React from "react";
import { Link } from "react-router-dom";
import { Query } from "react-apollo";
import { Spinner } from "../../shared/Loading";
import gql from "graphql-tag";
import ReleaseBox from "../../userDashboard/ReleaseBox";

const GET_USER = gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      userName
      email
      zip
      city
      state
      country
      supportTier
      supportTotal
      role
      acceptTerms
      acceptUsage
      about
      picture
      verified
      adminApproval
      createdAt
      updatedAt
      links
      role
      frontendId
      release {
        id
        title
        cover
        publicationDate
        type
      }
    }
  }
`;

const AdminUserDetails = props => {
  const { userId } = props.match.params;
  return (
    <div className="container">
      <Query query={GET_USER} variables={{ id: userId }}>
        {({ loading, error, data }) => {
          if (loading) return <Spinner />;
          if (error) return `Error! ${error.message}`;
          if (data.getUser === null) return <p>User not found</p>;
          const {
            id,
            userName,
            /* firstName,
            lastName, */
            email,
            picture,
            city,
            state,
            country,
            verified,
            about,
            release,
            role,
            frontendId
          } = data.getUser;
          return (
            <div className="container">
              <div style={{ marginTop: "20px" }} className="row">
                <div className="col-sm-4">
                  <img src={picture} alt="" />
                  <br />
                  <br />
                  <h2>{userName}</h2>
                  <p>
                    <span className="font-weight-bold ">Location</span> {city},{" "}
                    {state}, {country}
                  </p>
                  <p>
                    <span className="font-weight-bold ">Email</span> {email}
                  </p>
                  <p>
                    <span className="font-weight-bold ">Status</span>{" "}
                    {verified ? "Verified" : "Not verified"}
                  </p>
                  <p>
                    <span className="font-weight-bold ">About</span> {about}
                  </p>
                  <p>
                    <span className="font-weight-bold ">ID</span> {id} #
                    {frontendId}
                  </p>
                  <p>
                    <span className="font-weight-bold ">Account type: </span>
                    {role}
                  </p>
                  <br />
                  <br />
                  <Link className="btn-black" to={`${props.match.url}/edit`}>
                    {`Edit ${role} details`}
                  </Link>
                </div>
                <div className="col-sm-1" />
                <div className="col-sm-7">
                  <div className="row">
                    <div
                      className="col d-flex justify-content-between"
                      style={{
                        paddingBottom: "15px",
                        borderBottom: "1px solid #000"
                      }}
                    >
                      <h3>Releases</h3>
                    </div>
                  </div>
                  <div className=" d-flex  flex-wrap">
                    {release &&
                      release.map(r => <ReleaseBox key={r.id} {...r} />)}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default AdminUserDetails;
