import React from "react";
import NewReleasesList from "./NewReleasesList";

import ReleasesDragableList from "../4x4/4x4DragableList";
import FourByFourModal from "../4x4/4x4Modal";
import SaveNewReleases from "./SaveNewReleases";

class NewReleases extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      releases: this.props.releases || [],
      showModal: false,
      wasChanged: false,
      warning: false,
      order: true
    };
  }

  _toggleWarning = () => {
    // show track already on the list
    this.setState({ warning: !this.state.warning });
    setTimeout(() => {
      this.setState({ warning: !this.state.warning });
    }, 2000);
  };

  addToNewReleasesCollection = release => {
    const isInCollection = this.state.releases.some(r => r.id === release.id);

    if (isInCollection) {
      this._toggleWarning();
      return;
    }

    this.setState(state => ({
      releases: [...state.releases, release],
      wasChanged: true
    }));
  };
  deleteReleaseFromList = releaseId => {
    const releasesList = this.state.releases.filter(r => releaseId !== r.id);
    this.setState({ releases: releasesList, wasChanged: true });
  };

  onUpdateOrder = releases => {
    this.setState({ releases: releases, wasChanged: true });
  };

  onSavedChange = () => {
    this.setState({ wasChanged: false });
  };

  getReleases = () =>
    this.props.releases.sort((a, b) =>
      this.state.order
        ? a.publicationDate > b.publicationDate
          ? 1
          : -1
        : a.publicationDate > b.publicationDate
        ? -1
        : 1
    );

  updateReleases = ({ releaseId, notes }) => {
    const updatedReleases = this.setState.releases.map(r => {
      if (r.id === releaseId) {
        return { ...r, additionalAdminInfo: notes };
      } else {
        return r;
      }
    });
    this.setState({ releases: updatedReleases });
  };

  render() {
    const { wasChanged, warning } = this.state;
    return (
      <>
        <div className="row 4x4wrapper">
          <div className="col-sm-12">
            {/* <DateRow {...this.props} /> */}
            <div className="row">
              <div
                style={{ backgroundColor: warning ? "#bbffbb17" : "" }}
                className="col-sm-6 fxf-left"
              >
                <div className="row fxfColection-heading sticky">
                  <p>
                    New Releases feed{" "}
                    <span style={{ fontSize: "14px", marginLeft: "20px" }}>
                      {this.state.releases.length} items in collection
                    </span>
                  </p>
                  <SaveNewReleases
                    releases={this.state.releases}
                    showSave={wasChanged}
                    onSavedChange={this.onSavedChange}
                    isNewReleasesCollectionCreated={
                      this.props.isNewReleasesCollectionCreated
                    }
                  />

                  {warning && (
                    <span className="badge  badge-warning">
                      Already on the list
                    </span>
                  )}
                </div>
                <div className="fxfColection-list">
                  <ReleasesDragableList
                    onUpdateOrder={this.onUpdateOrder}
                    deleteReleaseFromList={this.deleteReleaseFromList}
                    releases={this.state.releases}
                    updateReleases={this.updateReleases}
                  />
                </div>
              </div>
              <div
                style={{
                  maxHeight: "85vh",
                  overflow: "scroll",
                  paddingLeft: "20px"
                }}
                className="col-sm-6"
              >
                <div className="row fxfColection-heading sticky">
                  <p>
                    Published releases timeline:{" "}
                    {/* <button
                      onClick={e =>
                        this.setState(state => ({
                          order: !state.order
                        }))
                      }
                    >
                      {" "}
                      <ChevronRight />{" "}
                    </button> */}
                  </p>
                </div>
                {
                  <NewReleasesList
                    addToNewReleasesCollection={this.addToNewReleasesCollection}
                    releases={[]}
                    {...this.props}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        {this.state.showModal && (
          <FourByFourModal
            releases={this.state.releases}
            onCacnel={e => this.setState({ showModal: false })}
          />
        )}
      </>
    );
  }
}

export default NewReleases;
