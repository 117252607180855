import React, { Component } from "react";

import queryString from "query-string";
import moment from "moment";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Spinner } from "../../shared/Loading";

import ReleaseItem from "./ReleaseItem";

const GET_RELEASES = gql`
  query getReleasesQuery($publicationDate: DateTime) {
    getReleases (publicationDate: $publicationDate) {
      id
      title
      cover
      type
      publicationDate
      territories
      artist {
        id
        userName
      }
    }
  }
`;

class FourByFourReleasesList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addTo4x4 = release => {
    this.props.addTo4x4(release);
  };

  render() {
    const filter = this.props.filter.toLowerCase();
      const dateFromProps = queryString.parse(this.props.location.search).date;
    const today = moment(new Date().toISOString()).format("YYYY-MM-DD");

    // const urlFormat = "YYYY-MM-DD";
    // const displayFormat = "MMMM DD, YYYY";

    const selectedDate = () => dateFromProps ? new Date(dateFromProps).toISOString() : today;

    return (
      <Query query={GET_RELEASES}
             variables={{ publicationDate: selectedDate() }}
      >
        {({ data, loading, error }) => {
          if (loading) return <Spinner />;
          if (error) return <p>Something goes wrong :( </p>;
          const releases =
            filter.length > 1
              ? data.getReleases.filter(
                  r =>
                    r.title.toLowerCase().includes(filter) ||
                    r.artist.userName.toLowerCase().includes(filter)
                )
              : data.getReleases;

          return (
            releases &&
            releases
              .sort((a, b) =>
                new Date(a.publicationDate) < new Date(b.publicationDate)
                  ? 1
                  : -1
              )
              .map(r => (
                <ReleaseItem key={r.id} {...r} addTo4x4={this.addTo4x4} />
              ))
          );
        }}
      </Query>
    );
  }
}

export default FourByFourReleasesList;
