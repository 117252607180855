import React from "react";

import gql from "graphql-tag";
import { Query } from "react-apollo";
import { Spinner } from "../shared/Loading";
import ReleaseArtistsTopBar from "./ReleasesArtistsTopBar";
import ArtistBox from "./ArtistBox";

const ARTISTS = gql`
  query releases {
    me {
      id
      userName
      artist {
        id
        userName
        picture
        createdAt
      }
    }
  }
`;

class ReleasesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Query query={ARTISTS}>
        {({ data, error, loading, refetch }) => {
          if (loading) return <Spinner />;
          if (error) return "...";
          if (!data.me) {
            return refetch();
          }
          const { artist: artists } = data.me;
          const labelName = data.me.userName;

            return (
            <>
              <ReleaseArtistsTopBar {...this.props} />

              <div className="d-flex flex-wrap">
                {artists
                  .sort((a, b) => ("" + a.userName).localeCompare(b.userName))

                  .map(a => (
                    <ArtistBox key={a.id} {...a} labelName={labelName} />
                  ))}
              </div>
            </>
          );
        }}
      </Query>
    );
  }
}

export default ReleasesList;
