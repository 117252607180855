import React, { Component } from "react";

import { Link } from "react-router-dom";
import moment from "moment";

import TrackList from "./TrackList";

import coverImg from "../../images/artistCover.jpg";
// import { format } from "date-fns";

import { Query } from "react-apollo";
import { Spinner } from "../shared/Loading";
import TextError from "../shared/Errors";

import GET_RELEASE from "../queries/getReleaseById";
import PublishModal from "./PublishModal";
import FirstTimeAfterRelease from './FirstTimeAfterRelease';
import gql from "graphql-tag";

const CURRENT_USER_QUERY = gql`
  query ME {
    me {
      id
      adminApproval
      paypal {
        selfUrl
        actionUrl
        merchantId
        merchantIdInPayPal
        permissionsGranted
        accountStatus
        consentStatus
        productIntentID
        isEmailConfirmed
        returnMessage
      }
    }
  }
`;

const PublishBtn = ({ status, loading, onClickHandler, artist }) => {
  return (
    <Query query={CURRENT_USER_QUERY}>
      {({ loading, error, data }) => {
        if (!data.me) return null;
        if (error) {
          return null;
        }
        const isDisabled = () => {
          if (!artist.paypal || !artist.adminApproval) {
            return {
              status: true,
              paypal: !artist.paypal,
              adminApprove: !artist.adminApproval
            };
          // accountStatus condition is not needed. 
          // PayPal sends BUSINESS_ACCOUNT if a business account was created; otherwise, nothing(null) is sent.
          // } else if (!data.me.paypal.accountStatus || !data.me.adminApproval) {
          //   return {
          //     status: true,
          //     paypal: !data.me.paypal.accountStatus,
          //     adminApprove: !data.me.adminApproval
          //   };
          } else {
            return { status: false };
          }
        };

        return (
          <>
            <p style={{ fontSize: "18px", marginTop: "30px" }}>
              Tracklist
              {status === "Pending" ? (
                <button
                  disabled={isDisabled().status}
                  style={{
                    minWidth: "120px",
                    backgroundColor: "green"
                  }}
                  onClick={onClickHandler}
                  className="btn-black btn-lg float-right"
                >
                  {loading ? `...` : "PUBLISH"}
                </button>
              ) : (
                <span className="float-right badge badge-success">
                  Published
                </span>
              )}
            </p>
            <p
              style={{
                padding: "15px 0",
                textAlign: "right",
                fontSize: "10px"
              }}
            >
              {isDisabled().status && (
                <>
                  {isDisabled().adminApprove && (
                    <span style={{ display: "block" }}>
                      - admin approval required in order to publish content
                    </span>
                  )}
                  {isDisabled().paypal && (
                    <span style={{ display: "block" }}>
                      - please connect your PayPal Account before publishing
                      content
                    </span>
                  )}
                </>
              )}
            </p>
          </>
        );
      }}
    </Query>
  );
};

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstTimePublish: true,
      showFirstPublishModal: false,
      oneTimeAfterReleaseAddModal: false,
    };
  }

  async componentDidMount() {
    const firstTimePublish = await localStorage.getItem("firstTimePublish");
    if (firstTimePublish) {
      this.setState({ firstTimePublish: false });
    }

    const firstTimeAfterRelease = localStorage.getItem("firstTimeAfterRelease");
    if(firstTimeAfterRelease === 'true') {
      localStorage.setItem('firstTimeAfterRelease', false);
      this.setState({ oneTimeAfterReleaseAddModal: true });
    }
  }

  showFirstTimePublish = bool => {
    if (bool) {
      localStorage.setItem("firstTimePublish", true);
    }
    this.setState({ showFirstPublishModal: bool, firstTimePublish: false });
  };

  render() {
    const { match } = this.props;
    const releaseId = parseInt(match.params.releaseId);
    return (
      <div style={{ padding: "15px" }} className="row summary-row">
        <Query query={GET_RELEASE} variables={{ releaseId: releaseId }}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <Spinner />;
            if (error) return <TextError error={error} />;
            if (!data.getRelease) return <h3>Resource not found</h3>;
            if (data.getRelease.artist === null) {
              refetch();
              return <Spinner />;
            }

            const {
              title,
              publicationDate,
              cover,
              type,
              artist,
              label,
              tracks,
              territories,
              status
            } = data.getRelease;

            const formatedPubDate = moment(publicationDate)
              .utc()
              .format("MMMM Do, YYYY");

            return (
              <>
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-12">
                      <div
                        style={{
                          backgroundImage: `url('${cover ? cover : coverImg}')`
                        }}
                        className="summary-cover"
                      />
                    </div>
                    <div className="col-sm-12">
                      <h2 className="summary-title">{title}</h2>
                      <p className="summary-artist">{artist.userName}</p>

                      <div className="summary-details">
                        {label && (
                          <p>
                            <strong>Label: </strong>
                            {label.userName}
                          </p>
                        )}
                        <p>
                          <strong>Release type: </strong> {type}
                        </p>
                        <p>
                          <strong>Release date: </strong>

                          {formatedPubDate}
                        </p>
                        <p>
                          <strong>Territory(ies) of release: </strong>
                          {territories &&
                            territories.map((t, i) => {
                              if (t === "worldwide") {
                                return (
                                  <span
                                    key={i}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {t}
                                  </span>
                                );
                              } else {
                                return <span key={i}> {t}</span>;
                              }
                            })}
                        </p>
                        <br />
                        <p>
                          <strong>ID: </strong> {match.params.releaseId}
                        </p>

                        <br />
                      </div>
                      <Link
                        style={{ marginTop: "5px", display: "inline-block" }}
                        to={`${match.url}/details`}
                        className="btn-bare font-weight-bolder "
                      >
                        Edit release details
                      </Link>
                      <br />
                      <Link
                        style={{ marginTop: "5px", display: "inline-block" }}
                        to={`${match.url}/credits`}
                        className="btn-bare font-weight-bolder "
                      >
                        Edit release credits
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="row trackList-row">
                    <div className="col">
                      <PublishBtn
                        status={status}
                        loading={loading}
                        artist={artist}
                        onClickHandler={e =>
                          this.setState({ publishModal: true })
                        }
                      />

                      <TrackList
                        match={this.props.match}
                        date={publicationDate}
                        releaseId={releaseId}
                        tracks={
                          tracks
                          // .sort((a, b) => a.trackOrder < b.trackOrder ? -1 : 1)
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Query>
        {this.state.publishModal && (
          <PublishModal
            releaseId={releaseId}
            onHide={() => this.setState({ publishModal: false })}
          />
        )}
        {this.state.oneTimeAfterReleaseAddModal && 
          <FirstTimeAfterRelease
            onHide={() => this.setState({ oneTimeAfterReleaseAddModal: false })}
          />
        }
      </div>
    );
  }
}

export default Summary;
