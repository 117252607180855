import React from "react";
import { NavLink } from "react-router-dom";

const AdminAppPageNav = () => {
  return (
    <div className="row adminApp-topBar">
      <div className="col-sm-12 no-gutters">
        <div className="adminApp-nav">
          <NavLink
            to="/admin/app/index"
            activeClassName="active"
            className="adminApp-link"
          >
            Index
          </NavLink>
          <NavLink
            to={`/admin/app/new-tracks`}
            activeClassName="active"
            className="adminApp-link"
          >
            New Tracks
          </NavLink>
          <NavLink
            to="/admin/app/new-releases"
            activeClassName="active"
            className="adminApp-link"
          >
            New Releases
          </NavLink>
          <NavLink
            to="/admin/app/4x4"
            activeClassName="active"
            className="adminApp-link"
          >
            4x4
          </NavLink>
          <NavLink
            to="/admin/app/homepage"
            activeClassName="active"
            className="adminApp-link"
          >
            Homepage
          </NavLink>
          <NavLink
            to="/admin/app/Us"
            activeClassName="active"
            className="adminApp-link"
          >
            Us
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default AdminAppPageNav;
