import React from "react";
import { Subscription } from "react-apollo";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { AUTH_TOKEN } from "../../constants";

const DEL_USER_SUB = gql`
  subscription deleteUserSub {
    deleteUser {
      id
    }
  }
`;

const DeleteUser = props => (
  <Subscription subscription={DEL_USER_SUB}>
    {({ data, loading }) => {
      const { id, client, history } = props;
      if (!loading && data) {
        if (id === data.deleteUser.id) {
          localStorage.removeItem(AUTH_TOKEN);
          client.clearStore();
          history.push("/");
        }
      }
      return null;
    }}
  </Subscription>
);

export default withRouter(DeleteUser);
