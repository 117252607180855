import gql from 'graphql-tag'

const GET_ALL_RELEASES = gql`
query getReleasesQuery {
  getReleases {
    id
    title
    cover
    type
    publicationDate
    artist {
      id
      userName
      city
      state
      email
      picture
    }
    label{
      userName
      email
    }

  }
}
`
export default GET_ALL_RELEASES;