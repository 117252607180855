import React from "react";
import { Fieldset } from "../shared/formFields";

import states from "../../data/states";
import countries from "../../data/countries";

class LocationSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: "United States",
      selectedState: "Select",
      selectedCity: ""
    };
  }
  render() {
    const { selectedCountry, selectedCity, selectedState } = this.state;
    const { onCountrySelect, onStateSelect, onCitySelect } = this.props;
    return (
      <>
        <div className="form-group row">
          <Fieldset
            name="country"
            label="Country"
            fieldClass="form-control"
            labelClass="col-sm-3 col-form-label"
            fieldContainerClass="col-sm-9"
            component="select"
            value={selectedCountry}
            onChange={e => {
              onCountrySelect(e.target.value);
              this.setState({ selectedCountry: e.target.value });
            }}
          >
            {countries.map((country, i) => (
              <option key={i} value={country.name}>
                {country.name}
              </option>
            ))}
          </Fieldset>
        </div>

        {/* suggest state if US is selected otherwise show bare input     */}
        {selectedCountry === "United States" ? (
          <div className="form-group row">
            <Fieldset
              name="state"
              label="State / Region"
              fieldClass="form-control"
              labelClass="col-sm-3 col-form-label"
              fieldContainerClass="col-sm-9"
              component="select"
              value={selectedState}
              onChange={e => {
                onStateSelect(e.target.value);
                this.setState({ selectedState: e.target.value });
              }}
            >
              <option key={99} value={null}>
                Select State
              </option>
              {states.map((state, i) => (
                <option key={i} value={state.name}>
                  {state.name}
                </option>
              ))}
            </Fieldset>
          </div>
        ) : (
          <div className="form-group row">
            <Fieldset
              name="state"
              type="text"
              label="State / Region"
              fieldClass="form-control"
              labelClass="col-sm-3 col-form-label"
              fieldContainerClass="col-sm-9"
              placeholder={"select"}
              onChange={e => {
                this.setState({ selectedState: e.target.value });
                onStateSelect(e.target.value);
              }}
            />
          </div>
        )}

        <div className="form-group row">
          <Fieldset
            name="city"
            type="text"
            label="City"
            fieldClass="form-control"
            labelClass="col-sm-3 col-form-label"
            fieldContainerClass="col-sm-9"
            value={selectedCity}
            onChange={e => {
              this.setState({ selectedCity: e.target.value });
              onCitySelect(e.target.value);
            }}
          />
        </div>
      </>
    );
  }
}

export default LocationSelect;
