import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from 'moment';

import coverImg from "../../../images/release.png";
import { Dots } from "../../shared/SvgIcons";

const IndexReleaseItem = props => {
  const { id, title, artist, publicationDate, type, cover, onDelete } = props;
  return (
    <div className="row">
      <div className="indexItem">
        <div className="indexItem-cover">
          <img src={cover} alt="" />
        </div>

        <div className="indexItem-details">
          <p className="indexItem-details__title">{title}</p>
          <p className="indexItem-details__author">{artist && artist.userName}</p>
          <p className="indexItem-details__date">
            <span style={{ color: "#000" }}> {type} | </span>
            {moment(publicationDate).format('M.D.YYYY')}
          </p>
          <p>#{id}</p>
        </div>
        <div className="indexItem-btn">
          <button className="btn-bare">
            {" "}
            <Dots />
          </button>
          <div className="indexItem-contextMenu">
            <Link to={`/admin/app/index/release/${id}`} className="btn-bare">
              Edit release
            </Link>
            <hr />
            <button onClick={e => onDelete({id, title})} className="btn-bare red">
              Delete release
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

IndexReleaseItem.propTypes = {
  id: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
  artist: PropTypes.object.isRequired,
  publicationDate: PropTypes.string,
  cover: PropTypes.string,
  type: PropTypes.string,
  onDelete: PropTypes.func.isRequired
};

IndexReleaseItem.defaultProps = {
  id: "999",
  title: "release title",
  artist: { userName: "release author" },
  publicationDate: "release pub date",
  type: "Type",
  cover: coverImg
};

export default IndexReleaseItem;
