import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { userSocialLinks } from "../../dataNormalization/dataNormalization";
import { Link } from "react-router-dom";
import Modal from "../../shared/modal/Modal";

import { Fieldset } from "../../shared/formFields";

import { getBase64 } from "../../../utils/utils";

import states from "../../../data/states";
import countries from "../../../data/countries";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { LoadingBtn } from "../../shared/Loading";

import CURRENT_USER_QUERY from "../../queries/me.js";
// import ALLOWED_COUNTRIES from "../../../data/allowed-countries";

const UPDATE_LABEL = gql`
  mutation EditUserMutation(
    $userName: String
    $firstName: String
    $lastName: String
    $city: String
    $state: String
    $country: String
    $about: String
    $picture: String
    $links: [URL]
    $acceptUsage: Boolean
  ) {
    editUser(
      userName: $userName
      firstName: $firstName
      lastName: $lastName
      city: $city
      state: $state
      country: $country
      about: $about
      picture: $picture
      links: $links
      acceptUsage: $acceptUsage
    ) {
      id
      token
      userName
      firstName
      lastName
      city
      state
      country
      about
      picture
      links
      acceptUsage
    }
  }
`;

class DetailsSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formImg: "",
      baseImg: "",
      imageSize: "",
      showModal: false
    };
  }

  onUploadChange = e => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5000000) {
        this.setState({ imageSize: "Too large" });
        return;
      } else {
        this.setState({ imageSize: "" });
      }

      getBase64(file).then(result => {
        this.setState(() => ({
          formImg: URL.createObjectURL(file),
          baseImg: result
        }));
      });
    } else {
    }
  };

  render() {
    const {
      about,
      city,
      state,
      picture,
      country,
      userName,
      firstName,
      lastName,
      links,
      acceptUsage,
      role
    } = this.props.me;

    const isArtist = role === "Artist";

    return (
      <Mutation
        mutation={UPDATE_LABEL}
        refetchQueries={[{ query: CURRENT_USER_QUERY }]}
        awaitRefetchQueries={true}
      >
        {(editUser, { loading, error }) => (
          <div className="register-form__container">
            <Formik
              initialValues={{
                labelName: userName,
                firstName: firstName,
                lastName: lastName,
                labelAbout: about,
                labelPicture: "",
                locationCountry: country,
                locationState: state,
                locationCity: city,
                links: userSocialLinks.fromServer(links),
                acceptUsage: acceptUsage || false
              }}
              validationSchema={Yup.object().shape({
                labelName: Yup.string().required("Required"),
                locationCountry: Yup.string().test(
                  "as",
                  "at present, platform access is unavailable to your territory",
                  val => {
                    return true;
                    // return ALLOWED_COUNTRIES.includes(val);
                  }
                ),
                locationState: Yup.string().required("Required"),
                locationCity: Yup.string().required("Required"),
                labelAbout: Yup.string().required("Required")
              })}
              onSubmit={values => {
                editUser({
                  variables: {
                    userName: values.labelName,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    city: values.locationCity,
                    state: values.locationState,
                    country: values.locationCountry,
                    about: values.labelAbout,
                    picture: this.state.baseImg,
                    links: userSocialLinks.fromFormToVariables(values.links),
                    acceptUsage: values.acceptUsage
                  }
                });
              }}
              render={({
                isSubmitting,
                handleReset,
                values,
                errors,
                setFieldTouched,
                setFieldValue
              }) => (
                <Form className="register-form">
                  <div className="row">
                    <div className="col">
                      <div className="form-group row">
                        <Fieldset
                          name="labelName"
                          type="text"
                          label={
                            this.props.me.role === "Artist"
                              ? "Artist Name"
                              : "Label Name"
                          }
                          fieldClass="form-control"
                          labelClass="col-sm-3 col-form-label"
                          fieldContainerClass="col-sm-9"
                        />
                      </div>
                      <div className="form-group row">
                        <Fieldset
                          name="firstName"
                          type="text"
                          label="Username (First)"
                          fieldClass="form-control"
                          labelClass="col-sm-3 col-form-label"
                          fieldContainerClass="col-sm-9"
                        />
                      </div>
                      <div className="form-group row">
                        <Fieldset
                          name="lastName"
                          type="text"
                          label="Username (Last)"
                          fieldClass="form-control"
                          labelClass="col-sm-3 col-form-label"
                          fieldContainerClass="col-sm-9"
                        />
                      </div>

                      <div className="form-group row">
                        <Fieldset
                          name="locationCountry"
                          label="Country"
                          fieldClass="form-control"
                          labelClass="col-sm-3 col-form-label"
                          fieldContainerClass="col-sm-9"
                          component="select"
                          onChange={e => {
                            setFieldTouched("locationCountry", true);
                            setFieldValue("locationCountry", e.target.value);
                            // validateField("country");
                          }}
                        >
                          {countries.map((country, i) => (
                            <option key={i} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </Fieldset>
                      </div>

                      {/* suggest state if US is selected otherwise show bare input     */}
                      {values.locationCountry === "United States" ? (
                        <div className="form-group row">
                          <Fieldset
                            name="locationState"
                            label="State"
                            fieldClass="form-control"
                            labelClass="col-sm-3 col-form-label"
                            fieldContainerClass="col-sm-9"
                            component="select"
                          >
                            {states.map((state, i) => (
                              <option key={i} value={state.name}>
                                {state.name}
                              </option>
                            ))}
                          </Fieldset>
                        </div>
                      ) : (
                        <div className="form-group row">
                          <Fieldset
                            name="locationState"
                            type="text"
                            label="State"
                            fieldClass="form-control"
                            labelClass="col-sm-3 col-form-label"
                            fieldContainerClass="col-sm-9"
                          />
                        </div>
                      )}

                      <div className="form-group row">
                        <Fieldset
                          name="locationCity"
                          type="text"
                          label="City"
                          fieldClass="form-control"
                          labelClass="col-sm-3 col-form-label"
                          fieldContainerClass="col-sm-9"
                        />
                      </div>
                    </div>
                    {/* form left coll end */}
                    <div className="col">
                      <div className="form-group row">
                        <div className="col-sm-6">
                          <p>{isArtist ? "Artist photo" : "Label logo"}</p>
                          <br />
                          <br />
                          <span className="upload-info">
                            JPG or PNG;
                            <br />
                            at least 512x512;
                            <br />
                            max. 5 MB
                          </span>
                          <hr />

                          {role === "Artist" && (
                            <div className="row">
                              <div className="col-sm-8">
                                <label className="" htmlFor="imageUseAgree">
                                  Agree for the van homepage use.
                                  {isArtist && (
                                    <button
                                      onClick={() =>
                                        this.setState({ showModal: true })
                                      }
                                      style={{
                                        display: "inline",
                                        padding: "0 5px"
                                      }}
                                      className="btn-bare"
                                    >
                                      [?]
                                    </button>
                                  )}
                                </label>
                              </div>
                              <div className="col-sm-4">
                                <Field
                                  name="acceptUsage"
                                  component="input"
                                  type="checkbox"
                                  checked={values.acceptUsage}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="col-sm-6">
                          <div className="upload-area float-right">
                            <input
                              type="file"
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={e => this.onUploadChange(e)}
                            />

                            {this.state.imageSize.length > 0 ? (
                              <span className="input-error">File to large</span>
                            ) : (
                              ""
                            )}

                            {this.state.formImg.length === 0 ? (
                              picture ? (
                                <img
                                  src={
                                    this.state.formImg.length > 1
                                      ? this.state.formImg
                                      : picture
                                  }
                                  alt=""
                                />
                              ) : (
                                <p>Upload image</p>
                              )
                            ) : (
                              <img
                                src={
                                  this.state.formImg.length > 1
                                    ? this.state.formImg
                                    : picture
                                }
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* form right coll end */}
                  </div>
                  {/* top row end */}

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group row">
                        <Fieldset
                          name="links"
                          type="text"
                          label="Website &amp; social links"
                          fieldClass="form-control"
                          labelClass="col-sm-2 col-form-label"
                          fieldContainerClass="col"
                          rows="4"
                        />
                      </div>
                      <div className="form-group row">
                        <Fieldset
                          name="labelAbout"
                          component="textarea"
                          type="text"
                          label="About"
                          fieldClass="form-control"
                          labelClass="col-sm-1 col-form-label"
                          fieldContainerClass="col"
                          rows="4"
                        />
                      </div>

                      <div className="form-group row justify-content-between">
                        <div className="col d-flex justify-content-end">
                          {loading ? (
                            <LoadingBtn />
                          ) : (
                            <button
                              disabled={
                                Object.keys(errors).length ||
                                this.state.imageSize.length
                              }
                              className="btn-black float-right"
                              type="submit"
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            />
            {this.state.showModal && (
              <Modal
                header="Agree for the van homepage use"
                onHide={() => this.setState({ showModal: false })}
              >
                <p>
                  approve this photo for use on platform homepage. see{" "}
                  <Link
                    style={{ color: "#000", textDecoration: "underline" }}
                    target="_blank"
                    to="/us/terms"
                  >
                    {" "}
                    T&amp;Cs{" "}
                  </Link>{" "}
                  for info on photo approval rights
                </p>
              </Modal>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default DetailsSettings;
