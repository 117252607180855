import gql from "graphql-tag";

const GET_RELEASE = gql`
  query getReleaseQuery($releaseId: ID!) {
    getRelease(releaseId: $releaseId) {
      id
      title
      type
      publicationDate
      cover
      price
      territories
      status
      artist {
        id
        userName
        adminApproval
        paypal {
          selfUrl
          actionUrl
          merchantId
          merchantIdInPayPal
          permissionsGranted
          accountStatus
          consentStatus
          productIntentID
          isEmailConfirmed
          returnMessage
        }
      }
      label {
        id
        userName
      }
      tracks {
        id
        title
        trackOrder
        preRelease
        publicationDate
      }
    }
  }
`;

export default GET_RELEASE;
