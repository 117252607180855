import React from "react";
import { Query } from "react-apollo";
import NewReleases from "./NewReleases";

import GET_NEW_RELEASES from "./GET_NEW_RELEASES";

const NewReleasesContainer = props => {
  return (
    <Query
      query={GET_NEW_RELEASES}
      variables={{ date: "2019-04-15T00:00:00.000Z" }}
      fetchPolicy={"network-only"}
    >
      {({ data, loading, error, client }) => {
        if (loading) return "loading";
        if (error) return "error";
        const { getNewReleases } = data;
        return (
          <NewReleases
            isNewReleasesCollectionCreated={getNewReleases}
            releases={getNewReleases ? getNewReleases.releases : []}
            {...props}
          />
        );
      }}
    </Query>
  );
};

export default NewReleasesContainer;
