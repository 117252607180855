function isValidURL(str) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export const userSocialLinks = {
  fromServer(links) {

    if(links && links.length){
      return links.join()
    }else{

      return ""
    }

  },

  fromFormToVariables(links) {
    return links
      .replace(/\s/g, "") // trim white spaces
      .split(",") // split to array
      .filter(link => link.length) // filter empty strings
      .filter(link => isValidURL(link))
      .map(link => {
        // add http to meet RFC3986 format: https://www.ietf.org/rfc/rfc3986.txt.
        if (!link.includes("http")) {
          return `http://${link}`;
        }

        return link;
      });
  }
};
