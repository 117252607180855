import React from "react";
import ReactDom from "react-dom";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import USER_PAYPAL from "./user-paypal-query.js";
import TextError from "../../shared/Errors";

const UNLINK_PAYPAL = gql`
  mutation {
    unlinkPaypalAccount
  }
`;

const modalRoot = document.getElementById("modal-root");

class UnlinkPayPalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  el = document.createElement("div");
  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDom.createPortal(
      <div clasname="vanModal-wrapper">
        <div className="vanModal-backdrop">
          <div className="vanModal-container">
            <div className="vanModal">
              <div className="vanModal-header">
                <h5>disconnect PayPal account</h5>
                <hr />
              </div>
              <div className="vanModal-body">
                <p>
                  if you have a different PayPal account you wish to connect,
                  you can do so after disconnecting this account
                </p>
                <hr />
                <br />
              </div>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="vanModal-footer"
              >
                <button className="btn-black" onClick={this.props.onHide}>
                  cancel
                </button>

                <Mutation
                  mutation={UNLINK_PAYPAL}
                  awaitRefetchQueries={true}
                  onCompleted={data => {
                    this.props.onHide();
                  }}
                  refetchQueries={[
                    {
                      query: USER_PAYPAL
                    }
                  ]}
                >
                  {(unlinkPaypalAccount, { loading, error, data }) => {
                    if (error) return <TextError error={error} />;
                    return (
                      <button
                        style={{
                          minWidth: "120px",
                          backgroundColor: "red"
                        }}
                        onClick={() => unlinkPaypalAccount()}
                        className="btn-black  float-right"
                      >
                        {loading ? `...` : "CONFIRM"}
                      </button>
                    );
                  }}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
      </div>,

      this.el
    );
  }
}

export default UnlinkPayPalModal;
