import React from "react";
import { withRouter } from "react-router";
import logo from "../../images/logo.png";
import LogOutBtn from "../login/LogOutBtn";

const SmallScreenBlockade = ({ history }) => {
  return (
    <div className="smallScreens-info">
      <img style={{ width: "70px", margin: "20px" }} src={logo} alt="" />
      <p>
        access full artist & label controls and content management tools on
        desktop
      </p>
      <LogOutBtn btnClass="btn-black" btnText="ok" history={history} />
    </div>
  );
};

export default withRouter(SmallScreenBlockade);
