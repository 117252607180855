import React from "react";
import { NavLink } from "react-router-dom";

const UsersNav = () => {
  return (
    <div className="row adminApp-topBar">
      <div className="col-sm-12 no-gutters">
        <div className="adminApp-nav">
          <NavLink
            to="/admin/users/new"
            activeClassName="active"
            className="adminApp-link"
          >
            New Users
          </NavLink>
          <NavLink
            to="/admin/users/all"
            activeClassName="active"
            className="adminApp-link"
          >
            All users
          </NavLink>
          <NavLink
            to="/admin/users/deleted"
            activeClassName="active"
            className="adminApp-link"
          >
            Deleted users
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default UsersNav;
