import React from "react";

const errorStyle = {
  color: "red",
  margin: "10 0",
  fontSize: "12"
};

const TextError = props => {
  return (
    <p style={errorStyle}>
      {props.error.graphQLErrors.map(e => (
        <span> {e.message} </span>
      ))}
    </p>
  );
};

export default TextError;
