import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
// import NumberFormat from "react-number-format";

// import DatePicker from "react-datepicker";
// import CustomdatePicker from "../shared/CustomDatePicker";
import { SingleDatePicker } from "react-dates";

import moment from "moment-timezone";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { Mutation, Query, ApolloConsumer } from "react-apollo";
import gql from "graphql-tag";
// import { parseISO } from "date-fns";

import { Fieldset } from "../shared/formFields";

import { getBase64 } from "../../utils/utils";

import { LoadingBtn } from "../shared/Loading";
import TerritorySelect from "./TerritorySelect";
import { Spinner } from "../shared/Loading";
import TextError from "../shared/Errors";

import CURRENT_USER from "../queries/me";
import Uploader from "../shared/Uploader";

import GET_RELEASE from "../queries/getReleaseById";

const EDIT_RELEASE_DETAILS = gql`
  mutation editReleaseDetailsMutation(
    $releaseId: ID
    $title: String!
    $publicationDate: DateTime
    $type: AlbumType
    $cover: String
    $territories: [String]
    $price: Float
  ) {
    editRelease(
      releaseId: $releaseId
      release: {
        title: $title
        publicationDate: $publicationDate
        type: $type
        cover: $cover
        territories: $territories
        price: $price
      }
    ) {
      id
      title
      type
      publicationDate
      cover
      price
      territories
      status
    }
  }
`;

class EditReleaseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formImg: "",
      imageSize: "",
      wasDateChanged: false
    };
  }
  onUploadChange = async e => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 5000000) {
        this.setState({ imageSize: "Too large" });
        return;
      } else {
        this.setState({ imageSize: "" });
      }

      getBase64(file).then(result => {
        this.setState(() => ({
          formImg: URL.createObjectURL(file),
          baseImg: result
        }));
      });
    } else {
      return;
    }
  };

  render() {
    // moment.tz.setDefault("America/New_York");

    const { match } = this.props;
    const releaseId = parseInt(match.params.releaseId);
    return (
      <Query query={GET_RELEASE} variables={{ releaseId: releaseId }}>
        {({ loading: queryLoading, error: queryError, data }) => {
          if (queryLoading) return <Spinner />;
          if (queryError) return <TextError error={queryError} />;
          if (!data.getRelease) return <h3> Resource not found </h3>;
          const {
            id,
            title,
            publicationDate,
            cover,
            type,
            label,
            territories,
            artist,
            price,
            status
          } = data.getRelease;

          return (
            <div className="register-form__container">
              <Mutation
                mutation={EDIT_RELEASE_DETAILS}
                refetchQueries={[
                  { query: GET_RELEASE, variables: { releaseId: parseInt(id) } }
                ]}
                onCompleted={data => {
                  const backTo = match.url.replace("/details", "");
                  this.props.history.replace(backTo);
                }}
              >
                {(editRelease, { loading, error, data }) => {
                  return (
                    <Formik
                      initialValues={{
                        author: artist.id,
                        title: title,
                        label: label ? label.userName : "",
                        type: type,
                        territories: territories,
                        publicationDate: publicationDate,
                        cover: cover ? "unchanged" : "",
                        price: parseFloat(price).toFixed(2) || 10.0
                      }}
                      validationSchema={Yup.object().shape({
                        title: Yup.string().required("required"),
                        price: Yup.number()
                          .typeError("must be a number")
                          .positive("must be greater than zero")
                          .required("required"),
                        author: Yup.string().required("required"),
                        publicationDate: Yup.string().required("required")
                      })}
                      onSubmit={values => {
                        let editPayload = {
                          releaseId: id,
                          title: values.title,
                          type: values.type,
                          territories: values.territories,
                          price: parseFloat(values.price)
                        };

                        if (values.cover !== "unchanged") {
                          editPayload.cover = values.cover;
                        }

                        if (this.state.wasDateChanged) {
                          editPayload.publicationDate = new Date(
                            moment(values.publicationDate).format("YYYY-MM-DD")
                          ).toISOString();
                        }

                        console.table(editPayload);

                        editRelease({
                          variables: { ...editPayload }
                        });
                      }}
                      render={({ values, setFieldValue, setFieldTouched }) => (
                        <Form className="register-form">
                          <div className="row">
                            <div className="col">
                              <div className="form-group row">
                                <Fieldset
                                  name="author"
                                  label="Artist"
                                  fieldClass="form-control"
                                  labelClass="col-sm-3 col-form-label"
                                  fieldContainerClass="col-sm-9"
                                  component="select"
                                  disabled={true}
                                >
                                  {/* TODO: EXcLUDE IN Admin */}
                                  {match.url.includes("admin") ? (
                                    <option value={artist.id}>
                                      {artist.userName}
                                    </option>
                                  ) : (
                                    <ApolloConsumer>
                                      {client => {
                                        const me = client.readQuery({
                                          query: CURRENT_USER
                                        });
                                        const artists = me.me.artist;
                                        return artists.map(artist => (
                                          <option
                                            key={artist.id}
                                            value={artist.id}
                                          >
                                            {artist.userName}
                                          </option>
                                        ));
                                      }}
                                    </ApolloConsumer>
                                  )}
                                </Fieldset>
                              </div>

                              <div className="form-group row">
                                <Fieldset
                                  name="label"
                                  type="text"
                                  label="Label"
                                  fieldClass="form-control"
                                  labelClass="col-sm-3 col-form-label"
                                  fieldContainerClass="col-sm-9"
                                  disabled={true}
                                />
                              </div>
                              <div className="form-group row">
                                <Fieldset
                                  name="title"
                                  type="text"
                                  label="Title"
                                  fieldClass="form-control"
                                  labelClass="col-sm-3 col-form-label"
                                  fieldContainerClass="col-sm-9"
                                  placeholder="Enter release title"
                                />
                              </div>

                              {/* suggest state if US is selected otherwise show bare input     */}

                              <div className="form-group row">
                                <Fieldset
                                  name="type"
                                  label="Type"
                                  fieldClass="form-control"
                                  labelClass="col-sm-3 col-form-label"
                                  fieldContainerClass="col-sm-9"
                                  component="select"
                                >
                                  <option type="Album">Album</option>
                                  <option type="Single">Single</option>
                                  <option type="EP">EP</option>
                                </Fieldset>
                              </div>
                              <div className="form-group row">
                                <div className="col-sm-3">
                                  <label htmlFor="">Territory</label>
                                </div>
                                <div className="col">
                                  <TerritorySelect
                                    territories={territories}
                                    onChange={data => {
                                      const territory = data.map(t => t.value);
                                      setFieldValue("territories", territory);
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <div className="col-sm-3">
                                  <label htmlFor="">Release date</label>
                                </div>
                                <div className="col">
                                  <SingleDatePicker
                                    // showClearDate={true}
                                    // isOutsideRange={() => false}
                                    disabled={status === "Published"}
                                    isOutsideRange={() => false}
                                    inputIconPosition="after"
                                    small={true}
                                    block={false}
                                    numberOfMonths={1}
                                    date={moment(values.publicationDate).utc()}
                                    onDateChange={date => {
                                      setFieldValue(
                                        "publicationDate",
                                        new Date(date)
                                      );
                                      this.setState({ wasDateChanged: true });
                                    }}
                                    focused={this.state.focused}
                                    onFocusChange={({ focused }) =>
                                      this.setState({ focused })
                                    }
                                    openDirection="up"
                                    hideKeyboardShortcutsPanel={true}
                                  />

                                  {/* <DatePicker
                                    disabled={status === "Published"}
                                    customInput={
                                      <CustomdatePicker
                                        onClick={e => e.preventDefault()}
                                      />
                                    }
                                    selected={parseISO(values.publicationDate)}
                                    onChange={val => {
                                      const mm = moment(val).format(
                                        "YYYY-MM-DD"
                                      );

                                      this.setState({ wasDateChanged: true });
                                      setFieldValue(
                                        "publicationDate",
                                        new Date(val)
                                      );
                                    }}
                                  /> */}
                                </div>
                              </div>
                            </div>
                            {/* form left coll end */}
                            <div className="col">
                              <div className="form-group row">
                                <div className="col-sm-6">
                                  <p>Cover Art:</p>
                                  <br />
                                  <br />
                                  <span className="upload-info">
                                    JPG or PNG;
                                    <br />
                                    at least 512x512;
                                    <br />
                                    max. 1 MB
                                  </span>
                                  <br />
                                  <br />
                                  <hr />
                                </div>

                                <div className="col-sm-6">
                                  <Uploader
                                    image={cover}
                                    onGetBaseString={baseResult => {
                                      setFieldTouched("cover", true);
                                      setFieldValue("cover", baseResult);
                                    }}
                                  />
                                </div>
                              </div>
                              {/* <div
                                style={{ marginTop: "50px" }}
                                className="form-group row"
                              >
                                <label
                                  className="col-sm-4 col-form-label"
                                  htmlFor="price"
                                >
                                  price (USD)
                                </label>

                                <div className="col-sm-4">
                                  <span className="input-error"> </span>
                                  <div className="currencyInput-wrap">
                                    <NumberFormat
                                      value={values.price}
                                      decimalScale={2}
                                      allowNegative={false}
                                      thousandSeparator={true}
                                      prefix={"$ "}
                                      fixedDecimalScale={true}
                                      onValueChange={values => {
                                        const { floatValue } = values;
                                        // formattedValue = $2,223
                                        // value ie, 2223
                                        setFieldValue("price", floatValue);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div> */}
                            </div>
                            {/* form right coll end */}
                          </div>
                          {/* top row end */}

                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group row justify-content-between">
                                <div className="col-sm-12">
                                  {loading ? (
                                    <LoadingBtn />
                                  ) : (
                                    <button
                                      disabled={false}
                                      className="btn-black float-right"
                                      type="submit"
                                    >
                                      Save Release Details
                                    </button>
                                  )}
                                </div>
                                <div className="col-sm-12">
                                  {error && (
                                    <p
                                      style={{ color: "red" }}
                                      className="text-right"
                                    >
                                      Something went wrong :(
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row" />
                        </Form>
                      )}
                    />
                  );
                }}
              </Mutation>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default EditReleaseDetails;
