import React from 'react';


class CustomdatePicker extends React.Component {

    render () {
      return (
        <button type="button"
          className="date-input"
          onClick={this.props.onClick}>
          {this.props.value}
        </button>
      )
    }
  }
  

  
export default CustomdatePicker