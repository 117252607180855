import { gql } from "apollo-boost";

export default gql`
  query getUsers {
    getUsers {
      id
      userName
      city
      state
      country
      role
      about
      picture
      verified
      adminApproval
      frontendId
    }
  }
`;
