import React from "react";

import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { LoadingBtn } from "../../shared/Loading";
import GET_NEW_RELEASES from "./GET_NEW_RELEASES";

// const CREATE_NEW_RELEASES = gql`
//   mutation createNewRelease($date: String!, $content: [ID]!) {
//     createNewRelease(newReleases: { date: $date, content: $content }) {
//       id
//       releases {
//         id
//       }
//     }
//   }
// `;
const SAVE_NEW_RELEASES = gql`
  mutation editNewRelease($date: String!, $content: [ID]!) {
    editNewRelease(newReleases: { date: $date, content: $content }) {
      id
      releases {
        id
      }
    }
  }
`;

const SaveNewReleases = props => {
  const {
    showSave,
    releases,
    onSavedChange,
    isNewReleasesCollectionCreated
  } = props;
  const savePayLoad = releases.map(t => t.id);
  return (
    <Mutation
      mutation={
        SAVE_NEW_RELEASES
        // isNewReleasesCollectionCreated ? SAVE_NEW_RELEASES : CREATE_NEW_RELEASES
      }
      refetchQueries={[
        {
          query: GET_NEW_RELEASES,
          variables: { date: "2019-04-15T00:00:00.000Z" }
        }
      ]}
      onCompleted={data => {
        onSavedChange();
      }}
    >
      {(editNewRelease, { loading, error, data }) => {
        if (error) {
        }
        return (
          <>
            {loading ? (
              <LoadingBtn />
            ) : (
              showSave && (
                <button
                  onClick={() => {
                    editNewRelease({
                      variables: {
                        date: "2019-04-15T00:00:00.000Z",
                        content: [...savePayLoad]
                      }
                    });
                  }}
                  className="btn-black"
                >
                  {isNewReleasesCollectionCreated ? "SAVE" : "CREATE NEW"}
                </button>
              )
            )}
            {error && <span>Error :( </span>}
          </>
        );
      }}
    </Mutation>
  );
};

export default SaveNewReleases;
