import React from "react";
import DateRow from "../DateRow";

import FourByFourSearch from "./4x4Search";
import ReleasesDragableList from "./4x4DragableList";
import FourByFourModal from "./4x4Modal";
import Save4x4 from "./Save4x4";

class FourByFourPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      releases: this.props.releases || [],
      showModal: false,
      wasChanged: false,
      warning: false
    };
  }
  _toggleWarning = () => {
    // show track already on the list
    this.setState({ warning: !this.state.warning });
    setTimeout(() => {
      this.setState({ warning: !this.state.warning });
    }, 2000);
  };

  addTo4x4 = release => {
    const isInCollection = this.state.releases.some(r => r.id === release.id);

    if (isInCollection) {
      this._toggleWarning();
      return;
    }

    this.setState(state => ({
      releases: [...state.releases, release],
      wasChanged: true
    }));
  };
  deleteReleaseFromList = releaseId => {
    const releasesList = this.state.releases.filter(r => releaseId !== r.id);
    this.setState({ releases: releasesList, wasChanged: true });
  };

  onUpdateOrder = releases => {
    this.setState({ releases: releases, wasChanged: true });
  };

  onSavedChange = () => {
    this.setState({ wasChanged: false });
  };

  render() {
    const { warning } = this.state;
    return (
      <>
        <div className="row 4x4wrapper">
          <div className="col-sm-12">
            <DateRow {...this.props} />
            <div className="row">
              <div
                style={{ backgroundColor: warning ? "#bbffbb17" : "" }}
                className="col-sm-6 fxf-left"
              >
                <div className="row fxfColection-heading sticky">
                  <p>
                    4x4
                    <span style={{ fontSize: "12px", marginLeft: "20px" }}>
                      ({this.state.releases.length} items in collection)
                    </span>
                  </p>
                  <Save4x4
                    releases={this.state.releases}
                    showSave={this.state.wasChanged}
                    onSavedChange={this.onSavedChange}
                    isCollectionCreated={this.props.isCollectionCreated}
                  />
                  {warning && (
                    <span className="badge  badge-warning">
                      Already on the list
                    </span>
                  )}
                </div>
                <div className="fxfColection-list">
                  <ReleasesDragableList
                    onUpdateOrder={this.onUpdateOrder}
                    deleteReleaseFromList={this.deleteReleaseFromList}
                    releases={this.state.releases}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row fxfColection-heading">
                  <p>Releases Library:</p>
                </div>
                <FourByFourSearch addTo4x4={this.addTo4x4} {...this.props} />
              </div>
            </div>
          </div>
        </div>
        {this.state.showModal && (
          <FourByFourModal
            releases={this.state.releases}
            onCacnel={e => this.setState({ showModal: false })}
          />
        )}
      </>
    );
  }
}

export default FourByFourPage;
