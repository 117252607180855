import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Fieldset } from "../shared/formFields";

import { Spinner, LoadingBtn } from "../shared/Loading";
import TextError from "../shared/Errors";

import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import TagInput from "../shared/TagInput";
import PersonnelsForm from "./PersonnelsForm";

const GET_RELEASE = gql`
  query($releaseId: ID!) {
    getRelease(releaseId: $releaseId) {
      id
      produced
      engineered
      mixed
      mastered
      studio
      designed
      photo
      thanks
      additionalCredits
      copyrightNote
      about
      personnels {
        name
        instrument
      }
    }
  }
`;

const EDIT_RELEASE_CREDITS = gql`
  mutation editReleaseCreditsMutation(
    $releaseId: ID
    $produced: String
    $engineered: String
    $mixed: String
    $mastered: String
    $studio: String
    $designed: String
    $photo: String
    $thanks: String
    $additionalCredits: String
    $copyrightNote: String
    $about: String
    $personnels: [PersonnelInput]
  ) {
    editRelease(
      releaseId: $releaseId
      release: {
        produced: $produced
        engineered: $engineered
        mixed: $mixed
        mastered: $mastered
        studio: $studio
        designed: $designed
        photo: $photo
        thanks: $thanks
        additionalCredits: $additionalCredits
        copyrightNote: $copyrightNote
        about: $about
        personnels: $personnels
      }
    ) {
      id
      produced
      engineered
      mixed
      mastered
      studio
      designed
      photo
      thanks
      additionalCredits
      copyrightNote
      about
      personnels {
        name
        instrument
      }
    }
  }
`;

class AddReleaseCredits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTagTouched: false
    };
  }
  render() {
    const { match } = this.props;
    const releaseId = parseInt(match.params.releaseId);
    return (
      <Query query={GET_RELEASE} variables={{ releaseId: releaseId }}>
        {({ loading: queryLoading, error: queryError, data }) => {
          if (queryLoading) return <Spinner />;
          if (queryError) return <TextError error={queryError} />;
          if (!data.getRelease) return <h3> Resource not found </h3>;

          const {
            produced,
            engineered,
            mixed,
            mastered,
            studio,
            designed,
            photo,
            thanks,
            additionalCredits,
            copyrightNote,
            about,
            // tags,
            personnels
          } = data.getRelease;

          // const mapTagsToValues = tags
          //   ? tags.split(", ").map(t => ({ value: t, label: t }))
          //   : "";

          // const mapTagsToSelect = tags => {
          //   if (!tags) return "";
          //   return tags.split(", ").map(t => ({ value: t, label: t }));
          // };

          // const mapTagsToForm = tags => {
          //   if (!tags) return "";
          //   return tags
          //     .replace(/\s+/g, "")
          //     .split(",")
          //     .map(t => ({ value: t, label: t }));
          // };

          const mapTagsToVariables = tags => {
            if (tags[0].value) {
              return tags.map(v => v.value).join(", ");
            } else {
              return tags.join(", ");
            }
          };

          return (
            <Mutation
              mutation={EDIT_RELEASE_CREDITS}
              onCompleted={data => {
                const backTo = match.url.replace("/credits", "");
                this.props.history.replace(backTo);
              }}
            >
              {(editReleaseCredits, { loading, error, data }) => {
                return (
                  <div className="register-form_containee">
                    <Formik
                      initialValues={{
                        produced: produced || "",
                        mixed: mixed || "",
                        studio: studio || "",
                        photo: photo || "",
                        additionalCredits: additionalCredits || "",
                        engineered: engineered || "",
                        mastered: mastered || "",
                        designed: designed || "",
                        thanks: thanks || "",
                        copyrightNote: copyrightNote || "",
                        about: about || "",
                        // tags: mapTagsToForm(tags),
                        personnels: personnels.length
                          ? personnels
                          : [{ name: "", instrument: "" }]
                      }}
                      onSubmit={values => {
                        let updatePayload = {
                          releaseId: releaseId,
                          produced: values.produced,
                          engineered: values.engineered,
                          mixed: values.mixed,
                          mastered: values.mastered,
                          studio: values.studio,
                          designed: values.designed,
                          photo: values.photo,
                          thanks: values.thanks,
                          additionalCredits: values.additionalCredits,
                          copyrightNote: values.copyrightNote,
                          about: values.about,
                          personnels: values.personnels.map(per => ({
                            name: per.name,
                            instrument: per.instrument
                          })) // exclude __typename
                        };

                        if (this.state.isTagTouched) {
                          updatePayload.tags = mapTagsToVariables(values.tags);
                        }

                        editReleaseCredits({ variables: { ...updatePayload } });
                      }}
                      render={({ values, setFieldValue }) => {
                        return (
                          <Form className="register-form">
                            <div className="row personel-row">
                              <div className="col-sm-1">
                                <p
                                  style={{
                                    marginTop: "45px",
                                    textAlign: "center"
                                  }}
                                >
                                  Personnel
                                </p>
                              </div>

                              <PersonnelsForm
                                name="personnels"
                                releaseId={releaseId}
                                personnels={values.personnels}
                              />
                            </div>
                            <div className="row credits-row">
                              <div className="col-sm-12">
                                <div className="row">
                                  <Fieldset
                                    name="produced"
                                    type="text"
                                    label="produced"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label"
                                    fieldContainerClass="col-sm-5"
                                  />
                                  <Fieldset
                                    name="engineered"
                                    type="text"
                                    label="engineered"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label"
                                    fieldContainerClass="col-sm-5"
                                  />
                                </div>
                                <div className="row">
                                  <Fieldset
                                    name="mixed"
                                    type="text"
                                    label="mixed"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label"
                                    fieldContainerClass="col-sm-5"
                                  />
                                  <Fieldset
                                    name="mastered"
                                    type="text"
                                    label="mastered"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label"
                                    fieldContainerClass="col-sm-5"
                                  />
                                </div>
                                <div className="row">
                                  <Fieldset
                                    name="studio"
                                    type="text"
                                    label="studio"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label"
                                    fieldContainerClass="col-sm-5"
                                  />
                                  <Fieldset
                                    name="designed"
                                    type="text"
                                    label="designed"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label"
                                    fieldContainerClass="col-sm-5"
                                  />
                                </div>
                                <div className="row">
                                  <Fieldset
                                    name="photo"
                                    type="text"
                                    label="photo"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label"
                                    fieldContainerClass="col-sm-5"
                                    placeholder="Cover image credits"
                                  />
                                  <Fieldset
                                    name="thanks"
                                    type="text"
                                    label="thanks"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label"
                                    fieldContainerClass="col-sm-5"
                                  />
                                </div>

                                <div className="row">
                                  <Fieldset
                                    name="additionalCredits"
                                    type="text"
                                    component="textarea"
                                    label="Additional credits"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label"
                                    fieldContainerClass="col-sm-5"
                                    placeholder="Additional credits"
                                    rows="4"
                                  />
                                  <Fieldset
                                    name="copyrightNote"
                                    type="text"
                                    component="textarea"
                                    label="Copyright Notice"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label"
                                    fieldContainerClass="col-sm-5"
                                    rows="4"
                                  />
                                </div>
                                <div className="row">
                                  <Fieldset
                                    name="about"
                                    type="text"
                                    component="textarea"
                                    label="About the Release"
                                    fieldClass="form-control"
                                    labelClass="col-sm-1 col-form-label login"
                                    fieldContainerClass="col-sm-11"
                                    rows="4"
                                  />
                                </div>
                                {/* <div className="row">
                                  <div className="col-sm-1">
                                    <label htmlFor="">Tags</label>
                                  </div>
                                  <div className="col-sm-11">
                                    <TagInput
                                      options={mapTagsToValues}
                                      onTagChange={tagVals => {
                                        this.setState({ isTagTouched: true });

                                        setFieldValue("tags", tagVals);
                                      }}
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div
                              className="row d-flex justify-content-between"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="col" />
                              <div className="col">
                                {loading ? (
                                  <LoadingBtn />
                                ) : (
                                  <button
                                    disabled={false}
                                    className="btn-black float-right"
                                    type="submit"
                                  >
                                    Save Release Credits
                                  </button>
                                )}
                              </div>
                              <div className="col-sm-12">
                                {error && (
                                  <p
                                    style={{ color: "red" }}
                                    className="text-right"
                                  >
                                    Something went wrong :(
                                  </p>
                                )}
                              </div>
                            </div>
                          </Form>
                        );
                      }}
                    />
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default AddReleaseCredits;
