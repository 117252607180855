import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

// import DateRow from "../DateRow";

import { Spinner } from "../../shared/Loading";
import CoverGallery from "./CoverGallery";
import HomeEditForm from "./HomeEditForm";

const GET_HOMEPAGE = gql`
  query {
    homePageByWeek(weekStart: "2019-04-15") {
      id
      credits
      labelColor
      picture
      weekStart
      weekEnd
      createdAt
      updatedAt
    }
  }
`;

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelColor: "black",
      credits: "",
      showGallery: false
    };
  }

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <>
        {/* <DateRow {...this.props} /> */}

        <Query query={GET_HOMEPAGE}>
          {({ data, loading, error }) => {
            if (loading) return <Spinner />;
            if (error) return <p>{error}</p>;
            return (
              <HomeEditForm
                showGallery={() => this.setState({ showGallery: true })}
                {...data.homePageByWeek}
              />
            );
          }}
        </Query>

        {this.state.showGallery && (
          <CoverGallery onHide={() => this.setState({ showGallery: false })} />
        )}
      </>
    );
  }
}

export default HomeContainer;
