import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import coverImage from "../../../images/artistCover.jpg";

import { Lines, Minus } from "../../shared/SvgIcons";
import moment from "moment";
import Modal from "../../shared/modal/Modal";
import AdminReleaseNotes from "../adminNotes/AdminReleaseNotes";
import Territories from "../newReleases/TerritoriesList";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const NewReleaseItem = ({
  provided,
  snapshot,
  release,
  deleteReleaseFromList,
  updateReleases,
  territories
}) => {
  const [showModal, setModal] = React.useState(false);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={`row fxfItem ${snapshot.isDragging ? "dragging" : ""}`}
    >
      <div className="col-sm-9 d-flex">
        <div
          style={{
            backgroundImage: `url("${release.cover || coverImage}")`,
            margin: "10px"
          }}
          className="fxfItem-cover"
        />
        <div style={{ flexGrow: 2 }}>
          <p className="fxfItem-title">{release.title}</p>
          <p className="fxfItem-release">
            {release.artist ? release.artist.userName : "Artist Name *BUG"}{" "}
          </p>
          <p className="fxfItem-artist">
            {moment(release.publicationDate)
              .utc()
              .format("MM.DD.YYYY")}
            <span className="fxfItem-type"> {release.type}</span> |{" "}
            <Territories territories={territories} />
          </p>
          <p style={{ fontSize: "10px" }}>
            <button onClick={e => setModal(true)}>admin notes</button> :
            <span> {release.additionalAdminInfo}</span>
          </p>
        </div>
      </div>
      <div className="col-sm-3 icons-col">
        <div className="fxfItem-icons float-right">
          <>
            <button
              onClick={e => deleteReleaseFromList(release.id)}
              className="fxfIem-btn"
            >
              <Minus icoClass="fxfIem-ico" />
            </button>
            <Lines />
          </>
        </div>
      </div>
      {showModal && (
        <Modal
          header="Edit Release Notes"
          ok="close"
          onHide={e => setModal(false)}
        >
          <AdminReleaseNotes
            updateReleases={updateReleases}
            releaseId={release.id}
          />
        </Modal>
      )}
    </div>
  );
};

const draggableStyles = {
  drag: { backgroundColor: "rgba(0, 0, 0, 0.041)", padding: "10px" },
  default: {
    padding: "10px 20px 10px 0"
  }
};

class ReleasesDragableList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      releases: this.props.releases
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.releases.length === nextProps.releases.length &&
      prevState.releases.sort().every(function(value, index) {
        return value === nextProps.releases.sort()[index];
      })
    ) {
      return null;
    } else {
      return {
        releases: nextProps.releases
      };
    }
  }

  update = () => {
    this.setState({
      releases: this.props.releases
    });
  };

  updateReleases = ({ releaseId, notes }) => {
    const updatedReleases = this.state.releases.map(r => {
      if (r.id === releaseId) {
        return { ...r, additionalAdminInfo: notes };
      } else {
        return { ...r };
      }
    });
    this.setState({ releases: updatedReleases });
  };

  deleteReleaseFromList = releaseId => {
    this.props.deleteReleaseFromList(releaseId);
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const releases = reorder(
      this.state.releases,
      result.source.index,
      result.destination.index
    );

    this.setState({
      releases
    });
    this.props.onUpdateOrder(releases);
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <p style={{ fontSize: "18px", marginTop: "30px" }}>Sequence:</p>

        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={
                snapshot.isDraggingOver
                  ? draggableStyles.drag
                  : draggableStyles.default
              }
            >
              {this.state.releases.map((release, index) => (
                <Draggable
                  key={release.id}
                  draggableId={release.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <NewReleaseItem
                      provided={provided}
                      snapshot={snapshot}
                      deleteReleaseFromList={this.deleteReleaseFromList}
                      release={release}
                      additionalAdminInfo={this.props.additionalAdminInfo}
                      updateReleases={this.updateReleases}
                      {...release}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default ReleasesDragableList;
