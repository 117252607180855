import React, { Component } from "react";
import Dropzone from "dropzone";
import axios from "axios";
import { withApollo } from "react-apollo";
import gql from "graphql-tag";

// import { getBase64 } from "../../utils/utils";
// import { LoadingBtn } from "./Loading";
// import trashIco from "../../images/trash.svg";
import Modal from "./modal/Modal";
import { AUTH_TOKEN } from "../../constants";
import Alert from "./alert/Alert";

const GET_S3_SIGNEDURL = gql`
mutation getS3SignedUrl(
  $mediaType: String!
  $fileType: String!
  $fileFormat: String!
  $fileExtension: String!
) {
  getS3SignedUrl(
    mediaType: $mediaType
    fileType: $fileType
    fileFormat: $fileFormat
    fileExtension: $fileExtension
  ) {
    signedUrl
    awsKey
    awsBucketName
  }
}
`;

const NOTIFY_ADMIN = gql`
  mutation notifyAdminFailedTrackUpload(
    $file: String,
    $message: String
  ) {
    notifyAdminFailedTrackUpload(
      file: $file,
      message: $message
    )
  }
`;

// const Loading = props => {
//   const { /* total, progress, */ isLoading } = props;
//   return (
//     <div>
//       {isLoading && (
//         <div className="col-sm-12 d-flex align-items-center">
//           {/* <p style={{ margin: "0 10px" }}>
//             {" "}
//             {progress} / {total}{" "}
//           </p> */}
//           <LoadingBtn />
//         </div>
//       )}
//     </div>
//   );
// };

class TracksUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tracks: [],
      showModal: false,
      showModalLossPollicy: false,
      loading: {
        errors: [],
        isLoading: false,
        total: 0,
        progress: 0,
      },
      showAlert: false,
      alert: {
        type: null,
        message: null,
      },
      currentIndex: 0,
    };
    this.dropzoneRef = React.createRef();
    Dropzone.autoDiscover = false;
  }

  componentDidMount() {
    const _this = this;
    const { client } = this.props;
    // const [getS3SignedUrl] = useMutation(GET_S3_SIGNEDURL)
    const dropzoneOptions = {
      // The URL will be changed for each new file being processing
      url: "/",

      // Since we're going to do a `PUT` upload to S3 directly
      method: "put",

      // Hijack the xhr.send since Dropzone always upload file by using formData
      // ref: https://github.com/danialfarid/ng-file-upload/issues/743
      sending(file, xhr) {
        let _send = xhr.send;
        xhr.send = () => {
          _send.call(xhr, file);
        };
      },

      // Upload one file at a time since we're using the S3 pre-signed URL scenario
      parallelUploads: 1,
      uploadMultiple: false,
      timeout: 4 * 60 * 1000,
      acceptedFiles: "audio/mp3, audio/mpeg, audio/mp4, audio/m4a, audio/aac, audio/ogg",
        // "audio/x-ms-wma, video/x-ms-asf, audio/aac, audio/mp3, audio/mpeg, audio/x-rmf, audio/flac, audio/ogg, audio/vnd.wav, audio/wave, audio/wav, audio/x-wav, audio/x-pn-wav, audio/L24, audio/mp4, audio/m4a, audio/mp4, video/m4a, audio/x-m4a",
      // Content-Type should be included, otherwise you'll get a signature
      // mismatch error from S3. We're going to update this for each file.
      header: "",

      // Customize the wording
      dictDefaultMessage: document.getElementById("dropzone-message").innerHTML,

      // We're going to process each file manually (see `accept` below)
      autoProcessQueue: false,

      // We're providing remove link to user
      addRemoveLinks: true,

      // Here we request a signed upload URL when a file being accepted
      async accept(file, done) {
        _this.handleFile(file);
        const validBitrate = true; //await _this._validateTrackBitrate(file);
        if (!validBitrate) {
          done("Audio track is not in supported bitrate");
          _this.showAlert({
            type: "error",
            message: "Audio track is not in supported bitrate",
          });
          return;
        }
        const mediaType = "Track";
        const [fileType, fileFormat] = file.type.split('/');
        const fileExtension = file.name.split(".")[1];
        client
          .mutate({
            mutation: GET_S3_SIGNEDURL,
            variables: {
              mediaType,
              fileType,
              fileFormat,
              fileExtension,
            },
          })
          .then((data) => {
            file.signedUrl = data.data.getS3SignedUrl.signedUrl;
            file.awsKey = data.data.getS3SignedUrl.awsKey;
            file.awsBucketName = data.data.getS3SignedUrl.awsBucketName;
            done();
            // Manually process each file
            setTimeout(() => _this.dropzone.processFile(file));
          })
          .catch((error) => {
            done(`Failed to upload file ${error.message}`);
          });
      },
    };
    this.dropzone = new Dropzone(this.dropzoneRef.current, dropzoneOptions);

    // Set signed upload URL for each file
    this.dropzone.on("processing", (file) => {
      this.dropzone.options.url = file.signedUrl;
    });

    this.dropzone.on("addedfile", (progress) => {
      // files added to dropzone
    });

    this.dropzone.on("queuecomplete", (progress) => {
      // queue completed
    });

    this.dropzone.on("totaluploadprogress", (e, v, t) => {
      // queue progress bar
    });

    this.dropzone.on("uploadprogress", (file, progress, byteSent) => {
      if (file.previewElement) {
        const progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
        const progressPercentage = Math.floor(progress)+ "%";
        progressElement.style.width = progressPercentage;
        progressElement.textContent = progressPercentage;
      }
    });

    this.dropzone.on("success", (file, v) => {
      // file successfully uploaded to server
      const currentIndex = this.state.currentIndex;
      this.setState({ currentIndex: currentIndex + 1 });
      this._proccessFile(file, currentIndex).then((track) => {
        const updatedTracks = [...this.state.tracks, track];
        this.setState((state) => ({
          tracks: updatedTracks,
          loading: {
            ...state.loading,
            isLoading: false,
            total: 0,
            progress: 0,
          },
        }));
        this.showAlert({
          type: "success",
          message: `File ${track.title} uploaded successfully`,
        });

        this.props.onGetTracks(updatedTracks);
      });
    });

    this.dropzone.on("removedfile", (file) => {
      const trackTitle = file.name.substring(
        file.name.lastIndexOf("/") + 1,
        file.name.lastIndexOf(".")
      );
      this.handleRemoveTrack(trackTitle);
    });

    this.dropzone.on("error", (file, message) => {
      if (!file.accepted || file.status === 'error') this.dropzone.removeFile(file);
      this.handleUploadFail(file, message);
      this.showAlert({ type: "error", message: message });
    });
  }

  _createTrack = (file, index) => {
    return new Promise((resolve, reject) => {
      // const au = new Audio();

      const au = document.createElement("video");
      au.setAttribute("src", URL.createObjectURL(file));

      au.addEventListener("loadeddata", function () {});
      au.addEventListener("error", function (progres) {
        return;
      });
      au.addEventListener("loadedmetadata", function () {
        const track = {
          id: index,
          duration: au.duration,
          title: file.name.substring(
            file.name.lastIndexOf("/") + 1,
            file.name.lastIndexOf(".")
          ),
          size: (file.size / 1000000).toFixed(2),
          // trackFile: file,
          awsKey: file.awsKey,
          awsBucketName: file.awsBucketName,
        };

        resolve(track);
      });
    });
  };

  _proccessFiles = async (files) => {
    const tracks = [];

    for (var i = 0; i < files.length; i++) {
      const track = await this._createTrack(files[i], i);
      tracks.push(track);

      this.setState((state) => ({
        loading: {
          ...state.loading,
          progress: state.loading.progress + 1,
        },
      }));
    }
    return tracks;
  };

  _proccessFile = async (file, index) => {
    const track = await this._createTrack(file, index);

    this.setState((state) => ({
      loading: {
        ...state.loading,
        progress: state.loading.progress + 1,
      },
    }));

    return track;
  };

  _validateFiles = (files) => {
    const fileTypes = /(\.|\/)(mp3|wav|flac|aac|aiff|ogg|vorbis|vma|pcm|mp4|alac|m4a)$/i;
    const collection = [];

    for (var i = 0; i < files.length; i++) {
      if (fileTypes.test(files[i].type) || fileTypes.test(files[i].name)) {
        collection.push(files[i]);
      } else {
        const errMessage = `${files[i].name} unsuported file type ${files[i].type}  `;

        this.setState((state) => ({
          loading: {
            ...state.loading,
            errors: [...state.loading.errors, errMessage],
          },
        }));
      }
    }

    return collection;
  };

  _validateFile = (file) => {
    // const fileTypes = /(\.|\/)(mp3|wav|flac|aac|aiff|ogg|vorbis|vma|pcm|mp4|alac|m4a)$/i;
    const fileTypes = /(\.|\/)(mp3|aac|ogg|mp4|m4a)$/i;
    const collection = [];

    if (fileTypes.test(file.type) || fileTypes.test(file.name)) {
      collection.push(file);
    } else {
      const errMessage = `${file.name} unsuported file type ${file.type}  `;

      this.setState((state) => ({
        loading: {
          ...state.loading,
          errors: [...state.loading.errors, errMessage],
        },
      }));
    }

    return collection[0];
  };

  handleRemoveTrack = (e) => {
    const collection = this.state.tracks.filter((track) => track.title !== e);
    this.setState((state) => ({ tracks: [...collection] }));
    this.props.onGetTracks(collection);
  };

  handleFiles = (e) => {
    e.preventDefault();

    if (!e.target.files.length) {
      return;
    }

    const files = this._validateFiles(e.target.files);

    this.setState((state) => ({
      loading: {
        ...state.loading,
        isLoading: true,
        total: files.length,
      },
    }));

    this._proccessFiles(files).then((tracks) => {
      this.setState((state) => ({
        tracks: tracks,
        loading: {
          ...state.loading,
          isLoading: false,
          total: 0,
          progress: 0,
        },
      }));

      this.props.onGetTracks(tracks);
    });
  };

  handleFile = (file) => {
    this._validateFile(file);

    this.setState((state) => ({
      loading: {
        ...state.loading,
        isLoading: true,
        total: state.total + 1,
      },
    }));
  };

  handleUploadFail = (file, message) => {
    const { client } = this.props;
    client.mutate({
      mutation: NOTIFY_ADMIN,
      variables: {
        file: file.name,
        message: message,
      }
    })
    .then(data => {})
    .catch(error => {})
  };

  _validateTrackBitrate = (file) => {
    return new Promise((resolve, reject) => {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        audioContext.decodeAudioData(arrayBuffer).then(function (buffer) {
          const duration = buffer.duration || 1;
          const bitrate = Math.floor((file.size * 0.008) / duration);
          // Check bitrate in range of 280-360 for 320kbps quality audio
          if (bitrate <= 360 && bitrate >= 280) resolve(true);
          else resolve(false);
        });
      };
      reader.readAsArrayBuffer(file);
    });
  };

  showAlert = ({ type, message }, duration = 3000) => {
    this.setState({
      alert: {
        type,
        message,
      },
      showAlert: true,
    });
    this._hideAlert(duration);
  };

  _hideAlert = (duration) => {
    setTimeout(() => {
      this.setState({
        alert: {
          type: null,
          message: null,
        },
        showAlert: false,
      });
    }, duration);
  };

  render() {
    const { tracks /* loading */ } = this.state;
    const { submitionError } = this.props;

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group row justify-content-between">
            <div className="col-sm-12">
              <div className="fileUpload-container">
                <div className="row">
                  <div className="col-sm-12">
                    <p>
                      {" "}
                      {/* File Spec: 192kbps MP3 */} compressed files only. preferred upload spec 320kbps mp3<br />{" "}
                      <small>
                        Bulk upload allows for import of multiple audio files at
                        once
                      </small>
                    </p>
                    <div>
                      <button
                        type="button"
                        onClick={(e) => this.setState({ showModal: true })}
                        className="btn-bare popUp-link"
                      >
                        cover songs policy <span className="small">[?]</span>
                      </button>
                      <button
                        type="button"
                        onClick={(e) =>
                          this.setState({ showModalLossPollicy: true })
                        }
                        className="btn-bare popUp-link"
                      >
                        loss-of-rights policy <span className="small">[?]</span>
                      </button>
                      {this.state.showModal && (
                        <Modal
                          header="cover songs policy"
                          onHide={() => this.setState({ showModal: false })}
                        >
                          <p>
                            only avail content for which you have (or can rep &
                            warrant) 100% rights. if there are cover songs on
                            your release, for which you cannot assert all
                            rights, do not upload those tracks. if cover songs
                            constitute the majority or entirety of a release,
                            the release cannot be offered at all. for more
                            information, go to ‘Content’ section of
                            <span> </span>
                            <a
                              className="black"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${window.location.origin}/us/terms`}
                            >
                              <span> T&amp;Cs</span>
                            </a>
                          </p>
                        </Modal>
                      )}
                      {this.state.showModalLossPollicy && (
                        <Modal
                          header="loss-of-rights policy"
                          onHide={() =>
                            this.setState({ showModalLossPollicy: false })
                          }
                        >
                          <p>
                            for any content over which you have lost partial or
                            total rights, remove all audio & metadata. for more
                            information, go to ‘Content’ section of
                            <a
                              className="black"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${window.location.origin}/us/terms`}
                            >
                              <span> T&amp;Cs</span>
                            </a>
                          </p>
                        </Modal>
                      )}
                    </div>
                    {((submitionError && this.props.touched.tracks) ||
                      (this.props.touched.tracks && tracks.length === 0)) && (
                      <span className="badge badge-danger">
                        {submitionError}
                      </span>
                    )}

                    {/* <Loading {...loading} /> */}

                    {/* <hr />
                    <ul className="tracksToUpload-list">
                      {tracks.map(track => (
                        <li key={track.id}>
                          {track.title}

                          <span className="small"> ({track.size} mb)</span>

                          <button
                            type="button"
                            onClick={e => this.handleRemoveTrack(track.title)}
                            className="btn-bare"
                          >
                            <img src={trashIco} alt="" />
                          </button>
                        </li>
                      ))}
                    </ul> */}
                  </div>
                  <div className="col">
                    <div className="col">
                      <div className="dropzone-area">
                        <div
                          id="dropzone"
                          className="dropzone"
                          ref={this.dropzoneRef}
                        >
                          {/* Not displayed, just for Dropzone's `dictDefaultMessage` option */}
                          <div
                            id="dropzone-message"
                            style={{ display: "none" }}
                          >
                            <h4 className="dropzone-title">
                              Drop audio files here or click to select
                            </h4>
                            <div className="dropzone-info">
                              You can upload multiple audio files at once
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <button className="btn-black float-right rel">
                      <input
                        onClick={this.props.setFieldTouched}
                        disabled={loading.isLoading}
                        className="file-input"
                        onChange={e => this.handleFiles(e)}
                        type="file"
                        multiple
                        accept="
                        audio/x-ms-wma, 
                        video/x-ms-asf,
                        audio/aac, 
                        audio/mp3, 
                        audio/mpeg, 
                        audio/x-rmf,
                        audio/flac, 
                        audio/ogg, 
                        audio/vnd.wav, 
                        audio/wave
                        audio/wav,
                        audio/x-wav,
                        audio/x-pn-wav, 
                        auido/L24,
                        audio/mp4,
                        audio/m4a,
                        audio/mp4,
                        video/m4a, audio/x-m4a"
                      />
                      Select Audio Files
                    </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showAlert && (
          <Alert
            type={this.state.alert.type}
            message={this.state.alert.message}
          />
        )}
      </div>
    );
  }
}

export default withApollo(TracksUploader);
