import React from "react";

import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_USERS = gql`
  query getUsersMutation($role: UserRole) {
    getUsers(role: $role) {
      id
      userName
      about
      state
      city
      picture
    }
  }
`;

const userList = () => {
  return (
    <Query query={GET_USERS} variables={{ role: "Label" }}>
      {({ loading, data }) => {
        if (loading) return "Loading...";

        return (
          <div className="container">
            {/* data.getUsers.map(user => {
                    return(
                       <User {...user}></User>
                    
                      )
                    
                    
                    }) */}
          </div>
        );
      }}
    </Query>
  );
};

export default userList;
