import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import TransactionNav from "./TransactionNav";
import CustomerReportsList from "./CustomerReportsList";
// import BankTransferReportList from "./BankTransferReportList";

const TransactionReports = props => {
  return (
    <div className="row usersPage">
      <div className="col-sm-12" style={{ paddingTop: "20px" }}>
        <TransactionNav />
      </div>

      <Switch>
        <Route exact path="/admin/transaction-report">
          <Redirect to="/admin/transaction-report/customer" />
        </Route>
        <Route
          exact
          path="/admin/transaction-report/customer"
          render={props => (
            <div className="col-sm-12" style={{ paddingTop: "20px" }}>
              <div className="row pendingAccounts-row">
                <CustomerReportsList {...props} />
              </div>
            </div>
          )}
        />
        {/* <Route
          exact
          path="/admin/transaction-report/bank-transfer"
          render={props => (
            <div className="col-sm-12" style={{ paddingTop: "20px" }}>
              <h3>Bank Transfers</h3>
              <hr />
              <div className="row pendingAccounts-row">
                <BankTransferReportList {...props} />
              </div>
            </div>
          )}
        /> */}
      </Switch>
    </div>
  );
};

export default TransactionReports;
