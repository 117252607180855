import React from "react";
import { Switch, Route } from "react-router-dom";

import IndexPage from "./IndexPage";
import Summary from "../../addRelease/Summary";
import EditReleaseDetails from "../../addRelease/EditReleaseDetails";
import AddReleaseCredits from "../../addRelease/AddReleaseCredits";
import EditTrackDetails from "../../addRelease/EditTrackDetails";
import { ChevronLeft } from "../../shared/SvgIcons";
// import ArtistConatiner from "../../editArtist/ArtistContainer";
import AdminArtistDetails from "./AdminArtistDetails";

const IndexContainer = () => {
  return (
    <>
      <Route
        path="/admin/app/index/(artist|release)"
        render={props => (
          <div>
            <button
              style={{ padding: "10px" }}
              onClick={e => props.history.goBack()}
              className="btn-bare"
            >
              <ChevronLeft width="30" height="20" />
            </button>
          </div>
        )}
      />
      <Switch>
        <Route
          path="/admin/app/index/artist/:artistId"
          component={AdminArtistDetails}
        />

        <Route
          path="/admin/app/index/release/:releaseId/track/:trackId"
          component={EditTrackDetails}
        />

        <Route
          path="/admin/app/index/release/:releaseId/details"
          component={EditReleaseDetails}
        />

        <Route
          path="/admin/app/index/release/:releaseId/credits"
          component={AddReleaseCredits}
        />

        <Route path="/admin/app/index/release/:releaseId" component={Summary} />

        <Route component={IndexPage} />
      </Switch>
    </>
  );
};

export default IndexContainer;
