import React from "react";
import { Formik, Form } from "formik";
import { Fieldset } from "../../shared/formFields";

import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import { Spinner, LoadingBtn } from "../../shared/Loading";
import VanEditor from "../../shared/editor/Editor";

const GETCONFIG = gql`
  query getConfig {
    getConfig {
      termsConditions
      privacy
      copyright
      why
      email
      everyThingElseEmail
      founded
    }
  }
`;

const EDITCONFIG = gql`
  mutation editConfig(
    $termsConditions: String!
    $privacy: String!
    $copyright: String!
    $why: String!
    $email: EmailAddress!
    $everyThingElseEmail: EmailAddress!
    $founded: String!
  ) {
    editConfig(
      config: {
        termsConditions: $termsConditions
        privacy: $privacy
        copyright: $copyright
        why: $why
        email: $email
        everyThingElseEmail: $everyThingElseEmail
        founded: $founded
      }
    ) {
      termsConditions
      privacy
      copyright
      why
      email
      everyThingElseEmail
      founded
    }
  }
`;

class AdminUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Query query={GETCONFIG}>
        {({ data, loading, error }) => {
          if (loading) return <Spinner />;
          if (error) return "Something went wrong :(";
          const config = data.getConfig;
          return (
            <Mutation mutation={EDITCONFIG}>
              {(editConfig, { loading: loadingM, error: errorM, data }) => {
                return (
                  <Formik
                    initialValues={{
                      ...config
                    }}
                    onSubmit={values => {
                      editConfig({ variables: values });
                    }}
                    render={({ values, setFieldValue, submitForm }) => (
                      <div className="row">
                        <div className="col-sm-12">
                          <Form>
                            <div className="editor-row">
                              <hr />
                              <div className="form-group row">
                                <h1>Terms &amp; Conditions</h1>
                                <VanEditor
                                  val={config ? config.termsConditions : null}
                                  onChange={val =>
                                    setFieldValue("termsConditions", val)
                                  }
                                />
                              </div>
                              <hr />
                            </div>

                            <div className="editor-row">
                              <hr />
                              <div className="form-group row">
                                <h1>Privacy</h1>
                                <VanEditor
                                  val={config ? config.privacy : null}
                                  onChange={val =>
                                    setFieldValue("privacy", val)
                                  }
                                />
                              </div>
                              <hr />
                            </div>

                            <div className="editor-row">
                              <hr />
                              <h1>Copyright</h1>
                              <div className="form-group row">
                                <VanEditor
                                  val={config ? config.copyright : null}
                                  onChange={val =>
                                    setFieldValue("copyright", val)
                                  }
                                />
                              </div>
                              <hr />
                            </div>

                            <div className="editor-row">
                              <hr />
                              <h1>Why</h1>
                              <div className="form-group row">
                                <VanEditor
                                  val={config ? config.why : null}
                                  onChange={val => setFieldValue("why", val)}
                                />
                              </div>
                              <hr />
                            </div>

                            <div className="form-group row">
                              <Fieldset
                                name="email"
                                type="email"
                                label="Technical Help Email:"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col"
                              />
                            </div>
                            <div className="form-group row">
                              <Fieldset
                                name="everyThingElseEmail"
                                type="email"
                                label="Everything Else Email:"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col"
                              />
                            </div>
                            <div className="form-group row">
                              <Fieldset
                                name="founded"
                                type="text"
                                label="Founded in:"
                                fieldClass="form-control"
                                labelClass="col-sm-2 col-form-label"
                                fieldContainerClass="col"
                              />
                            </div>
                          </Form>
                        </div>
                        <div
                          style={{
                            position: "sticky",
                            zIndex: 20,
                            bottom: "20px"
                          }}
                          className="col-sm-12"
                        >
                          <br />
                          {loadingM ? (
                            <LoadingBtn />
                          ) : (
                            <button
                              onClick={e => submitForm()}
                              className="btn-black float-right"
                            >
                              SAVE
                            </button>
                          )}
                          <br />
                          <br />
                        </div>
                      </div>
                    )}
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default AdminUs;
