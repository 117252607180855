import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Fieldset } from "../shared/formFields";
import TopBar from "../shared/TopBar";
import { Link } from "react-router-dom";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";

import states from "../../data/states";
import countries from "../../data/countries";

import { LoadingBtn } from "../shared/Loading";
import Uploader from "../shared/Uploader";

import CURRENT_USER from "../queries/me";
import Modal from "../shared/modal/Modal";

const CREATE_ARTIST = gql`
  mutation signUp(
    $userName: String
    $city: String
    $state: String
    $country: String
    $role: UserRole
    $about: String
    $picture: String
    $labelId: Int
    $email: EmailAddress!
    $acceptUsage: Boolean
  ) {
    signUp(
      userName: $userName
      email: $email
      city: $city
      state: $state
      country: $country
      role: $role
      about: $about
      picture: $picture
      labelId: $labelId
      acceptUsage: $acceptUsage
    ) {
      id
      userName
      picture
    }
  }
`;

class CreateNewArtist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  render() {
    const { labelId: currentLabelId } = this.props;

    return (
      <div style={{ marginTop: "0px" }} className="register-container">
        <div className="container">
          <TopBar bold={true} title="Create New" step="Artist" />
          <span>{}</span>

          <Mutation
            mutation={CREATE_ARTIST}
            refetchQueries={[{ query: CURRENT_USER }]}
            awaitRefetchQueries={true}
            onCompleted={data => {
              this.props.history.push("/dashboard/artists");
            }}
          >
            {(addUser, { loading, error, data }) => {
              return (
                <div className="register-form__container">
                  <Formik
                    initialValues={{
                      labelName: "",
                      labelAbout: "",
                      labelPicture: "",
                      locationCountry: "United States",
                      locationState: "",
                      locationCity: "",
                      acceptUsage: false,
                      baseImg: "",
                      email: ""
                    }}
                    validationSchema={Yup.object().shape({
                      labelName: Yup.string()
                        .required("Required")
                        .test("no various", "invalid name", val => {
                          if (val.toLowerCase().includes("various")) {
                            return false;
                          } else if (val.toLowerCase().includes("v/a")) {
                            return false;
                          } else {
                            return true;
                          }
                        }),
                      email: Yup.string()
                        .email("Invalid email address")
                        .required("Required"),
                      locationCountry: Yup.string().required("Required"),
                      locationState: Yup.string().required("Required"),
                      locationCity: Yup.string().required("Required"),
                      picture: Yup.string().required("required"),

                      labelAbout: Yup.string().required("Required")
                    })}
                    onSubmit={values => {
                      let variables = {
                        labelId: parseInt(currentLabelId),
                        userName: values.labelName,
                        email: values.email,
                        city: values.locationCity,
                        state: values.locationState,
                        country: values.locationCountry,
                        role: "Artist",
                        about: values.labelAbout,
                        picture: values.baseImg,
                        acceptUsage: values.acceptUsage
                      };
                      addUser({ variables });
                    }}
                    render={({
                      isSubmitting,
                      handleReset,
                      values,
                      errors,
                      setFieldValue,
                      submitCount
                    }) => (
                      <Form className="register-form">
                        <div className="row">
                          <div className="col">
                            <div className="form-group row">
                              <Fieldset
                                name="labelName"
                                type="text"
                                label="Artist Name"
                                fieldClass="form-control"
                                labelClass="col-sm-3 col-form-label"
                                fieldContainerClass="col-sm-9"
                              />
                            </div>
                            <div className="form-group row">
                              <Fieldset
                                name="email"
                                type="email"
                                label="Email"
                                fieldClass="form-control"
                                labelClass="col-sm-3 col-form-label"
                                fieldContainerClass="col-sm-9"
                              />
                            </div>

                            <div className="form-group row">
                              <Fieldset
                                name="locationCountry"
                                label="Country"
                                fieldClass="form-control"
                                labelClass="col-sm-3 col-form-label"
                                fieldContainerClass="col-sm-9"
                                component="select"
                              >
                                {countries.map((country, i) => (
                                  <option key={i} value={country.name}>
                                    {country.name}
                                  </option>
                                ))}
                              </Fieldset>
                            </div>

                            {/* suggest state if US is selected otherwise show bare input     */}
                            {values.locationCountry === "United States" ? (
                              <div className="form-group row">
                                <Fieldset
                                  name="locationState"
                                  label="State / Region"
                                  fieldClass="form-control"
                                  labelClass="col-sm-3 col-form-label"
                                  fieldContainerClass="col-sm-9"
                                  component="select"
                                >
                                  <option key={99} value={null}>
                                    Select State
                                  </option>
                                  {states.map((state, i) => (
                                    <option key={i} value={state.name}>
                                      {state.name}
                                    </option>
                                  ))}
                                </Fieldset>
                              </div>
                            ) : (
                              <div className="form-group row">
                                <Fieldset
                                  name="locationState"
                                  type="text"
                                  label="State / Region"
                                  fieldClass="form-control"
                                  labelClass="col-sm-3 col-form-label"
                                  fieldContainerClass="col-sm-9"
                                />
                              </div>
                            )}

                            <div className="form-group row">
                              <Fieldset
                                name="locationCity"
                                type="text"
                                label="City"
                                fieldClass="form-control"
                                labelClass="col-sm-3 col-form-label"
                                fieldContainerClass="col-sm-9"
                              />
                            </div>
                          </div>
                          {/* form left coll end */}
                          <div className="col">
                            <div className="form-group row">
                              <div className="col-sm-6">
                                <p>Artist Photo:</p>
                                <br />
                                <br />
                                <span className="upload-info">
                                  JPG or PNG;
                                  <br />
                                  at least 512x512;
                                  <br />
                                  max. 5 MB
                                </span>
                                <hr />

                                <div className="row">
                                  <div className="col-sm-12">
                                    <label className="" htmlFor="imageUseAgree">
                                      Agree for the van homepage use{" "}
                                      <button
                                        onClick={() =>
                                          this.setState({ showModal: true })
                                        }
                                        style={{
                                          display: "inline",
                                          padding: "0 5px"
                                        }}
                                        className="btn-bare"
                                      >
                                        [?]
                                      </button>
                                    </label>
                                  </div>
                                  <div className="col-sm-12">
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                        marginRight: "15px"
                                      }}
                                    >
                                      approve
                                    </span>
                                    <Field
                                      name="acceptUsage"
                                      component="input"
                                      type="checkbox"
                                      checked={values.acceptUsage}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <Uploader
                                  copy="Upload Photo"
                                  onGetBaseString={baseString => {
                                    setFieldValue("baseImg", baseString);
                                    setFieldValue("picture", "ok");
                                  }}
                                />
                                {errors.picture && submitCount > 0 ? (
                                  <p style={{ color: "red", float: "right" }}>
                                    {errors.picture}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {/* form right coll end */}
                        </div>
                        {/* top row end */}

                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group row">
                              <Fieldset
                                name="labelAbout"
                                component="textarea"
                                type="text"
                                label="Bio:"
                                fieldClass="form-control"
                                labelClass="col-sm-1 col-form-label"
                                fieldContainerClass="col"
                                rows="4"
                              />
                            </div>

                            <div className="form-group row justify-content-end">
                              <div className="col-sm-2">
                                {loading ? (
                                  <LoadingBtn />
                                ) : (
                                  <button
                                    disabled={loading}
                                    className="btn-black float-right"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col text-left">
                            {error ? (
                              <p
                                className="text-right"
                                style={{ color: "red" }}
                              >
                                {error.graphQLErrors.map(e => (
                                  <span> {e.message} </span>
                                ))}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  />
                </div>
              );
            }}
          </Mutation>
        </div>

        {this.state.showModal && (
          <Modal
            header="Agree for the van homepage use"
            onHide={() => this.setState({ showModal: false })}
            ok={"X"}
          >
            <p>
              approve this photo for use on platform homepage. see{" "}
              <Link
                style={{ color: "#000", textDecoration: "underline" }}
                target="_blank"
                to="/us/terms"
              >
                {" "}
                T&amp;Cs{" "}
              </Link>{" "}
              for info on photo approval rights
            </p>
          </Modal>
        )}
      </div>
    );
  }
}

export default CreateNewArtist;
