import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import AdminSidebar from "./AdminSidebar";
import AdminAppPage from "./AdminAppPage";
import UsersPageContainer from "./users/UsersPageContainer";
import AdminAccount from "./adminAccount/AdminAccount";
import Statistics from "./statistics/Statistics";
import TransactionReports from "./transactions/TransactionReports";

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="">
        <div className="row">
          <div className="col-sm-2">
            <AdminSidebar history={this.props.history} />
          </div>

          <div className="col-sm-10">
            <Switch>
              <Route
                path="/admin/statistics"
                render={props => (
                  <div>
                    <br />
                    <h1>statistics</h1>
                    <hr />
                    <br />
                    <br />
                    <Statistics />
                  </div>
                )}
              />

              <Route path="/admin/account" component={AdminAccount} />

              <Route path="/admin/users" component={UsersPageContainer} />

              <Route path="/admin/transaction-report" component={TransactionReports} />

              <Route component={AdminAppPage} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminDashboard;
