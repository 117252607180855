import React from "react";
import DateRow from "../DateRow";

import NewTracksModal from "./NewTracksModal";
import NewTracksTrackList from "./NewTracksTrackList";
import NewTracksDraggableList from "./NewTracksDragableList";

import NewTracksContext from "./newTracksContext";
import SaveNewTracks from "./SaveNewTracks";

class NewTracksPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tracks: this.props.tracks || [],
      showModal: false,
      wasCahnged: false,
      warning: false,
      filterPreReleased: true
    };
  }

  _toggleWarning = () => {
    // show track already on the list
    this.setState({ warning: !this.state.warning });
    setTimeout(() => {
      this.setState({ warning: !this.state.warning });
    }, 2000);
  };

  onAddTracks = track => {
    if (this.state.tracks.includes(track)) {
      this._toggleWarning();
      return;
    }
    this.setState(state => ({
      tracks: [...state.tracks, track],
      wasCahnged: true
    }));
  };

  onRemoveTrack = trackId => {
    const tracksList = this.state.tracks.filter(t => t !== trackId);
    this.setState({ tracks: tracksList, wasCahnged: true });
  };

  onUpdateOrder = tracks => {
    this.setState({ tracks: tracks, wasCahnged: true });
  };

  onSavedChange = () => {
    this.setState({ wasCahnged: false });
  };

  render() {
    const { wasCahnged, warning } = this.state;

    return (
      <NewTracksContext.Provider
        value={{
          tracks: this.state.tracks,
          onRemoveTrack: this.onRemoveTrack,
          onAddTrack: this.onAddTracks,
          onUpdateOrder: this.onUpdateOrder
        }}
      >
        <div className="row 4x4wrapper">
          <div className="col-sm-12">
            <DateRow {...this.props} />
            <div className="row">
              <div
                style={{ backgroundColor: warning ? "#bbffbb17" : "" }}
                className="col-sm-6 fxf-left"
              >
                <div className="row fxfColection-heading sticky">
                  <p>
                    New Tracks feed
                    <span style={{ fontSize: "14px", marginLeft: "20px" }}>
                      ({this.state.tracks.length} items in collection)
                    </span>
                  </p>
                  <SaveNewTracks
                    tracks={this.state.tracks}
                    showSave={wasCahnged}
                    onSavedChange={this.onSavedChange}
                    isListCreated={this.props.isListCreated}
                  />
                  {warning && (
                    <span className="badge  badge-warning">
                      Already on the list
                    </span>
                  )}
                </div>
                <div className="fxfColection-list">
                  <NewTracksDraggableList />
                </div>
              </div>
              <div
                style={{ maxHeight: "77vh", overflowY: "scroll" }}
                className="col-sm-6"
              >
                <div className="row fxfColection-heading sticky">
                  <p>Eligible tracks:</p>
                  <div className="newTracksFilter">
                    <button
                      onClick={() =>
                        this.setState({ filterPreReleased: false })
                      }
                      className={`btn ${
                        !this.state.filterPreReleased ? "btn-black" : ""
                      }`}
                    >
                      All
                    </button>

                    <button
                      onClick={() => this.setState({ filterPreReleased: true })}
                      className={`btn ${
                        this.state.filterPreReleased ? "btn-black" : ""
                      }`}
                    >
                      Pre-released
                    </button>
                  </div>
                </div>
                <NewTracksTrackList
                  filterPreReleased={this.state.filterPreReleased}
                  {...this.props}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.showModal && (
          <NewTracksModal
            releases={this.state.tracks}
            onCacnel={e => this.setState({ showModal: false })}
          />
        )}
      </NewTracksContext.Provider>
    );
  }
}

export default NewTracksPage;
