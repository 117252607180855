import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { Fieldset } from "../shared/formFields";

import { getBase64 } from "../../utils/utils";

import states from "../../data/states";
import countries from "../../data/countries";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { LoadingBtn } from "../shared/Loading";
import GET_ARTIST from "./labelArtistQuery";

const UPDATE_LABEL = gql`
  mutation labelEditArtist(
    $artistId: ID!
    $userName: String
    $city: String
    $state: String
    $country: String
    $about: String
    $picture: String
    $acceptUsage: Boolean
  ) {
    labelEditArtist(
      artistId: $artistId
      artist: {
        userName: $userName
        city: $city
        state: $state
        country: $country
        about: $about
        picture: $picture
        acceptUsage: $acceptUsage
      }
    ) {
      id
      token
      userName
      city
      state
      country
      about
      picture
      acceptUsage
    }
  }
`;

class EditArtist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formImg: "",
      baseImg: "",
      imageSize: "",
      wasImageChanged: false
    };
  }

  onUploadChange = e => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5000000) {
        this.setState({ imageSize: "Too large" });
        return;
      } else {
        this.setState({ imageSize: "" });
      }

      getBase64(file).then(result => {
        this.setState(() => ({
          formImg: URL.createObjectURL(file),
          baseImg: result,
          wasImageChanged: true
        }));
      });
    } else {
    }
  };

  render() {
    const {
      id,
      about,
      city,
      state,
      picture,
      country,
      userName,
      acceptUsage
    } = this.props;

    return (
      <Mutation
        mutation={UPDATE_LABEL}
        refetchQueries={[{ query: GET_ARTIST, variables: { id: id } }]}
      >
        {(labelEditArtist, { loading, error }) => (
          <div className="register-form__container">
            <Formik
              initialValues={{
                labelName: userName,
                labelAbout: about,
                labelPicture: "",
                locationCountry: country,
                locationState: state,
                locationCity: city,
                acceptUsage: acceptUsage
              }}
              validationSchema={Yup.object().shape({
                labelName: Yup.string().required("Required"),
                locationCountry: Yup.string().required("Required"),
                locationState: Yup.string().required("Required"),
                locationCity: Yup.string().required("Required"),
                labelAbout: Yup.string().required("Required")
              })}
              onSubmit={values => {
                let editPayload = {
                  artistId: id,
                  userName: values.labelName,
                  city: values.locationCity,
                  state: values.locationState,
                  country: values.locationCountry,
                  about: values.labelAbout,
                  acceptUsage: values.acceptUsage
                };

                if (this.state.wasImageChanged) {
                  editPayload.picture = this.state.baseImg;
                }
                labelEditArtist({
                  variables: editPayload
                });
              }}
              render={({ values, errors, setFieldValue }) => (
                <Form className="register-form">
                  <div className="row">
                    <div className="col">
                      <div className="form-group row">
                        <Fieldset
                          name="labelName"
                          type="text"
                          label="Label Name"
                          fieldClass="form-control"
                          labelClass="col-sm-3 col-form-label"
                          fieldContainerClass="col-sm-9"
                        />
                      </div>

                      <div className="form-group row">
                        <Fieldset
                          name="locationCountry"
                          label="Country"
                          fieldClass="form-control"
                          labelClass="col-sm-3 col-form-label"
                          fieldContainerClass="col-sm-9"
                          component="select"
                        >
                          {countries.map((country, i) => (
                            <option key={i} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </Fieldset>
                      </div>

                      {/* suggest state if US is selected otherwise show bare input     */}
                      {values.locationCountry === "United States" ? (
                        <div className="form-group row">
                          <Fieldset
                            name="locationState"
                            label="State"
                            fieldClass="form-control"
                            labelClass="col-sm-3 col-form-label"
                            fieldContainerClass="col-sm-9"
                            component="select"
                          >
                            {states.map((state, i) => (
                              <option key={i} value={state.name}>
                                {state.name}
                              </option>
                            ))}
                          </Fieldset>
                        </div>
                      ) : (
                        <div className="form-group row">
                          <Fieldset
                            name="locationState"
                            type="text"
                            label="State"
                            fieldClass="form-control"
                            labelClass="col-sm-3 col-form-label"
                            fieldContainerClass="col-sm-9"
                          />
                        </div>
                      )}

                      <div className="form-group row">
                        <Fieldset
                          name="locationCity"
                          type="text"
                          label="City"
                          fieldClass="form-control"
                          labelClass="col-sm-3 col-form-label"
                          fieldContainerClass="col-sm-9"
                        />
                      </div>
                    </div>
                    {/* form left coll end */}
                    <div className="col">
                      <div className="form-group row">
                        <div className="col-sm-6">
                          <p>Logo:</p>
                          <br />
                          <br />
                          <span className="upload-info">
                            JPG or PNG;
                            <br />
                            at least 512x512px;
                            <br />
                            max. 5 Mb
                          </span>
                          <hr />

                          <div className="row">
                            <div className="col-sm-8">
                              <label className="" htmlFor="imageUseAgree">
                                Agree for the van homepage use.
                              </label>
                            </div>
                            <div className="col-sm-4">
                              <Field
                                name="acceptUsage"
                                component="input"
                                type="checkbox"
                                checked={values.acceptUsage}
                                onChange={e =>
                                  setFieldValue("acceptUsage", e.target.checked)
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="upload-area float-right">
                            <input
                              type="file"
                              onChange={e => this.onUploadChange(e)}
                            />

                            {this.state.imageSize.length > 0 ? (
                              <span className="input-error">File to large</span>
                            ) : (
                              ""
                            )}

                            {this.state.formImg.length === 0 ? (
                              picture ? (
                                <img
                                  src={
                                    this.state.formImg.length > 1
                                      ? this.state.formImg
                                      : picture
                                  }
                                  alt=""
                                />
                              ) : (
                                <p>Upload cover image</p>
                              )
                            ) : (
                              <img
                                src={
                                  this.state.formImg.length > 1
                                    ? this.state.formImg
                                    : picture
                                }
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* form right coll end */}
                  </div>
                  {/* top row end */}

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group row">
                        <Fieldset
                          name="labelAbout"
                          component="textarea"
                          type="text"
                          label="About:"
                          fieldClass="form-control"
                          labelClass="col-sm-1 col-form-label"
                          fieldContainerClass="col"
                          rows="4"
                        />
                      </div>

                      <div className="form-group row justify-content-between">
                        <div className="col d-flex justify-content-end">
                          {loading ? (
                            <LoadingBtn />
                          ) : (
                            <button
                              disabled={
                                Object.keys(errors).length ||
                                this.state.imageSize.length
                              }
                              className="btn-black float-right"
                              type="submit"
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        )}
      </Mutation>
    );
  }
}

export default EditArtist;
