import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import GET_RELEASE from "../queries/getReleaseById";

import { LoadingBtn } from "../shared/Loading";
import TextError from "../shared/Errors";

const DELETE_TRACK = gql`
  mutation deleteTrackMutation($trackId: ID) {
    deleteTrack(trackId: $trackId)
  }
`;

const RemoveTrack = props => {
  const { title, id, onHideModal, onDeleteTrack, releaseId } = props;
  return (
    <div style={{ width: "100%" }} className="reset-pass__container">
      <div className="reset-pass__body">
        <h3>Delete Track?</h3>
        <hr />

        <div className="reset-pass__form">
          <div className="form-group row">
            <div className="col">
              <p>{title}</p>
            </div>
          </div>

          <div className="btn-row">
            <Mutation
              mutation={DELETE_TRACK}
              refetchQueries={[
                {
                  query: GET_RELEASE,
                  variables: { releaseId: parseInt(releaseId) }
                }
              ]}
              onCompleted={data => {
                if (data.deleteTrack) {
                  onDeleteTrack(data.deleteTrack);
                  onHideModal();
                }
              }}
            >
              {(deleteTrack, { loading, error }) => {
                const variables = { trackId: parseInt(id) };
                return (
                  <>
                    {loading ? (
                      <LoadingBtn />
                    ) : (
                      <button
                        onClick={e => deleteTrack({ variables })}
                        type="button"
                        className="btn btn-danger"
                      >
                        Remove
                      </button>
                    )}
                    <br />
                    <button
                      type="button"
                      onClick={e => onHideModal()}
                      className="btn-bare"
                    >
                      Cancel
                    </button>
                    {error && (
                      <span style={{}}>
                        <TextError error={error} />
                      </span>
                    )}
                  </>
                );
              }}
            </Mutation>
          </div>
        </div>
      </div>
    </div>
  );
};

RemoveTrack.propTypes = {
  releaseId: PropTypes.any.isRequired,
  id: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  onHideModal: PropTypes.func.isRequired,
  onDeleteTrack: PropTypes.func.isRequired
};

export default RemoveTrack;
