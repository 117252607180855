import React from "react";
import Uploader from "../../shared/Uploader";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { LoadingBtn } from "../../shared/Loading";

const UPDATE_HOMEPAGE = gql`
  mutation createEditHomePage(
    $credits: String
    $labelColor: LabelColor!
    $picture: String
    $weekStart: String!
  ) {
    createEditHomePage(
      homepage: {
        credits: $credits
        labelColor: $labelColor
        picture: $picture
        weekStart: $weekStart
      }
    ) {
      id
    }
  }
`;

const HomeEditForm = ({ picture, credits, labelColor, showGallery }) => {
  const noSizeValidate = true;
  const [homeImage, setHomeImage] = React.useState("unchanged");
  const [homeLabelColor, setHomeLabelColor] = React.useState(
    labelColor === "Black_on_White" ? false : true
  );
  const [homeCredits, setHomeCredits] = React.useState(credits);
  const [showSucces, toggleShowSucces] = React.useState(false);

  //   React.useEffect(() => {
  //     setHomeImage(picture);
  //   }, []);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-6">
          <br />
          <h4>Homepage Photo</h4>
          <br />
          <p />
          <p>JPG or PNG; at least 512x512px; max 10MB</p>
          <button onClick={() => showGallery()} className="btn-bare">
            <p className="font-weight-bold"> Artist Photos Library</p>
          </button>

          <p>Photo Credit</p>

          <form action="">
            <input
              onChange={e => setHomeCredits(e.target.value)}
              name="credits"
              className=" form-control"
              type="text"
              value={homeCredits}
            />

            <br />
            <hr />

            <br />
            <p>Homepage nav button color</p>

            <div className="form-check">
              <input
                onChange={e => setHomeLabelColor(!homeLabelColor)}
                className="form-check-input"
                type="radio"
                name="labelColor"
                id="labelColor1"
                value="black"
                checked={!homeLabelColor}
              />

              <label className="form-check-label" htmlFor="labelColor1">
                Black on white
              </label>
            </div>
            <div className="form-check">
              <input
                onChange={e => setHomeLabelColor(!homeLabelColor)}
                className="form-check-input"
                type="radio"
                name="labelColor"
                id="labelColor2"
                value="white"
                checked={homeLabelColor}
              />
              <label className="form-check-label" htmlFor="labelColor2">
                White on black
              </label>
            </div>
          </form>
        </div>

        <div
          style={{ paddingTop: "40px" }}
          className="col-sm-4 d-flex justify-content-center align-content-center"
        >
          <br />
          <Uploader
            image={picture}
            nosizeValidation={noSizeValidate}
            onGetBaseString={img => setHomeImage(img)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-9 d-flex justify-content-end">
          <Mutation
            mutation={UPDATE_HOMEPAGE}
            onCompleted={data => {
              toggleShowSucces(true);
              setTimeout(() => toggleShowSucces(false), 2000);
            }}
          >
            {(createEditHomePage, { loading, error, data }) => {
              if (loading) return <LoadingBtn />;

              let editPayload = {
                credits: homeCredits,
                labelColor: homeLabelColor
                  ? "White_on_Black"
                  : "Black_on_White",
                weekStart: "2019-04-15"
              };

              if (homeImage !== "unchanged") {
                editPayload = { ...editPayload, picture: homeImage };
              }

              return (
                <>
                  <button
                    className="btn-black"
                    onClick={() =>
                      createEditHomePage({
                        variables: { ...editPayload }
                      })
                    }
                  >
                    Save
                  </button>
                  <br />
                  {error && (
                    <p style={{ color: "red", display: "block" }}>
                      something went wrong
                    </p>
                  )}
                  {showSucces && (
                    <span
                      style={{ position: "absolute", bottom: "-30px" }}
                      className="badge badge-success"
                    >
                      saved
                    </span>
                  )}
                </>
              );
            }}
          </Mutation>
          {/* <button
            onClick={() => {
            }}
            className="btn-black"
          >
            SAVE
          </button> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeEditForm;
