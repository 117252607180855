import React, { Component } from "react";

import AppContext from "../context/app-context";

import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";
import queryString from "query-string";

import ReleaseDetailsForm from "./ReleaseDetailsForm";
import { Spinner, Elipsis } from "../shared/Loading";
import TopBar from "../shared/TopBar";

import RELEASES from "../queries/releasesList";
import GET_ARTIST from "../editArtist/labelArtistQuery";
import { withRouter } from "react-router-dom";

const QUERY_ARTISTS = gql`
  {
    me {
      id
      userName
      role
      artist {
        id
        userName
      }
    }
  }
`;

const CREATE_RELEASE = gql`
  mutation createRelease(
    $title: String!
    $publicationDate: DateTime!
    $labelId: ID
    $artistId: ID!
    $type: AlbumType
    $cover: Upload
    $tracks: [TrackInput]
    $territories: [String]
    $price: Float
  ) {
    createRelease(
      release: {
        title: $title
        publicationDate: $publicationDate
        artistId: $artistId
        labelId: $labelId
        type: $type
        cover: $cover
        tracks: $tracks
        territories: $territories
        price: $price
      }
    ) {
      id
      title
      publicationDate
      type
      cover
      artist {
        id
      }
    }
  }
`;

class AddReleaseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      releaseCreated: false,
      showModal: false
    };
  }

  static contextType = AppContext;

  handleOnExitWarning = () => {
    if (!this.state.releaseCreated) {
      this.setState({ showCancelWarning: true });
    } else {
      this.props.history.goBack();
    }
  };

  handleMutationCompleated = data => {
    if (!data.createRelease) {
    } else {
      setTimeout(() => {
        this.context.setReleaseReady(
          data.createRelease.title,
          data.createRelease.id
        );
      }, 2000);
      localStorage.setItem('firstTimeAfterRelease', "true");
      this.props.history.push(`/dashboard/release/${data.createRelease.id}`)
    }
  };

  render() {
    const { location } = this.props;

    const selectedArtistId = queryString.parse(location.search).artistId;
    const selectedArtistName = queryString.parse(location.search).artistName;

    return (
      <Query query={QUERY_ARTISTS}>
        {({ loading: queryLoading, error: queryError, data: queryData }) => {
          if (queryLoading) return <Spinner />;
          if (queryError) return `Error! ${queryError.message}`;

          return (
            <Mutation
              mutation={CREATE_RELEASE}
              onCompleted={data => this.handleMutationCompleated(data)}
              refetchQueries={result => {
                return [
                  { query: RELEASES },
                  {
                    query: GET_ARTIST,
                    variables: { id: result.data.createRelease.artist.id }
                  }
                ];
              }}
            >
              {(createRelease, { loading, error, data }) => {
                if (error) {
                  // this.context.hideReleaseLoader();
                  return "something went wrong, please try again";
                } //TODO: Uploader error handling
                return (
                  <div className="container">
                    <TopBar
                      history={this.props.history}
                      title={!loading ? "Add Release" : ""}
                      step={!loading && `Details`}
                      onCancel={() => this.handleOnExitWarning()}
                    />

                    {loading ? (
                      <div className="row d-flex justify-content-center">
                        <div className="mt-5 text-center">
                          <h3>creating release…</h3>

                          <Elipsis />
                          <button
                            onClick={e => this.props.history.push("/dashboard")}
                            className="btn-black"
                          >
                            go to Inventory
                          </button>
                        </div>
                      </div>
                    ) : (
                      <ReleaseDetailsForm
                        loading={loading}
                        {...queryData.me}
                        selectedArtistId={selectedArtistId}
                        selectedArtistName={selectedArtistName}
                        onSubmit={submitedData => {
                          createRelease({ variables: submitedData });
                          this.setState({ releaseCreated: true });
                          this.context.onAddReleaseToList({
                            title: submitedData.title,
                            id: "",
                            ready: false
                          });
                        }}
                      />
                    )}

                    {this.state.showCancelWarning && (
                      <div className="reset-pass__container">
                        <div
                          className="reset-pass__body"
                          style={{ width: "400px" }}
                        >
                          <form>
                            <p>
                              You'll lose all data entered. Are you sure you
                              want to leave?
                            </p>

                            <div className="btn-row">
                              <button
                                style={{ paddingLeft: 0 }}
                                type="button"
                                onClick={e =>
                                  this.setState({ showCancelWarning: false })
                                }
                                className="btn-bare"
                              >
                                Decline
                              </button>
                              <button
                                type="submit"
                                className="btn-black"
                                onClick={() =>
                                  this.props.history.push("/dashboard")
                                }
                              >
                                Cancel Upload
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default withRouter(AddReleaseDetails);
