import React from "react";
import { NavLink, Link } from "react-router-dom";

import { Query } from "react-apollo";
import { Spinner } from "../shared/Loading";
import CURRENT_USER_QUERY from "../queries/me";

const SettingsTopBar = () => {
  return (
    <div className="dashTopBar-container">
      <div className="dash-topbar">
        <Query query={CURRENT_USER_QUERY}>
          {({ loading, error, data }) => {
            if (loading) return <Spinner />;
            if (error) return error.message;
            return (
              <div className="dash-topbar__left">
                <NavLink
                  activeClassName="active"
                  exact
                  to="/dashboard/settings/details"
                  className="topDash-navlink small"
                >
                  Details
                </NavLink>

                {/* <NavLink activeClassName='active'
                  to="/dashboard/settings/permisions"
                  className="topDash-navlink small"
                >
                  Permissions
                </NavLink> */}

                {data.me.role !== 'Label' &&
                  <NavLink
                    activeClassName="active"
                    to="/dashboard/settings/finance"
                    className="topDash-navlink small"
                  >
                    Finances
                  </NavLink>
                }

                <NavLink
                  activeClassName="active"
                  to="/dashboard/settings/account"
                  className="topDash-navlink small"
                >
                  Account
                </NavLink>
              </div>
            );
          }}
        </Query>

        <div className="dash-topbar__right">
          <Link to="/dashboard/" className="btn-ico float-right">
            <img
              style={{ maxWidth: "20px" }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAQAAACQ9RH5AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQfjAhMRGQDijAjCAAABbklEQVRYw8XZzU7CQBSG4TeuWqlXRLk/rN4RoIF4EaZ6D9SlbMaNIeGn7fycLwdWDOn3cIZ25qQFWLFjoKejQvmq6egZ2NICrDgR/t97GhnbsD87J1p4O38MBA4iuuFw4Wzh52JAQ1+zgSN8XQ0FPngyZR+vZjUQ+ISXm0Hbqm+rDQTWUN/9wopuRtKrsamwmfDZZA0dlWpPRyfa0klpdnRykg2dlVJOZyeU0UVH5x9cPF95ASZnSHqI2TWRFmS6CsSHma97cYGSlX4+VLa3TQfrttTJcCkL432EsneZrFpabQotYGNoETtHC9kpOpl9UP5Kfb2O/7DjOe14FQtopyXTaZNw2hadGgGn1sep2Vv4tLcL3hODTOh01oTOY4vpfLaILmOz6XI2i7Zhk2k7Nom2ZaNpezaK1rCztM9t4xpehew43cG3ZJLnJryHQVjteNVH2MnZe/TG54HXL0uAlg0DPWvxI76KZ3oGNizhD8GYaL65kSzbAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTAyLTE5VDE2OjI1OjAwKzAxOjAwwWj1KAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0wMi0xOVQxNjoyNTowMCswMTowMLA1TZQAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC"
              alt=""
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SettingsTopBar;
