import React from "react";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { LoadingBtn } from "../../shared/Loading";
import GET_NEW_RELEASES from "../newReleases/GET_NEW_RELEASES";

const GET_ADMIN_NOTES = gql`
  query getAdminNotes($releaseId: ID!) {
    getRelease(releaseId: $releaseId) {
      id
      title
      cover
      additionalAdminInfo
    }
  }
`;

const EDIT_ADMIN_NOTES = gql`
  mutation editAdminNotes($releaseId: ID!, $additionalAdminInfo: String) {
    editRelease(
      releaseId: $releaseId
      release: { additionalAdminInfo: $additionalAdminInfo }
    ) {
      id
      additionalAdminInfo
    }
  }
`;

const EditAdminNotes = ({ notes, releaseId, updateReleases }) => {
  const [adminNotes, setAdminNotes] = React.useState(notes);

  return (
    <div className="row">
      <div className="col-sm-12">
        <textarea
          style={{ minHeight: "100px", width: "100%" }}
          onChange={e => setAdminNotes(e.target.value)}
          value={adminNotes ? adminNotes : ""}
        />
      </div>
      <div className="col-sm-12">
        <Mutation
          variables={{ releaseId: releaseId, additionalAdminInfo: adminNotes }}
          refetchQueries={[{ query: GET_NEW_RELEASES }]}
          mutation={EDIT_ADMIN_NOTES}
          update={(cache, { data: { editRelease } }) => {
            // const { getNewReleases } = cache.readQuery({
            //   query: GET_NEW_RELEASES
            // });
            updateReleases(editRelease.id, editRelease.additionalAdminInfo);
          }}
        >
          {(editRelease, { data, loading, error }) => {
            if (loading) return <LoadingBtn />;
            if (error) return `${error.message}`;
            return (
              <button onClick={editRelease} className="btn-black">
                Save
              </button>
            );
          }}
        </Mutation>
      </div>
    </div>
  );
};

const AdminReleaseNotes = ({ releaseId, updateReleases }) => {
  return (
    <Query query={GET_ADMIN_NOTES} variables={{ releaseId: releaseId }}>
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        const { id, title, cover, additionalAdminInfo } = data.getRelease;
        return (
          <div className="row">
            <div className="col-sm-6">
              <img style={{ width: "120px" }} src={cover} alt="" />
              <p>
                <span>Title: </span> {title}
              </p>
            </div>
            <div className="col-sm-6">
              <p className="text-bold">
                <span>Id: </span> {id}
              </p>
            </div>
            <div className="col-sm-12">
              <hr />
              <p>Admin Notes</p>
              <EditAdminNotes
                releaseId={releaseId}
                notes={additionalAdminInfo}
                additionalAdminInfo={additionalAdminInfo}
                updateReleases={updateReleases}
              />
            </div>
          </div>
        );
      }}
    </Query>
  );
};

export default AdminReleaseNotes;
