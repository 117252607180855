import React from "react";

import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { LoadingBtn } from "../../shared/Loading";

// const CREATE_4x4_COLLECTION = gql`
//   mutation create4x4($date: String!, $content: [ID]!) {
//     create4x4(fourByFour: { date: $date, content: $content }) {
//       id
//       releases {
//         id
//         title
//         cover
//         type
//         publicationDate
//         artist {
//           id
//           userName
//         }
//       }
//     }
//   }
// `;
const SAVE_4x4 = gql`
  mutation edit4x4($date: String!, $content: [ID]!) {
    edit4x4(fourByFour: { date: $date, content: $content }) {
      id
      releases {
        id
        title
        cover
        type
        publicationDate
        artist {
          id
          userName
        }
      }
    }
  }
`;

const Save4x4 = props => {
  const { showSave, releases, onSavedChange, isCollectionCreated } = props;
  const savePayLoad = releases.map(t => t.id);
  return (
    <Mutation
      mutation={
        SAVE_4x4
        // isCollectionCreated ? SAVE_4x4 : CREATE_4x4_COLLECTION
      }
      onCompleted={data => {
        onSavedChange();
      }}
    >
      {(edit4x4, { loading, error, data }) => {
        if (error) {
          console.log(error);
        }
        return (
          <>
            {loading ? (
              <LoadingBtn />
            ) : (
              showSave && (
                <button
                  onClick={() =>
                    edit4x4({
                      variables: {
                        date: "2019-04-15T00:00:00.000Z",
                        content: [...savePayLoad]
                      }
                    })
                  }
                  className="btn-black"
                >
                  {isCollectionCreated ? "SAVE" : "SAVE NEW"}
                </button>
              )
            )}
            {error && <span>Error :( </span>}
          </>
        );
      }}
    </Mutation>
  );
};

export default Save4x4;
