import React from "react";
import { NavLink } from "react-router-dom";

const TransactionNav = () => {
  return (
    <div className="row adminApp-topBar">
      <div className="col-sm-12 no-gutters">
        <div className="adminApp-nav">
          <NavLink
            to="/admin/transaction-report/customer"
            activeClassName="active"
            className="adminApp-link"
          >
            Customer Report
          </NavLink>
          {/* <NavLink
            to="/admin/transaction-report/bank-transfer"
            activeClassName="active"
            className="adminApp-link"
          >
            Bank Transfer
          </NavLink> */}
        </div>
      </div>
    </div>
  );
};

export default TransactionNav;
