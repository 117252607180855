import React, { Component } from 'react';
import LoginUser from './LoginUser';
import ResetPassword from './ResetPasswordDialog';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReset: false
     }
  }

  showReset = () => {
    this.setState({showReset: !this.state.showReset})
  }
  render() {
    const {showReset} = this.state
    return (
    <>
    <LoginUser {...this.props} showReset={this.showReset}/>

    {
      showReset && <ResetPassword onCancel={e => this.setState({showReset: !showReset})} />
    }
    </>
    );
  }
}

export default Login;
