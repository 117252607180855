import React from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { LoadingBtn } from "../../shared/Loading";
import GET_PENDING_USERS from "./getPenndingUsersQuery";

const DELETE_USER = gql`
  mutation adminDeleteUser($userId: ID!) {
    adminDeleteUser(userId: $userId)
  }
`;

const AdminDeleteUser = props => {
  return (
    <div>
      <Mutation
        mutation={DELETE_USER}
        variables={{ userId: props.id }}
        onCompleted={data => {
          if (data.adminDeleteUser) {
            props.history.replace("/admin/users");
          }
        }}
        refetchQueries={[{ query: GET_PENDING_USERS }]}
      >
        {(deleteUser, { loading, error }) => {
          if (loading) return <LoadingBtn />;
          return (
            <button onClick={e => deleteUser()} className="btn-red">
              Delete User
            </button>
          );
        }}
      </Mutation>
    </div>
  );
};

export default AdminDeleteUser;
