import React from "react";
import { LoadingBtn } from "../shared/Loading";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { Fieldset } from "../shared/formFields";

const LoginForm = props => {
  const { handleSubmit, error, loading } = props;

  return (
    <Formik
      initialValues={{
        email: "",
        password: ""
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("invalid email address")
          .required("email required"),
        password: Yup.string().required("password required")
      })}
      onSubmit={values => {
        handleSubmit(values);
      }}
      render={({ setFieldValue, setErrors }) => (
        <Form style={{ width: "100%" }}>
          <div className="login-form__container">
            {error && (
              <p style={{ color: "red" }}>
                {error.graphQLErrors.map(e => (
                  <span> {e.message} </span>
                ))}
              </p>
            )}

            <div className="form-group row">
              <Fieldset
                name="email"
                type="text"
                label="email"
                fieldClass="form-control"
                labelClass="col-sm-3 col-form-label login"
                fieldContainerClass="col-sm-9"
              />
            </div>
            <div className="form-group row">
              <Fieldset
                name="password"
                type="password"
                label="password"
                fieldClass="form-control"
                labelClass="col-sm-3 col-form-label login"
                fieldContainerClass="col-sm-9"
              />
            </div>
            <div className="row">
              <div className="col-sm-6">
                <button
                  type="button"
                  onClick={e => props.showReset()}
                  style={{ paddingLeft: 0 }}
                  className="btn-bare"
                >
                  forgot?
                </button>
              </div>

              <div className="col-sm-6">
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <button
                    disabled={loading}
                    className="btn btn-primary submit-full"
                    type="submit"
                  >
                    log in
                  </button>
                )}
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
};

LoginForm.propTypes = {
  loading: PropTypes.any.isRequired,
  error: PropTypes.any.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

LoginForm.defaultProps = {
  error: false
};

export default LoginForm;
