import React, { Component } from "react";
import { DebounceInput } from "react-debounce-input";
import img from "../../images/artistCover.jpg";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { LoadingBtn } from "../shared/Loading";
import TextError from "../shared/Errors";

const SEND_ARTIST_REQUEST = gql`
  mutation addLabelArtist($artistId: ID) {
    addLabelArtist(artistId: $artistId)
  }
`;

const Result = props => (
  <div className="row no-gutters resultRow d-flex align-items-stretch">
    <div className="col d-flex align-items-center">
      <img
        src={props.picture || img}
        alt=""
        className="result-img float-left"
      />

      <div>
        {" "}
        <h4> {props.userName}</h4>
        <span>{`${props.city}, ${props.state}`}</span>
      </div>
    </div>
    <div className="col d-flex align-items-center justify-content-end">
      <button onClick={props.onSelectArtist} className="btn-bare float-right">
        Add Artist >{" "}
      </button>
    </div>
  </div>
);

class SearchExistingArtist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      searchResults: [],
      selectedArtistId: "",
      selectedArtistName: "",
      selectedArtistLocation: "",
      selectedArtistState: "",
      selectedArtistEmail: "",
      showAddConfirmation: false,
      user: ""
    };
  }

  handleSearch = e => {
    const value = e.target.value;
    this.setState({ search: value });

    this.filterdResults(value);
  };

  handleConfirmationModal = values => {
    this.setState(state => ({ showAddConfirmation: true, user: values }));
  };

  closeModal = () => this.setState({ showAddConfirmation: false });

  filterdResults = keyword => {
    const res = this.props.artists;

    const filtered = res.filter(artist => {
      if (artist.userName === null) {
        return false;
      }
      return artist.userName.toLowerCase().includes(keyword.toLowerCase());
    });

    this.setState({ searchResults: filtered });
  };

  render() {
    const {
      searchResults,
      user: { userName, id, email, city }
    } = this.state;

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <br />
            <br />
            <DebounceInput
              className="form-control"
              minLength={2}
              debounceTimeout={300}
              onChange={this.handleSearch}
              placeholder="Search for existing artist"
            />
          </div>
          <br />
          <h5>Results for: {this.state.search}</h5>
          <hr />
        </div>

        <div className="col-sm-12 resultContainer">
          {this.state.search.length > 1 &&
            searchResults.map((el, i) => (
              <Result
                onSelectArtist={e => this.handleConfirmationModal(el)}
                {...el}
                key={i}
              />
            ))}
        </div>

        <div className="col-sm-12">
          <hr />
        </div>

        {this.state.showAddConfirmation && (
          <div className="reset-pass__container">
            <div className="reset-pass__body">
              <h3>Send Request to:</h3>
              <hr />

              <div className="reset-pass__form">
                <div className="form-group row">
                  <div className="col">
                    <h4>{userName}</h4>
                    <p>{city}</p>

                    <p>{email}</p>
                  </div>
                </div>

                <Mutation
                  mutation={SEND_ARTIST_REQUEST}
                  onCompleted={data => {
                    if (data.addLabelArtist) {
                      this.setState({ showAddConfirmation: false });
                    }
                  }}
                >
                  {(addLabelArtist, { loading, error }) => {
                    if (error) {
                    }

                    return (
                      <>
                        <div className="btn-row">
                          <button
                            onClick={this.closeModal}
                            className="btn-bare"
                          >
                            Cancel
                          </button>

                          {loading ? (
                            <LoadingBtn />
                          ) : (
                            <button
                              onClick={e =>
                                addLabelArtist({ variables: { artistId: id } })
                              }
                              className="btn-black"
                            >
                              Send
                            </button>
                          )}
                        </div>
                        {error ? (
                          <div className="row">
                            <TextError error={error} />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  }}
                </Mutation>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SearchExistingArtist;
