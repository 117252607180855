import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";

import { Fieldset } from "../../shared/formFields";
import { Mutation } from "react-apollo";
import CURRENT_USER_QUERY from "../../queries/me";
import { LoadingBtn } from "../../shared/Loading";

const EDIT_EMAIL = gql`
  mutation EditEmailMutation($email: EmailAddress) {
    editUser(email: $email) {
      id
      email
    }
  }
`;

const validate = {
  email: Yup.string()
    .email("Invalid email address")
    .required("Required")
};

class EditEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmation: false
    };
  }

  handleSuccessMsg = () => {
    this.setState({ showConfirmation: true });

    setTimeout(() => {
      this.setState({ showConfirmation: false });
    }, 3000);
  };

  render() {
    return (
      <Mutation
        mutation={EDIT_EMAIL}
        onCompleted={data => {
          this.handleSuccessMsg();
        }}
        refetchQueries={[{ query: CURRENT_USER_QUERY }]}
      >
        {(editEmail, { loading, error }) => (
          <Formik
            initialValues={{
              email: this.props.email
            }}
            validationSchema={Yup.object().shape({ validate })}
            onSubmit={values => {
              editEmail({
                variables: {
                  email: values.email
                }
              });
            }}
          >
            {({ isSubmitting, handleReset, values, errors }) => (
              <Form className="register-form">
                <div className="row">
                  <div className="col-sm-12">
                    <br />
                    <p>Edit email</p>
                  </div>
                  <Fieldset
                    name="email"
                    type="email"
                    fieldClass="form-control"
                    fieldContainerClass="col-sm-9"
                  />
                  <div className="col-sm-3 d-flex flex-start">
                    {loading ? (
                      <LoadingBtn />
                    ) : (
                      <button type="submit" className="btn-black">
                        Save
                      </button>
                    )}
                  </div>
                  <div className="col-sm-12">
                    <br />
                    {this.state.showConfirmation && (
                      <h6>
                        {" "}
                        <span className="badge badge-success">
                          Email updated
                        </span>
                      </h6>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Mutation>
    );
  }
}

export default EditEmail;
