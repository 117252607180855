import React, { Component } from "react";
import TopBar from "../shared/TopBar";
import { Route } from "react-router-dom";


import AddReleaseCredits from "./AddReleaseCredits";
import EditTrackDetails from "./EditTrackDetails";
import Summary from "./Summary";
import EditReleaseDetails from "./EditReleaseDetails";


class ReleaseContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	render() {
		return (
			<>
				<TopBar history={this.props.history} title="Release" step="" />


				<Route
					exact
					path="/dashboard/release/:releaseId"
					component={Summary}
				/>


				<Route
					exatc
					path="/dashboard/release/:releaseId/details"
					component={EditReleaseDetails}
				/>

				<Route
					exact
					path="/dashboard/release/:releaseId/credits"
					component={AddReleaseCredits}
				/>

				<Route
					path="/dashboard/release/:releaseId/track/:trackId"
					component={EditTrackDetails}
				/>

			</>

		);
	}
}

export default ReleaseContainer;
