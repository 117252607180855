import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Plus } from "../../shared/SvgIcons";
import coverImage from "../../../images/artistCover.jpg";
import Territories from "../newReleases/TerritoriesList";

const ReleaseItem = props => {
  const {
    id,
    artist,
    title,
    cover,
    type,
    publicationDate,
    territories
  } = props;

  const addTo4x4 = () => {
    const release = {
      id,
      artist,
      title,
      cover,
      type,
      publicationDate,
      territories
    };
    props.addTo4x4(release);
  };

  return (
    <div className={`row fxfItem`}>
      <div className="col-sm-10 d-flex">
        <div
          style={{
            backgroundImage: `url("${cover || coverImage}")`,
            marginRight: "10px"
          }}
          className="fxfItem-cover"
        />
        <div style={{ flexGrow: 2 }}>
          <p className="fxfItem-title">{title.substring(0, 20)}</p>
          <p className="fxfItem-release">
            {artist ? artist.userName : "#error"}
          </p>
          <p className="fxfItem-artist">
            {moment(publicationDate)
              .utc()
              .format("M.D.YYYY")}{" "}
            <span className="fxfItem-type">{type}</span>{" "}
          </p>
          <Territories territories={territories} />
        </div>
      </div>
      <div className="col-sm-2 icons-col">
        <div className="fxfItem-icons float-right">
          <button onClick={e => addTo4x4()} className="fxfIem-btn release">
            <Plus icoClass="fxfIem-ico" />
          </button>
        </div>
      </div>
    </div>
  );
};

ReleaseItem.propTypes = {
  title: PropTypes.string,
  cover: PropTypes.string,
  artist: PropTypes.object,
  type: PropTypes.string
};

ReleaseItem.defaultProps = {
  title: "Title",
  cover: coverImage,
  artist: { userName: "ArtistName" },
  type: "Album"
};

export default ReleaseItem;
