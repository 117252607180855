import React, { Component } from "react";
import { Route, Link, NavLink } from "react-router-dom";
import logo from "../../images/logo.png";
import PleaseVerifyPage from "./PleaseVerifyPage";
import Login from "./Login";
import { getUrlParameter } from "../../utils/utils";

const HomeTop = () => {
  return (
    <>
      <div className="login-logo__wrapper">
        <img src={logo} alt="The van Logo" className="login-logo" />
      </div>
      <div className="log-reg__bar">
        <NavLink
          exact
          className="btn login-btn"
          activeClassName="active"
          to="/"
        >
          log in
        </NavLink>

        <NavLink
          exact
          className="btn login-btn"
          activeClassName="active"
          to="/register"
        >
          register
        </NavLink>
      </div>
    </>
  );
};

const RegisterBtns = () => {
  return (
    <div className="account-select">
      <Link to="/register/artist" className="btn account-btn">
        artist account
      </Link>
      <Link to="/register/label" className="btn account-btn">
        label account
      </Link>
    </div>
  );
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const isConfirmation = getUrlParameter("status") === "success";

    return (
      <div className="login-wrapper">
        {isConfirmation && (
          <p style={{ color: "green", position: "absolute", top: "10px" }}>
            email confirmed
          </p>
        )}
        <div className="loggin-container">
          <HomeTop />
          <Route exact path="/" component={Login} />
          <Route exact path="/register" component={RegisterBtns} />
          <Route exact path="/verify" component={PleaseVerifyPage} />
        </div>
      </div>
    );
  }
}

export default Home;
