import React, { Component } from "react";
import PropTypes from "prop-types";

import queryString from "query-string";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "../shared/SvgIcons";

class DateRow extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const path = this.props.location.pathname;
    const dateFromProps = queryString.parse(this.props.location.search).date;

    const date = () =>
      dateFromProps ? moment(dateFromProps) : moment(new Date().toISOString());

    const displayFormat = "MMMM DD, YYYY";
    const urlFormat = "YYYY-MM-DD";

    // const formatedPubDate = moment(publicationDate, "YYYY-MM-DD").format(
    //   "MMMM Do, YYYY"
    // );

    return (
      <div className="row fxf-dateRow">
        <NavLink
          to={`${path}?date=${date()
            .subtract(1, "day")
            .format(urlFormat)}`}
          className="btn-date"
        >
          <ChevronLeft />{" "}
          {date()
            .subtract(1, "day")
            .format(displayFormat)}
        </NavLink>
        <NavLink
          to={`${path}?date=${date().format(urlFormat)}`}
          className="btn-date active"
        >
          {date().format(displayFormat)}
        </NavLink>
        <NavLink
          to={`${path}?date=${date()
            .add(1, "day")
            .format(urlFormat)}`}
          className="btn-date"
        >
          {date()
            .add(1, "day")
            .format(displayFormat)}{" "}
          <ChevronRight />
        </NavLink>
      </div>
    );
  }
}

DateRow.propTypes = {
  location: PropTypes.any.isRequired
};

export default DateRow;
