import React from "react";
import PropTypes from "prop-types";
import SidebarDetails from "../dashboardSidebar/SidebarDetails";
import SidebarLinks from "./SidebarLinks";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import LogOutBtn from "../login/LogOutBtn";

import AppContext from "../context/app-context";
import { LoadingBtn } from "../shared/Loading";

const USER_DETAILS = gql`
  query userDetailsQuerry {
    me {
      id
      userName
      city
      state
      about
      picture
      verified
      role
      frontendId
    }
  }
`;

const Sidebar = props => {
  return (
    <Query query={USER_DETAILS}>
      {({ data, loading, error }) => {
        if (loading) return "...";
        if (error) return <p>{error}</p>;
        const { verified } = data.me;
        return (
          <AppContext.Consumer>
            {client => {
              return (
                <aside className="dash-sidebar">
                  <div className="sidebar-sticky">
                    <SidebarDetails {...data.me} />
                    <div className="loading-status">
                      {client.isReleaseCreating && <LoadingBtn />}
                    </div>
                    <div className="sidebar-bottom">
                      {verified ? (
                        <SidebarLinks history={props.history} />
                      ) : (
                        <LogOutBtn history={props.history} />
                      )}
                    </div>
                  </div>
                </aside>
              );
            }}
          </AppContext.Consumer>
        );
      }}
    </Query>
  );
};

Sidebar.propTypes = {
  history: PropTypes.any.isRequired
};

export default Sidebar;
