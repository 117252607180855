import { gql } from "apollo-boost";

export default gql`
  query getUnApprovedUsers {
    getUnApprovedUsers {
      id
      userName
      city
      state
      country
      role
      about
      picture
      verified
      adminApproval
      frontendId
    }
  }
`;
