import React, { Component } from "react";

import CreatableSelect from "react-select/lib/Creatable";

export default class TagInput extends Component {
  handleChange = (newValue, actionMeta) => {
    this.props.onTagChange(newValue);
  };
  render() {
    return (
      <CreatableSelect
        options={this.props.options}
        defaultValue={this.props.options}
        isMulti
        onChange={this.handleChange}
      />
    );
  }
}
