import React from "react";
// import _sortBy from "lodash/sortBy";
import queryString from "query-string";

import { Query } from "react-apollo";
import GET_PENDING_USERS from "./getPenndingUsersQuery";

class PendinngUsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "id",
      order: false
    };
  }

  handleFilter = title => {
    const { sort, order } = this.state;

    if (title === sort) {
      this.setState({ order: !order });
    } else {
      this.setState({ sort: title, order: false });
    }
  };

  getSortedList = users => {
    const { sort, order } = this.state;
    let sortedUsers;

    switch (sort) {
      case "id":
        sortedUsers = users.sort((a, b) =>
          order ? (a.id < b.id ? -1 : 1) : b.id > a.id ? 1 : -1
        );
        break;
      case "userName":
        sortedUsers = users.sort((a, b) => {
          return order
            ? a.userName.toLowerCase() > b.userName.toLowerCase()
              ? -1
              : 1
            : a.userName.toLowerCase() > b.userName.toLowerCase()
            ? 1
            : -1;
        });
        break;
      case "role":
        sortedUsers = users.sort((a, b) =>
          order
            ? a.role.toLowerCase() > b.role.toLowerCase()
              ? -1
              : 1
            : a.role.toLowerCase() > b.role.toLowerCase()
            ? 1
            : -1
        );
        break;

      default:
        sortedUsers = users;
        break;
    }

    return sortedUsers;
  };

  render() {
    const { props } = this;
    const activeId = queryString.parse(this.props.location.search).user;

    return (
      <Query query={GET_PENDING_USERS}>
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const usersArr = data.getUnApprovedUsers
            .filter(u => u.userName !== null)
            .map(user => ({
              ...user,
              id: parseInt(user.id)
            }));

          return (
            <>
              <div
                style={{
                  maxHeight: "70vh",
                  overflow: "auto"
                }}
                className="scroll-tab"
              >
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col" className="stickyTableHeader">
                        <button
                          onClick={e => this.handleFilter("id")}
                          className="btn-bare"
                        >
                          id
                        </button>
                      </th>
                      <th scope="col" className="stickyTableHeader">
                        <button
                          onClick={e => this.handleFilter("userName")}
                          className="btn-bare"
                        >
                          Name
                        </button>
                      </th>
                      <th scope="col" className="stickyTableHeader">
                        <button
                          onClick={e => this.handleFilter("role")}
                          className="btn-bare"
                        >
                          Type
                        </button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.getSortedList(usersArr).map(user => {
                      const {
                        id,
                        frontendId,
                        userName,
                        role,
                        adminApproval
                      } = user;
                      return (
                        <tr
                          style={{
                            cursor: "pointer",
                            border: `${
                              id === activeId ? "2px dotted #000" : "none"
                            }`
                          }}
                          className={`${
                            adminApproval ? "approved" : "pennding"
                          }`}
                          key={id}
                          onClick={e =>
                            props.history.replace("/admin/users/new?user=" + id)
                          }
                        >
                          <th scope="row">
                            {id} #{frontendId}
                          </th>
                          <td>{userName}</td>
                          <td>{role}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          );
        }}
      </Query>
    );
  }
}

export default PendinngUsersList;
