import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Mutation } from "react-apollo";

import gql from "graphql-tag";
import CURRENT_USER_QUERY from "../queries/me";
import TextError from "../shared/Errors";
import { LoadingBtn } from "../shared/Loading";

import RELEASES from "../queries/releasesList";

const DELETE_ARTIST = gql`
  mutation labelDeleteArtist($artistId: ID!) {
    labelDeleteArtist(artistId: $artistId)
  }
`;

const Arrow = () => (
  <span>
    <svg
      style={{
        overflow: " hidden",
        width: "20px",
        height: "20px",
        fill: "#fff"
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 477.175 477.175"
    >
      <path d="M360.73 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z" />
    </svg>
  </span>
);

class ArtistBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  render() {
    const { showModal } = this.state;
    const { userName, picture, id, status, publicationDate, labelName } = this.props;

    const style = {
      backgroundImage: `url('${picture}')`
    };

    return (
      <>
        <div className="artist-box__container">
          <div style={style} className="artist-box__inner">
            <Link
              className="artist-option"
              to={`/dashboard/addrelease?artistId=${id}&artistName=${userName}`}
            >
              Add Release
              <Arrow />
            </Link>

            <Link className="artist-option" to={`/dashboard/artist/${id}`}>
              Edit
              <Arrow />
            </Link>

            <button
              className="artist-option"
              onClick={e => this.setState({ showModal: !showModal })}
            >
              <p>Delete</p>
            </button>
          </div>

          <h4>{userName}</h4>
          <p>{publicationDate}</p>
          <span>{status}</span>
        </div>

        {showModal && (
          <div className="reset-pass__container">
            <div className="reset-pass__body">
              <p>
                are you sure you want to delete{" "}
                <span style={{ fontWeight: "bold" }}> {userName} </span>
                ? Any prior releases will remain co-managed by
                <span style={{ fontWeight: "bold" }}> {labelName} </span>
                  &
                <span style={{ fontWeight: "bold" }}> {userName} </span>
                  — with provider’s share of purchase revenue for these releases remitted to
                <span style={{ fontWeight: "bold" }}> {labelName} </span>
                  . But
                <span style={{ fontWeight: "bold" }}> {labelName} </span>
                    will not be able to create any future releases to co-manage with
                    <span style={{ fontWeight: "bold" }}> {userName} </span>.
              </p>

              <div className="reset-pass__form">
                <div className="form-group row">
                  <div className="col">
                    <p style={{ fontSize: "14px" }}></p>
                  </div>
                </div>

                <div className="btn-row">
                  <button
                    type="button"
                    onClick={e => this.setState({ showModal: !showModal })}
                    className="btn-bare"
                  >
                    Decline
                  </button>
                  <Mutation
                    mutation={DELETE_ARTIST}
                    refetchQueries={[
                      { query: CURRENT_USER_QUERY },
                      { query: RELEASES }
                    ]}
                    onCompleted={data => {
                      this.setState({ showModal: !showModal });
                    }}
                  >
                    {(deleteUser, { loading, error, data }) => {
                      const variables = { artistId: id };
                      return (
                        <>
                          {loading ? (
                            <LoadingBtn />
                          ) : (
                            <button
                              onClick={e => deleteUser({ variables })}
                              type="button"
                              className="btn btn-danger"
                            >
                              Delete Page
                            </button>
                          )}

                          {error && (
                            <span style={{ position: "absolute", bottom: 0 }}>
                              <TextError error={error} />
                            </span>
                          )}
                        </>
                      );
                    }}
                  </Mutation>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ArtistBox;
