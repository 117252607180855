import React from "react";
import PropTypes from "prop-types";

import NewTracksContext from "./newTracksContext";

import { Plus, Play } from "../../shared/SvgIcons";
import coverImage from "../../../images/artistCover.jpg";
import Territories from "../newReleases/TerritoriesList";
import moment from "moment";

class TrackItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = NewTracksContext;

  audio = React.createRef();

  playAudio = trackUrl => {
    const song = this.audio.current;
    song.src = trackUrl;
    song.oncanplay = () => {
      song.play();
    };
  };

  pauseAudio = () => {
    this.audio.current.pause();
  };

  render() {
    const {
      id,
      artist,
      title,
      cover,
      releaseTitle,
      trackUrl,
      territories,
      publicationDate
    } = this.props;

    return (
      <div className={`row fxfItem fxfItem-playback`}>
        <audio ref={this.audio} />
        <div className="col-sm-3">
          <div
            onMouseEnter={e => this.playAudio(trackUrl)}
            onMouseLeave={e => this.pauseAudio()}
            style={{ backgroundImage: `url("${cover || coverImage}")` }}
            className={`fxfItem-cover fxfItem-cover__playback`}
          >
            <Play />
          </div>
        </div>

        <div className="col-sm-6">
          <p className="fxfItem-title">{title.substring(0, 17)}</p>
          <p className="fxfItem-release">{releaseTitle}</p>
          <p className="fxfItem-artist">{artist.userName}</p>
          <p
            style={{ fontSize: "8px", color: "#ccc" }}
            className="fxfItem-artist"
          >
            {moment(new Date(publicationDate))
              .utc()
              .format("MM.DD.YYYY")}
          </p>
          <Territories territories={territories} />
        </div>
        <div className="col-sm-3 icons-col">
          <div className="fxfItem-icons float-right">
            <button
              onClick={e => this.context.onAddTrack(id)}
              className="fxfIem-btn release"
            >
              <Plus icoClass="fxfIem-ico" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

TrackItem.propTypes = {
  title: PropTypes.string,
  cover: PropTypes.string,
  artist: PropTypes.string,
  releaseTitle: PropTypes.string
};

TrackItem.defaultProps = {
  title: "Title",
  cover: coverImage,
  artist: "ArtistName",
  type: "Album",
  releaseTitle: "release title"
};
export default TrackItem;
