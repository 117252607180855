import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_IMAGES = gql`
  query {
    getUsers(role: Artist) {
      id
      acceptUsage
      picture
    }
  }
`;

const ImageItem = ({ src }) => {
  const [size, setSize] = React.useState({ h: 0, w: 0 });

  const image = React.useCallback(node => {
    if (node !== null) {
      setTimeout(() => {
        setSize({ h: node.naturalHeight, w: node.naturalWidth });
      }, 1000);
    }
  }, []);

  return (
    <div className="imageContainer">
      <img src={src} ref={image} alt="" />
      <span>
        Size {size.w} x {size.h}
      </span>
      <a className="btn-black" href={src}>
        download
      </a>
    </div>
  );
};

const CoverGallery = props => {
  return (
    <div clasname="vanModal-wrapper">
      <div className="vanModal-backdrop">
        <div className="vanModal-container">
          <div style={{ width: "90vw" }} className="vanModal">
            <div className="vanModal-header">
              <h5>Gallery</h5>
              <hr />
            </div>
            <div
              style={{
                height: "40vw",
                overflow: "scroll",
                padding: "40px 0",
                marginBottom: "60px"
              }}
              className="vanModal-body"
            >
              <div className="imagesGallery">
                <Query query={GET_IMAGES}>
                  {({ loading, error, data }) => {
                    if (loading) return "Loading...";
                    if (error) return `Error! ${error.message}`;
                    const images = data.getUsers
                      .filter(user => user.acceptUsage && user.picture)
                      .map(u => u.picture);
                    return images.map((i, index) => (
                      <ImageItem key={index} src={i} />
                    ));
                  }}
                </Query>
              </div>
            </div>
            <div className="vanModal-footer">
              <button className="btn-black" onClick={props.onHide}>
                {props.ok || "close"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverGallery;
