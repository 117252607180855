import React, { Component } from "react";
import { ApolloConsumer } from "react-apollo";

import gql from "graphql-tag";

const RESET_PASSWORD = gql`
  query ResetPassword($email: EmailAddress!) {
    resetPassword(email: $email)
  }
`;
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: "",
      error: null
    };
  }

  onChange = e => {
    this.setState({ email: e.target.value });
  };

  handleReset = data => {
    this.setState({ message: `reset email sent to ${this.state.email}` });
  };

  handleError = error => this.setState({ error, email: "" });

  render() {
    const { onCancel } = this.props;

    return (
      <ApolloConsumer>
        {client => (
          <div className="reset-pass__container">
            <div style={{padding: "20px"}} className="reset-pass__body">
              <span
                style={{ color: "red", textAlign: "center", margin: "10px" }}
              >
                {this.state.error && this.state.error}
              </span>
              <div className="reset-pass__form">
                {this.state.message.length ? (
                  <div>
                    <p>{this.state.message}</p>
                    <br />
                    <button
                      onClick={e => onCancel(e)}
                      className="btn-bare font-weight-bolder"

                    >
                      close
                    </button>
                  </div>
                ) : (
                  <form
                    onSubmit={async e => {
                      e.preventDefault();
                      try {
                        const { data } = await client.query({
                          query: RESET_PASSWORD,
                          variables: { email: this.state.email },
                          fetchPolicy: "network-only"
                        });

                        this.handleReset(data);
                      } catch (e) {
                        if (e.graphQLErrors) {
                          this.handleError(e.graphQLErrors[0].message);
                        }
                      }
                    }}
                  >
                    <div className="form-group row">
                      <label
                        htmlFor="staticEmail"
                        className="col-sm-3"
                      >
                        email
                      </label>
                      <div className="col-sm-9">
                        <input
                          required
                          onChange={this.onChange}
                          value={this.state.email}
                          type="email"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="btn-row">
                      <button   style={{paddingLeft: 0}} type="button" onClick={e => onCancel(e)} className="btn-bare">
                        cancel
                      </button>
                      <button type="submit" className="btn-black">
                        reset password
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

export default ResetPassword;
