import React, { useState } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import queryString from "query-string";
import moment from "moment";

const GET_DELETED_USERS = gql`
  query {
    getUsersWithDeleted {
      id
      firstName
      lastName
      userName
      email
      zip
      city
      state
      country
      supportTier
      supportTotal
      role
      token
      acceptTerms
      acceptUsage
      about
      picture
      verified
      adminApproval
      links
      createdAt
      updatedAt
      deletedAt
      blacklist
      frontendId
    }
  }
`;
const DeletedUsers = props => {
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState(false);
  const activeId = queryString.parse(props.location.search).user;

  const handleFilter = filter => {
    if (filter === sort) {
      setOrder(!order);
    } else {
      setSort(filter);
      setOrder(false);
    }
  };

  const getSortedList = users => {
    const usersData = users.map(u => ({
      ...u,
      userName: u.userName.toLowerCase()
    }));
    return usersData.sort((a, b) =>
      order
        ? a[`${sort}`] < b[`${sort}`]
          ? -1
          : 1
        : b[`${sort}`] > a[`${sort}`]
        ? 1
        : -1
    );
  };

  return (
    <Query query={GET_DELETED_USERS}>
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;

        const usersArr = data.getUsersWithDeleted
          .filter(user => user.userName)
          .filter(user => user.deletedAt)
          .map(user => ({
            ...user,
            userName: user.userName.toLowerCase(),
            id: parseInt(user.id)
          }));

        return (
          <>
            <div
              style={{ maxHeight: "70vh", overflowY: "auto", width: "100%" }}
              className="scroll-tab"
            >
              <table style={{ fontSize: "12px" }} className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">
                      <button
                        onClick={e => handleFilter("id")}
                        className="btn-bare"
                      >
                        id
                      </button>
                    </th>
                    <th scope="col">
                      <button
                        onClick={e => handleFilter("userName")}
                        className="btn-bare"
                      >
                        Name
                      </button>
                    </th>
                    <th scope="col">
                      <button
                        onClick={e => handleFilter("role")}
                        className="btn-bare"
                      >
                        Type
                      </button>
                    </th>
                    <th scope="col">
                      <button
                        onClick={e => handleFilter("adminApproval")}
                        className="btn-bare"
                      >
                        Status
                      </button>
                    </th>
                    <th className="col">Deleted</th>
                  </tr>
                </thead>
                <tbody>
                  {getSortedList(usersArr).map(user => {
                    const {
                      id,
                      userName,
                      role,
                      adminApproval,
                      deletedAt,
                      blacklist,
                      frontendId
                    } = user;
                    return (
                      <tr
                        style={{
                          cursor: "pointer",
                          border: `${
                            id === activeId ? "2px dotted #000" : "none"
                          }`
                        }}
                        className={`${adminApproval ? "approved" : "pennding"}`}
                        key={id}
                        onClick={e =>
                          props.history.replace(
                            "/admin/users/deleted?user=" + id
                          )
                        }
                      >
                        <th scope="row">
                          {id} #{frontendId}
                        </th>
                        <td>
                          <p style={{ margin: 0 }}>{userName}</p>
                          <p style={{ margin: 0, fontSize: "10px" }}>
                            deleted:{" "}
                            {moment(new Date(deletedAt)).format("YYYY-MM-DD")}
                          </p>
                        </td>
                        <td>{role}</td>
                        <td>{adminApproval ? "Approved" : "Pending"}</td>
                        <td>{blacklist ? "admin" : "user"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        );
      }}
    </Query>
  );
};

export default DeletedUsers;
