import React, { Component } from "react";
import { DebounceInput } from "react-debounce-input";

import FourByFourReleasesList from "./4x4releasesList";

class FourByFourSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFor: ""
    };
  }

  handleSearch = e => {
    const value = e.target.value;
    this.setState({ searchFor: value });
  };

  addTo4x4 = release => {
    this.props.addTo4x4(release);
  };

  render() {
    return (
      <>
        <div className="row fxfSearch">
          <div className="fxfSearch-inputContainer">
            <DebounceInput
              className="form-control"
              minLength={2}
              debounceTimeout={300}
              onChange={this.handleSearch}
              placeholder="Search for existing artist"
            />
          </div>

          <h5 className="fxfSearch-helper">
            Results for:{" "}
            {this.state.searchFor.length > 1 ? this.state.searchFor : "All"}
          </h5>
        </div>

        <div className="searchResults">
          <FourByFourReleasesList
            addTo4x4={this.addTo4x4}
            filter={this.state.searchFor}
            {...this.props}

          />
        </div>
      </>
    );
  }
}

export default FourByFourSearch;
