import React from "react";

import { Query } from "react-apollo";
import { Spinner } from "../shared/Loading";
import ReleaseArtistsTopBar from "./ReleasesArtistsTopBar";
import ReleaseBox from "./ReleaseBox";

import RELEASES from "../queries/releasesList";
import { ChevronLeft } from "../shared/SvgIcons";
import PayPalNotice from "./PaypalNotice";

class ReleasesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "date",
      order: false
    };
  }

  handleFilter = title => {
    const { sort, order } = this.state;

    if (title === sort) {
      this.setState({ order: !order });
    } else {
      this.setState({ sort: title, order: false });
    }
  };

  getSortedList = releases => {
    const { sort, order } = this.state;
    let sortedReleases;

    switch (sort) {
      case "date":
        sortedReleases = releases.sort((a, b) =>
          order
            ? a.publicationDate < b.publicationDate
              ? -1
              : 1
            : b.publicationDate > a.publicationDate
            ? 1
            : -1
        );
        break;
      case "title":
        sortedReleases = releases.sort((a, b) =>
          order
            ? a.title.toLowerCase() > b.title.toLowerCase()
              ? -1
              : 1
            : a.title.toLowerCase() > b.title.toLowerCase()
            ? 1
            : -1
        );
        break;

      default:
        sortedReleases = releases;
        break;
    }

    return sortedReleases;
  };

  render() {
    return (
      <Query query={RELEASES}>
        {({ data, error, loading, refetch }) => {
          if (loading) return <Spinner />;
          if (error) return "...";
          if (!data) {
            refetch();
          }
          const { release: releases } = data.me;
          const { sort, order } = this.state;
          return (
            <>
              <ReleaseArtistsTopBar role={this.props.role} {...this.props} />

              <div style={{ paddingLeft: "10px" }} className="filters-bars">
                <p>
                  sort:
                  <button
                    onClick={() => this.handleFilter("date")}
                    className={`filter-btn btn-bare ${
                      sort === "date" ? "active" : ""
                    } ${order && "desc"}`}
                  >
                    date <ChevronLeft />{" "}
                  </button>
                  <button
                    onClick={() => this.handleFilter("title")}
                    className={`filter-btn btn-bare ${
                      sort === "title" ? "active" : ""
                    } ${order && "desc"}`}
                  >
                    title <ChevronLeft />
                  </button>
                </p>
              </div>
              <div className="d-flex flex-wrap">
                {this.props.role === 'Artist' && <PayPalNotice />}

                {this.getSortedList(releases).map(r => (
                  <ReleaseBox key={r.id} {...r} />
                ))}
              </div>
            </>
          );
        }}
      </Query>
    );
  }
}

export default ReleasesList;
