import React, { Component } from "react";
import IndexReleaseItem from "./IndexReleaseItem";

import { dynamicSort } from "../../../utils/utils";
import DeletereleaseModal from "./DeleteReleaseModal";

class IndexReleasesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      releases: this.props.releases,
      searchFor: this.props.searchFor,
      sort: "publicationDate",
        dir:1,
      deleteRelease: {
        show: false,
        title: "",
        id: ""
      }
    };
  }

  onDeleteRelease = release => {
    this.setState({
      deleteRelease: {
        show: true,
        title: release.title,
        id: release.id
      }
    });
  };

  cancelDelete = (id = null) => {
    if (id) {
      const releases = this.state.releases.filter(r => r.id !== id);
      this.setState({
        releases: releases,
        deleteRelease: { show: false }
      });
    } else {
      this.setState({ deleteRelease: { show: false } });
    }
  };

  setSort = val => {
    let dir=!this.state.dir
    this.setState({ sort: val,dir });
  };

  render() {
    const { searchFor } = this.props;
    const { releases, sort,dir } = this.state;
    const { show } = this.state.deleteRelease;

    let releasesCollection = dir?releases.sort(dynamicSort(sort)):releases.sort(dynamicSort(sort)).reverse();

    if (searchFor.length > 1) {
      releasesCollection = releasesCollection.filter(
        r =>
          r.title.toLowerCase().includes(searchFor.toLowerCase()) ||
          r.artist.userName.toLowerCase().includes(searchFor.toLowerCase())
      );
    }

    return (
      <div className="col-sm-6 fxf-left">
        <div className="row">
          <div className="indexResults-col__heading">
            <p>Releases:</p>
            <div className="indexResults-filter">
              <p>
                Sort By:
                <button
                  onClick={e => this.setSort("publicationDate")}
                  className={`btn-bare ${sort === "publicationDate" &&
                    "active"}`}
                >
                  Date
                </button>
                <button
                  onClick={e => this.setSort("title")}
                  className={`btn-bare ${sort === "title" && "active"}`}
                >
                  Title
                </button>
              </p>
            </div>
          </div>
        </div>

        <div className="indexResults-coll">
          {releasesCollection.map(r => (
            <IndexReleaseItem
              key={r.id}
              {...r}
              onDelete={this.onDeleteRelease}
            />
          ))}
        </div>

        {show && (
          <DeletereleaseModal
            {...this.state.deleteRelease}
            onCancelDelete={this.cancelDelete}
          />
        )}
      </div>
    );
  }
}

export default IndexReleasesList;
