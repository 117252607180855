import React from "react";
import ReactDom from "react-dom";

const modalRoot = document.getElementById("modal-root");

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  el = document.createElement("div");
  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDom.createPortal(
      <div clasname="vanModal-wrapper">
        <div className="vanModal-backdrop">
          <div className="vanModal-container">
            <div className="vanModal">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
                className="vanModal-header"
              >
                <h5>{this.props.header}</h5>
                <button className="btn-black" onClick={this.props.onHide}>
                  {this.props.ok || "ok"}
                </button>
              </div>
              <hr />
              <div className="vanModal-body">{this.props.children}</div>
              <div className="vanModal-footer"></div>
            </div>
          </div>
        </div>
      </div>,

      this.el
    );
  }
}

export default Modal;
