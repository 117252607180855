import React, { Component } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

/* const content = {
  entityMap: {},
  blocks: [
    {
      key: "637gr",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ]
}; */

const why = {
  why:
    '{"blocks":[{"key":"637gr","text":"ugh.","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":4,"style":"fontsize-30"}],"entityRanges":[],"data":{}}],"entityMap":{}}'
};

class VanEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createWithContent(
        convertFromRaw(JSON.parse(this.props.val || why.why))
      )
    };
  }

  onEditorStateChange = editorState => {
    this.setState(
      state => ({
        editorState
      }),
      this.props.onChange(
        JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()))
      )
    );
  };

  raw = () => {
    const s = convertToRaw(this.state.editorState.getCurrentContent());
    this.props.onChange(s);
  };

  render() {
    const { editorState } = this.state;
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}

export default VanEditor;
