import React, { Component } from "react";
import TopBar from "../shared/TopBar";
import { Route, Link, Switch } from "react-router-dom";

import CreateNewArtist from "./CreateNewArtist";
import SearchExistingArtist from "./SearchExistingArtistContainer";

class CreateArtistContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route
          path="/dashboard/addartist/add-existing"
          render={() => {
            return (
              <div className="container">
                <TopBar history={this.props.history} title="Add Artist" />
                <SearchExistingArtist />

                <div className="col-sm-12 d-flex align-items-center justify-content-end">
                  <Link
                    to="/dashboard/addartist/add-new"
                    clasname="float-right"
                    className="btn-black"
                  >
                    add new artist
                  </Link>
                </div>
              </div>
            );
          }}
        />

        <Route
          exact
          path="/dashboard/addartist/add-new"
          render={props => {
            return (
              <CreateNewArtist
                labelId={this.props.labelId}
                labelName={this.props.name}
                {...props}
              />
            );
          }}
        />
      </Switch>
    );
  }
}

export default CreateArtistContainer;
