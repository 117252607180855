import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import moment from "moment";

import { Lines, Trash } from "../shared/SvgIcons";
import RemoveTrack from "./RemoveTrack";

import GET_RELEASE from "../queries/getReleaseById";
import EditTrackPrerelease from "./EditTrackPreRelease";

const EDIT_TRACK_ORDER = gql`
  mutation editTrackOrder($trackOrders: [trackOrderInput]) {
    editTrackOrder(trackOrders: $trackOrders) {
      id
    }
  }
`;

const getDate = date => moment(date, "YYYY-MM-DD").format("MM.DD.YYYY");

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class TrackList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tracks: null,
      date: this.props.date,
      showModal: false,
      trackId: "",
      trackTitle: ""
    };
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (true) {
  //     return {
  //       tracks: props.tracks
  //     };
  //   }
  //   return null;
  // }

  componentDidMount() {
    this.setState({ tracks: this.props.tracks });
  }

  showDeleteConfirmation = (id, title) => {
    this.setState({ trackId: id, showModal: true, trackTitle: title });
  };

  deleteTrackFromList = trackId => {
    const trackList = this.state.tracks.filter(track => trackId !== track.id);
    this.setState({ tracks: trackList });
  };

  onDragEnd = (result, cb) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const tracks = reorder(
      this.state.tracks,
      result.source.index,
      result.destination.index
    );

    this.setState(
      () => ({ tracks: tracks }),
      () => {
        cb();
      }
    );
  };

  updateTracks = newTracks => {
    this.setState({ tracks: newTracks });
  };

  render() {
    const { releaseId } = this.props;

    return (
      <>
        <Mutation
          mutation={EDIT_TRACK_ORDER}
          refetchQueries={[
            {
              query: GET_RELEASE,
              variables: { releaseId: this.props.releaseId }
            }
          ]}
        >
          {(trackOrder, { data }) => (
            <DragDropContext
              onDragEnd={result => {
                this.onDragEnd(result, () => {
                  trackOrder({
                    variables: {
                      trackOrders: this.state.tracks.map((track, i) => ({
                        trackId: track.id,
                        trackOrder: i + 1
                      }))
                    }
                  });
                });
              }}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={
                      snapshot.isDraggingOver
                        ? {
                            backgroundColor: "rgba(0, 0, 0, 0.021)",
                            padding: "10px"
                          }
                        : { padding: "10px 20px" }
                    }
                  >
                    {this.state.tracks &&
                      this.state.tracks.map((track, index) => (
                        <Draggable
                          key={track.id}
                          draggableId={track.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`row track-row ${
                                snapshot.isDragging ? "dragging" : ""
                              }`}
                            >
                              <div className="col-sm-4">
                                <span className="track-num">{index + 1}</span>
                                <p className="track-title">
                                  {track.title}
                                  {/* <span className="track-time">2:13</span> */}
                                </p>
                                <p className="track-id">ID: {track.id}</p>
                              </div>
                              <div
                                style={{ paddingTop: "10px", flexGrow: "2" }}
                                className="col-sm-8 d-flex justify-content-end align-items-center"
                              >
                                <span
                                  style={{
                                    fontSize: "10px",
                                    marginRight: "10px"
                                  }}
                                >
                                  {getDate(track.publicationDate)}
                                </span>
                                <EditTrackPrerelease
                                  id={track.id}
                                  preRelease={track.preRelease}
                                  releaseId={this.props.releaseId}
                                  updateTracks={this.updateTracks}
                                />

                                <Link
                                  to={`${this.props.match.url}/track/${track.id}`}
                                  style={{
                                    padding: "0 30px 0 50px",
                                    height: "25px"
                                  }}
                                  className="btn-bare"
                                >
                                  Edit
                                </Link>
                                <button
                                  onClick={e =>
                                    this.showDeleteConfirmation(
                                      track.id,
                                      track.title
                                    )
                                  }
                                  className="btn-bare"
                                >
                                  <Trash icoClass="tracklist-trash" />
                                </button>

                                <span>
                                  <Lines />
                                </span>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Mutation>

        {this.state.showModal && (
          <RemoveTrack
            releaseId={releaseId}
            id={this.state.trackId}
            title={this.state.trackTitle}
            onHideModal={e => this.setState({ showModal: false })}
            onDeleteTrack={trackId => this.deleteTrackFromList(trackId)}
          />
        )}
      </>
    );
  }
}

export default TrackList;
