import React from "react";

import { Query } from "react-apollo";
import GET_USER from "./getUserById";
import AdminEditUser from "./AdminEditUser";

const AdminEditUserConatiner = props => {
  const userId = props.match.params.userId;
  return (
    <Query query={GET_USER} variables={{ id: userId }}>
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return (
          <div>
            <AdminEditUser {...data.getUser} />
          </div>
        );
      }}
    </Query>
  );
};

export default AdminEditUserConatiner;
