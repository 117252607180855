import React from "react";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Query } from "react-apollo";
import GET_CUSTOMER_REPORT from "./getCustomerReportQuery";

class CustomerReportsList extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Query query={GET_CUSTOMER_REPORT}>
          {({ loading, error, data }) => {
            if (loading) return "Loading...";
            if (error) return `Error! ${error.message}`;

            const reportsArr = data.getCustomerReport;

            const headers = [
              { label: "PayPal Customer Account #", key: "merchantIdInPayPal" },
              { label: "PayPal Reference ID", key: "paypalOrderId" },
              { label: "Transaction Currency", key: "currencyCode" },
              { label: "Transaction Total", key: "buyerAmount" },
              { label: "TVIAC Share", key: "partnerFee" },
              { label: "Transaction Date", key: "transactionDate" },
              { label: "Artist Name", key: "artistName" },
              { label: "ID", key: "artistId" },
              { label: "Country", key: "country" },
              { label: "State", key: "state" },
              { label: "City", key: "city" },
              { label: "Label Name", key: "labelName" },
              { label: "Label ID", key: "labelId" },
              { label: "Customer ID", key: "customerId" },
              { label: "Zip Code", key: "zipCode" },
            ];

            const transactionsData = reportsArr.map(transaction => {
              const { id, merchantIdInPayPal, paypalOrderId, updatedAt, description, artist } = transaction;
              const paymentDetail = description && description.purchase_units && description.purchase_units.length && description.purchase_units[0].payments && description.purchase_units[0].payments.captures && description.purchase_units[0].payments.captures.length ? description.purchase_units[0].payments.captures[0] : null;

              return {
                id: id,
                merchantIdInPayPal: merchantIdInPayPal,
                paypalOrderId: paypalOrderId,
                currencyCode: paymentDetail && paymentDetail.amount && paymentDetail.amount.currency_code ? paymentDetail.amount.currency_code : '-',
                buyerAmount: paymentDetail && paymentDetail.amount && paymentDetail.amount.value ? paymentDetail.amount.value : '-',
                partnerFee: paymentDetail && paymentDetail.seller_receivable_breakdown && paymentDetail.seller_receivable_breakdown.platform_fees && paymentDetail.seller_receivable_breakdown.platform_fees.length ? paymentDetail.seller_receivable_breakdown.platform_fees[0].amount.value : 0,
                transactionDate: moment(updatedAt).format('YYYY-MM-DD hh:mm A'),
                artistName: artist ? artist.userName : '-',
                artistId: artist ? '#' + artist.frontendId : '-',
                country: artist && artist.country ? artist.country : '-',
                state: artist && artist.state ? artist.state : '-',
                city: artist && artist.city ? artist.city : '-',
                labelName: artist && artist.label && artist.label.label ? artist.label.label.userName : '-',
                labelId: artist && artist.label && artist.label.label ? artist.label.label.id : '-',
                customerId: artist && artist.paypal && artist.paypal.merchantId ? artist.paypal.merchantId : '-',
                zipCode: artist && artist.zip ? artist.zip : '-'
              }
            });

            const csvreport = {
              data: transactionsData,
              headers: headers,
              filename: 'Customer_Transaction_Report.csv'
            };

            return (
              <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', marginRight: '30px', width: '100%' }}>
                  <CSVLink className="btn-black" {...csvreport}>Export to CSV</CSVLink>
                </div>
                <div
                  style={{
                    maxHeight: "70vh",
                    overflow: "auto",
                    marginRight: 30,
                  }}
                  className="scroll-tab"
                >
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        {headers.map(x => <th key={x.key} scope="col" className="stickyTableHeader">{x.label}</th>)}
                      </tr>
                    </thead>
                    <tbody>
                      {transactionsData.map(transaction => {
                        const { id, merchantIdInPayPal, paypalOrderId, currencyCode, buyerAmount, partnerFee, transactionDate, artistName, artistId, country, state, city, labelName, labelId, customerId, zipCode } = transaction;
                        return (
                          <tr key={id} style={{ cursor: "pointer", border: "none" }}>
                            <th scope="row">{merchantIdInPayPal}</th>
                            <td>{paypalOrderId}</td>
                            <td>{currencyCode}</td>
                            <td>{buyerAmount}</td>
                            <td>{partnerFee}</td>
                            <td>{transactionDate}</td>
                            <td>{artistName}</td>
                            <td>{artistId}</td>
                            <td>{country}</td>
                            <td>{state}</td>
                            <td>{city}</td>
                            <td>{labelName}</td>
                            <td>{labelId}</td>
                            <td>{customerId}</td>
                            <td>{zipCode}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

export default CustomerReportsList;
