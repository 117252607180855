import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";

import { Fieldset } from "../../shared/formFields";
import { Mutation } from "react-apollo";

import Error from "../../shared/Errors.jsx";

const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      id
    }
  }
`;

const validate = {
  newPassword: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum."),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Password confirm is required")
};

class EditPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmation: false
    };
  }

  handleConfirmation = () => {
    this.setState({ showConfirmation: true });
    setTimeout(() => {
      this.setState({ showConfirmation: false });
    }, 2000);
  };

  render() {
    return (
        <Mutation
          mutation={CHANGE_PASSWORD}
          onCompleted={data => {
            this.handleConfirmation();
          }}
        >
          {(changePassword, { loading, error }) => (
            <Formik
              initialValues={{
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
              }}
              validationSchema={Yup.object().shape({ ...validate })}
              onSubmit={values => {
                changePassword({
                  variables: {
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword
                  }
                });
              }}
            >
              {({ isSubmitting, handleReset, values, errors }) => (
                <Form className="register-form">
                  <div className="row">
                    <div className="col-12" />

                    <div className="col-sm-12">
                      <br />
                      <p>Change password</p>
                    </div>
                    <Fieldset
                      name="oldPassword"
                      type="password"
                      fieldClass="form-control"
                      fieldContainerClass="col-sm-12 mb-3"
                      placeholder="Old password"
                    />
                    <br />
                    <Fieldset
                      name="newPassword"
                      type="password"
                      fieldClass="form-control"
                      fieldContainerClass="col-sm-12 mb-3"
                      placeholder="New password"
                    />
                    <br />
                    <Fieldset
                      name="confirmPassword"
                      type="password"
                      fieldClass="form-control"
                      fieldContainerClass="col-sm-12 mb-3"
                      placeholder="Confirm new password"
                    />

                    <div className="col-sm-3">
                      <button type="submit" className="btn-black">
                        Save
                      </button>
                    </div>
                    <div className="col-sm-9">
                      {error && <Error error={error} />}
                      {this.state.showConfirmation && (
                        <h6>
                          <span className="badge badge-success">
                            Password updated
                          </span>
                        </h6>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Mutation>

    );
  }
}

export default EditPassword;
