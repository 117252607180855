import React from "react";
// import PropTypes from "prop-types";
import { withRouter } from "react-router";

import ResendVerification from "./ResendVerification";

const PleaseVerifyPage = ({ history }) => {
  return (
    <div
      style={{ height: "100vh" }}
      className="container d-flex align-content-center justify-content-center"
    >
      <div className="row">
        <div style={{ paddingTop: "2vh" }} className="col">
          <h3>please verify your account</h3>
          <p>a confirmation email has been sent</p>

          <hr />
          <p>re-send verification email</p>
          <ResendVerification history={history} />
        </div>
      </div>
    </div>
  );
};

// PleaseVerifyPage.propTypes = {
//   history: PropTypes.any.isRequired
// };

export default withRouter(PleaseVerifyPage);
