import React from "react";
import logo from "../../images/logo.png";
import loaderIco from "../../images/loader.svg";
import spinnerIco from "../../images/spinner.svg";

const LoadingBig = () => (
  <div className="loading">
    <img src={logo} alt="" />
    <h1>Loading</h1>
  </div>
);

const LoadingBtn = () => (
  <div className="float-right" style={{ textAlign: "center" }}>
    <img style={{ maxHeight: "36px" }} src={loaderIco} alt="" />
  </div>
);
const LoadingBtnLeft = () => (
  <div className="float-left" style={{ textAlign: "center" }}>
    <img style={{ maxHeight: "36px" }} src={loaderIco} alt="" />
  </div>
);

const spinnerStyle = {
  padding: "15% 0",
  textAlign: "center",
  margin: "auto"
};

const Spinner = () => (
  <div style={spinnerStyle}>
    <img src={spinnerIco} alt="" />
  </div>
);

const Elipsis = () => (
  <div className="elipsis">
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

export { LoadingBig, LoadingBtn, Spinner, LoadingBtnLeft, Elipsis };
