import React from "react";
import { Route, Switch } from "react-router-dom";
import PendinngUsersList from "./PendingUsersList";
import PendingUserDetails from "./PendingUserDetails";
import UsersNav from "./UsersNav";
import AllUsersList from "./AllUsers";
import AdminUserDetails from "./AdminUserDetails";
import AdminEditUserConatiner from "./AminEditUserContainer";
import DeletedUsers from "./DeletedUsers";
import DeletedUserDetails from "./DeletedUserDetails";

const UsersPageContainer = props => {
  return (
    <div className="row usersPage">
      <div className="col-sm-12" style={{ paddingTop: "20px" }}>
        <UsersNav />
      </div>

      <Switch>
        <Route
          exact
          path="/admin/users/new"
          render={props => (
            <>
              <div className="col-sm-4" style={{ paddingTop: "20px" }}>
                <h3>New Users</h3>
                <hr />
                <div className="row pendingAccounts-row">
                  <PendinngUsersList {...props} />
                </div>
              </div>
              <div className="col-sm-1" />
              <div className="col-sm-7" style={{ paddingTop: "20px" }}>
                <h3>User Details</h3>
                <hr />
                <br />
                <PendingUserDetails {...props} />
              </div>
            </>
          )}
        />
        <Route
          exact
          path="/admin/users/all"
          render={props => (
            <>
              <div className="col-sm-4" style={{ paddingTop: "20px" }}>
                <h3>All Users</h3>
                <hr />
                <div className="row pendingAccounts-row">
                  <AllUsersList {...props} />
                </div>
              </div>
              <div className="col-sm-1" />
              <div className="col-sm-7" style={{ paddingTop: "20px" }}>
                <h3>User Details</h3>
                <hr />
                <br />
                <PendingUserDetails {...props} />
              </div>
            </>
          )}
        />
        <Route
          exact
          path="/admin/users/deleted"
          render={props => (
            <>
              <div className="col-sm-5" style={{ paddingTop: "20px" }}>
                <h3>Deleted Users</h3>
                <hr />
                <div className="row pendingAccounts-row">
                  <DeletedUsers {...props} />
                </div>
              </div>
              <div className="col-sm-1" />
              <div className="col-sm-6" style={{ paddingTop: "20px" }}>
                <h3>User Details</h3>
                <hr />
                <br />
                <DeletedUserDetails {...props} />
              </div>
            </>
          )}
        />

        <Route
          exact
          path="/admin/users/user/:userId/edit"
          component={AdminEditUserConatiner}
        />
        <Route
          exact
          path="/admin/users/user/:userId"
          render={props => (
            <>
              <AdminUserDetails {...props} />
            </>
          )}
        />

        <Route
          render={props => (
            <>
              <div className="col-sm-4" style={{ paddingTop: "20px" }}>
                <h3>Deleted Users</h3>
                <hr />
                <div className="row pendingAccounts-row">
                  <PendinngUsersList {...props} />
                </div>
              </div>
              <div className="col-sm-1" />
              <div className="col-sm-7" style={{ paddingTop: "20px" }}>
                <h3>User Details</h3>
                <hr />
                <br />
                <DeletedUsers {...props} />
              </div>
            </>
          )}
        />
      </Switch>
    </div>
  );
};

export default UsersPageContainer;
