import React, { Component } from "react";
import { userSocialLinks } from "../dataNormalization/dataNormalization";

import TopBar from "../shared/TopBar";

import { AUTH_TOKEN } from "../../constants";
import { Mutation } from "react-apollo";
import SIGNUP_MUTATION from "../queries/signUpQuery";
import RegisterArtistForm from "./RegisterArtistForm";

class RegisterArtist extends Component {
  render() {
    return (
      <div className="register-container">
        <div className="container">
          <TopBar title="Register" step="Artist" />

          <Mutation
            mutation={SIGNUP_MUTATION}
            onCompleted={data => {
              this._confirm(data);
            }}
          >
            {(addUser, { loading, error }) => {
              return (
                <RegisterArtistForm
                  loading={loading}
                  error={error}
                  handleSubmit={submitedData => {
                    let variables = {
                      email: submitedData.email,
                      password: submitedData.password,
                      userName: submitedData.userName,
                      firstName: submitedData.firstName,
                      lastName: submitedData.lastName,
                      city: submitedData.city,
                      state: submitedData.state,
                      country: submitedData.country,
                      acceptUsage: submitedData.acceptUsage,
                      about: submitedData.about,
                      picture: submitedData.picture,
                      role: "Artist",
                      links: userSocialLinks.fromFormToVariables(
                        submitedData.links
                      )
                    };

                    if (submitedData.labelId !== "independent") {
                      variables = {
                        ...variables,
                        labelId: parseInt(submitedData.labelId)
                      }; //TODO: labelId -> string
                    }
                    addUser({ variables });
                  }}
                />
              );
            }}
          </Mutation>
        </div>
      </div>
    );
  }
  _confirm = async data => {
    const { token } = data.signUp;
    this._saveUserData(token);
    this.props.history.push(`/dashboard`);
  };

  _saveUserData = token => {
    localStorage.setItem(AUTH_TOKEN, token);
  };
}

export default RegisterArtist;
