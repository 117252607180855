import React from "react";
import PropTypes from "prop-types";

import { Plus } from "../../shared/SvgIcons";
import coverImage from "../../../images/artistCover.jpg";
import Territories from "./TerritoriesList";
import moment from "moment";

const ReleaseItem = props => {
  const {
    id,
    artist,
    title,
    cover,
    type,
    publicationDate,
    territories
  } = props;

  const addToNewReleasesCollection = () => {
    const release = { id, artist, title, cover, type, territories };
    props.addToNewReleasesCollection(release);
  };

  return (
    <div className={`row fxfItem`}>
      <div className="col-sm-10 d-flex ">
        <div
          style={{
            backgroundImage: `url("${cover || coverImage}")`,
            marginRight: "10px"
          }}
          className="fxfItem-cover"
        />
        <div style={{ flexGrow: 2 }}>
          <p className="fxfItem-title">{title}</p>
          <p className="fxfItem-artist">
            {" "}
            {artist ? artist.userName : "Error"}
          </p>
          <p style={{ fontSize: "12px", margin: "5px" }}>
            {" "}
            {moment(publicationDate)
              .utc()
              .format("M.D.YYYY")}{" "}
            <span className="fxfItem-type">{type}</span>{" "}
          </p>
          <Territories territories={territories} />
        </div>
      </div>
      <div className="col-sm-2 icons-col">
        <div className="fxfItem-icons float-right">
          <button
            onClick={e => addToNewReleasesCollection()}
            className="fxfIem-btn release"
          >
            <Plus icoClass="fxfIem-ico" />
          </button>
        </div>
      </div>
    </div>
  );
};

ReleaseItem.propTypes = {
  title: PropTypes.string,
  cover: PropTypes.string,
  artist: PropTypes.object,
  type: PropTypes.string
};

ReleaseItem.defaultProps = {
  title: "Title",
  cover: coverImage,
  artist: { userName: "ArtistName" },
  type: "Album"
};

export default ReleaseItem;
