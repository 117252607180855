import React from "react";
import ReactDom from "react-dom";

const modalRoot = document.getElementById("modal-root");

class FirstTimeAfterRelease extends React.Component {
  el = document.createElement("div");
  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDom.createPortal(
      <div clasname="vanModal-wrapper">
        <div className="vanModal-backdrop">
          <div className="vanModal-container">
            <div className="vanModal">
              {/* <div className="vanModal-header">
                <h5>Publish release</h5>
                <hr />
              </div> */}
              <div className="vanModal-body">
                {" "}
                <p>
                  populate all track- & release-level detail, ensure track titles have no stray metadata, and check playlist sequence. click ‘publish’ to put the release live as of its designated release date
                </p>
                <hr />
                <br />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }} className="vanModal-footer">
                <button className="btn-black" onClick={this.props.onHide}>
                  ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>,

      this.el
    );
  }
}

export default FirstTimeAfterRelease;
