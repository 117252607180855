import React, { Component } from "react";
// import queryString from "query-string";
import moment from "moment";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Spinner } from "../../shared/Loading";
import ReleaseItem from "./ReleaseItem";
// import TrackItem from "./TrackItem";

const GET_RELEASES = gql`
  query getReleasesQuery($publicationDate: DateTime) {
    getReleases(publicationDate: $publicationDate) {
      id
      title
      cover
      publicationDate
      createdAt
      type
      additionalAdminInfo
      territories
      artist {
        id
        userName
      }
    }
  }
`;

class NewReleasesList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addToNewReleasesCollection = release => {
    this.props.addToNewReleasesCollection(release);
  };

  render() {
    //const filter = this.props.filter.toLowerCase();

    // const dateFromProps = queryString.parse(this.props.location.search).date;
    // const urlFormat = "YYYY-MM-DD";
    // const displayFormat = "MMMM DD, YYYY";

    // const date = () =>
    //   dateFromProps
    //     ? moment(new Date(dateFromProps)).utc()
    //     : moment(Date.now()).utc();
    return (
      <Query
        query={GET_RELEASES}
        // variables={{ publicationDate: date().format(urlFormat) }}
      >
        {({ data, loading, error }) => {
          if (loading) return <Spinner />;
          if (error) return <p>Something goes wrong :( </p>;
          if (!data.getReleases.length) return <p>{`no published releases`}</p>;

          const releasesCollection = data.getReleases.sort((a, b) =>
            a.publicationDate < b.publicationDate ? 1 : -1
          );

          return releasesCollection.map((r, index) => {
            if (
              index !== 0 &&
              r.publicationDate ===
                releasesCollection[index - 1].publicationDate
            ) {
              return (
                <ReleaseItem
                  key={index}
                  {...r}
                  addToNewReleasesCollection={this.addToNewReleasesCollection}
                />
              );
            } else {
              return (
                <div key={index}>
                  <span
                    style={{
                      display: "block",
                      width: "100%",
                      textAlign: "right",
                      margin: "20px 0",
                      borderTop: "1px solid"
                    }}
                    className="timeline-sep"
                  >
                    {moment(new Date(r.publicationDate))
                      .utc()
                      .format("MM.DD.YYYY")}
                  </span>
                  <ReleaseItem
                    key={index}
                    {...r}
                    addToNewReleasesCollection={this.addToNewReleasesCollection}
                  />
                </div>
              );
            }
          });
        }}
      </Query>
    );
  }
}

export default NewReleasesList;
