import React, { Component } from "react";

import EditPass from "./EditPass";
import EditEmail from "./EditEmail";
import DeleteAccount from "./DeleteAccount";

class AccountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteOpen: false,
      name: ""
    };
  }
  render() {
    return (
      <div className="row">
        <div className="col-sm-6">
          <EditEmail email={this.props.me.email} />
        </div>

        <div className="col-sm-6">
          <EditPass />

          <br />
          <hr />

          <DeleteAccount
            name={this.props.me.userName}
            id={this.props.me.id}
            history={this.props.history}
          />
        </div>
      </div>
    );
  }
}

export default AccountSettings;
