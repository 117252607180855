import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { LoadingBtn } from "../../shared/Loading";
import { AUTH_TOKEN } from "../../../constants";
import { withRouter } from "react-router-dom";

const DELETE_ACCOUNT = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId)
  }
`;

const UserDeleteAccount = props => {
  const { title, id, onCancelDelete, history } = props;
  return (
    <div className="IndexModal-fixed">
      <div className="indexModal-header">
        <h3>Delete account?</h3>
        <hr />
      </div>
      <div className="IndexModal-body">
        <p style={{ fontSize: "18px" }}>{title}</p>
        <br />
        <hr />
      </div>
      <div className="indexModal-footer">
        <button onClick={e => onCancelDelete()} className="btn-bare">
          Close
        </button>

        <Mutation
          mutation={DELETE_ACCOUNT}
          awaitRefetchQueries={true}
          onCompleted={data => {
            localStorage.removeItem(AUTH_TOKEN);
            history.push("/");
          }}
        >
          {(deleteUser, { loading, error, data }) => {
            const variables = { userId: id };
            return (
              <>
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <button
                    onClick={e => deleteUser({ variables })}
                    type="button"
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                )}

                {error && (
                  <span style={{ position: "absolute", bottom: 0 }}>
                    Someting goes wrong :(
                  </span>
                )}
              </>
            );
          }}
        </Mutation>
      </div>
    </div>
  );
};

UserDeleteAccount.propTypes = {
  id: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  onCancelDelete: PropTypes.func.isRequired
};

export default withRouter(UserDeleteAccount);
