import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import SearchArtist from "./SearchExistingArtist";

const QUERY_ARTISTS = gql`
  {
    getUsers(role: Artist) {
      id
      userName
      email
      picture
      city
      state
    }
  }
`;

const SearchContainer = () => {
  return (
    <Query query={QUERY_ARTISTS}>
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        return <SearchArtist artists={[...data.getUsers]} />;
      }}
    </Query>
  );
};

export default SearchContainer;
