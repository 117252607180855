import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom'


import { ApolloProvider } from "react-apollo";
import client from './apollo.config'
import 'dropzone/dist/dropzone.css'



ReactDOM.render(
<ApolloProvider client={client}>
  <Router>
    <App />
  </Router>
</ApolloProvider>
  ,
  document.getElementById('root'));

serviceWorker.unregister();
