import React, { Component } from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

const FILEUPLOAD = gql`
  mutation($files: [Upload!]!) {
    uploadFiles(files: $files)
  }
`;

const CREATE = gql`
  mutation createRelease($tracks: [TrackInput]) {
    createRelease(
      release: {
        title: "t3"
        type: Album
        publicationDate: "07-15-2019"
        tracks: $tracks
        artistId: 118
        labelId: 13
      }
    ) {
      id
      title
      type
      publicationDate
      cover
      price
      territories
      status
      produced
      engineered
      mixed
      mastered
      studio
      designed
      photo
      thanks
      additionalCredits
      copyrightNote
      about
      tags

      duration
      createdAt
      updatedAt
    }
  }
`;

class Uploader2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col">
            <h4 style={{ marginTop: "30px" }}>Uploader</h4>
            <hr />
            <p>Files list</p>

            {Array.from(this.state.files).map((file, i) => {
              const { name, size, type } = file;
              const thumb = URL.createObjectURL(file);
              return (
                <p key={i}>
                  {name} {size} {type} <img src={thumb} alt="" />
                </p>
              );
            })}
          </div>
          <div className="col">
            <br />
            <form action="">
              <label htmlFor="files">files</label>
              <input
                id="files"
                multiple
                onChange={({ target: { validity, files } }) => {
                  this.setState({ files: files });
                }}
                type="file"
              />
            </form>
            <hr />
            <div className="row">
              <div className="col">
                <Mutation
                  mutation={FILEUPLOAD}
                  variables={{ files: this.state.files }}
                >
                  {mutate => (
                    <button
                      disabled={!Array.from(this.state.files).length}
                      onClick={e => mutate()}
                      className="btn-black"
                    >
                      Upload FileList
                    </button>
                  )}
                </Mutation>
                <code style={{ fontSize: "10px" }}>{`
                    mutation($files: [Upload!]!) {
                        uploadFiles(files: $files)
                    }`}</code>
              </div>
              <div className="col">
                <Mutation
                  mutation={FILEUPLOAD}
                  variables={{ file: this.state.files[0] }}
                >
                  {mutate => (
                    <button
                      disabled={!Array.from(this.state.files).length}
                      onClick={e => mutate()}
                      className="btn-black"
                    >
                      Upload one track
                    </button>
                  )}
                </Mutation>
                <code style={{ fontSize: "10px" }}>{`
                    mutation($file: Upload!) {
                        uploadFile(file: $file)
                    }`}</code>
              </div>
              <div className="col-sm-12">
                <hr />
                <Mutation
                  mutation={CREATE}
                  variables={{
                    tracks: [
                      {
                        title: "track one",
                        publicationDate: "07-15-2019",
                        trackFile: this.state.files[0]
                      }
                    ]
                  }}
                >
                  {create => (
                    <button
                      className="btn-black"
                      onClick={e => {
                        create();
                      }}
                    >
                      Create
                    </button>
                  )}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Uploader2;
