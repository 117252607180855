import React from 'react';


const FourByFourModal = (props) => {

    const {releases, onCacnel, onSave} = props
    return ( 


        <div className="fxfModal">
        <div className="fxfModal-heading">
          <h3>Save 4x4 selection</h3>
          <hr />
        </div>
        <div className="fxfModal-inner">
          <div className="fxfModal-releases">
            {releases && releases.map(r => (
              <div className="fxfModal-release">
                <div
                  style={{ backgroundImage: `url('${r.cover}')` }}
                  className="fxfRelease-box"
                >
                <p>{r.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        <div className="fxfModal-footer">
         
          <button onClick={onCacnel} className="fxfModal-close btn-bare">CANCEL</button>
          <button onClick={onSave} className="fxfModal-close btn-black">SAVE</button>
        </div>
      </div>
    


     );
}
 
export default FourByFourModal;