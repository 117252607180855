import React from "react";
import { Link, Switch, Route } from "react-router-dom";
import { Query } from "react-apollo";
import { Spinner } from "../../shared/Loading";
import ReleaseBox from "../../userDashboard/ReleaseBox";
import EditArtist from "../../editArtist/EditArtist";

import GET_ARTIST from "../../editArtist/labelArtistQuery";

const AdminArtistDetails = props => {
  const { artistId } = props.match.params;
  return (
    <div className="container">
      <Query query={GET_ARTIST} variables={{ id: artistId }}>
        {({ loading, error, data }) => {
          if (loading) return <Spinner />;
          if (error) return `Error! ${error.message}`;
          const {
            id,
            userName,
            release,
            email,
            picture,
            city,
            state,
            country,
            verified,
            about
          } = data.appLabelArtistDetail;
          return (
            <Switch>
              <Route
                exact
                path={`/admin/app/index/artist/:artistId/edit`}
                render={props => (
                  <>
                    <h1>Edit</h1>
                    <EditArtist {...data.appLabelArtistDetail} />
                  </>
                )}
              />

              <Route
                render={props => (
                  <div className="container">
                    <div style={{ marginTop: "20px" }} className="row">
                      <div className="col-sm-4">
                        <img src={picture} alt="" />
                        <br />
                        <br />
                        <h2>{userName}</h2>
                        <p>
                          <span className="font-weight-bold ">Location</span>{" "}
                          {city}, {state}, {country}
                        </p>
                        <p>
                          <span className="font-weight-bold ">Email</span>{" "}
                          {email}
                        </p>
                        <p>
                          <span className="font-weight-bold ">Status</span>{" "}
                          {verified ? "Verified" : "Not verified"}
                        </p>
                        <p>
                          <span className="font-weight-bold ">About</span>{" "}
                          {about}
                        </p>
                        <p>
                          <span className="font-weight-bold ">ID</span> #{id}
                        </p>
                        <br />
                        <br />
                        <Link
                          className="btn-black"
                          to={`${props.match.url}/edit`}
                        >
                          Edit artist details
                        </Link>
                      </div>
                      <div className="col-sm-1" />
                      <div className="col-sm-7">
                        <div className="row">
                          <div
                            className="col d-flex justify-content-between"
                            style={{
                              paddingBottom: "15px",
                              borderBottom: "1px solid #000"
                            }}
                          ></div>
                        </div>
                        <div className=" d-flex  flex-wrap">
                          {release.map(r => (
                            <ReleaseBox isAdmin={true} key={r.id} {...r} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              />
            </Switch>
          );
        }}
      </Query>
    </div>
  );
};

export default AdminArtistDetails;
