import { gql } from "apollo-boost";

export default gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      userName
      email
      zip
      city
      state
      country
      supportTier
      supportTotal
      role
      about
      picture
      verified
      links
      adminApproval
      frontendId
    }
  }
`;
