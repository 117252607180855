import gql from "graphql-tag";

const CURRENT_USER_QUERY = gql`
  query ME {
    me {
      id
      userName
      firstName
      lastName
      about
      state
      city
      picture
      email
      role
      country
      token
      verified
      links
      acceptUsage
      release {
        id
        title
        cover
        publicationDate
        status
      }
      artist {
        id
        role
        userName
        picture
      }
      label {
        id
        userName
      }
      paypal {
        selfUrl
        actionUrl
        merchantId
        merchantIdInPayPal
        permissionsGranted
        accountStatus
        consentStatus
        productIntentID
        isEmailConfirmed
        returnMessage
      }
    }
  }
`;

export default CURRENT_USER_QUERY;
