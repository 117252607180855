import { gql } from "apollo-boost";

const GET_ARTIST = gql`
  query appLabelArtistDetail($id: ID!) {
    appLabelArtistDetail(id: $id) {
      id
      frontendId
      userName
      email
      zip
      city
      state
      country
      about
      picture
      verified
      acceptUsage
      release {
        publicationDate
        id
        title
        cover
        status
      }
    }
  }
`;

export default GET_ARTIST;
