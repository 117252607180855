import React, { useState } from "react";
// import _sortBy from "lodash/sortBy";
import queryString from "query-string";

import { Query } from "react-apollo";
import GET_ALL_USERS from "./getAllUsersQuery";

const AllUsersList = props => {
  const [sort, setSort] = useState("id");
  const [order, setOrder] = useState(false);
  const activeId = queryString.parse(props.location.search).user;

  const handleFilter = filter => {
    if (filter === sort) {
      setOrder(!order);
    } else {
      setSort(filter);
      setOrder(false);
    }
  };

  const getSortedList = users => {
    const usersData = users.map(u => ({
      ...u,
      userName: u.userName.toLowerCase()
    }));
    return usersData.sort((a, b) =>
      order
        ? a[`${sort}`] < b[`${sort}`]
          ? -1
          : 1
        : b[`${sort}`] > a[`${sort}`]
        ? 1
        : -1
    );
  };

  return (
    <Query query={GET_ALL_USERS}>
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;

        const usersArr = data.getUsers
          .filter(user => user.userName !== null)
          .map(user => ({
            ...user,
            id: parseInt(user.id)
          }));

        return (
          <>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">
                    <button
                      onClick={e => handleFilter("id")}
                      className="btn-bare"
                    >
                      id
                    </button>
                  </th>
                  <th scope="col">
                    <button
                      onClick={e => handleFilter("userName")}
                      className="btn-bare"
                    >
                      Name
                    </button>
                  </th>
                  <th scope="col">
                    <button
                      onClick={e => handleFilter("role")}
                      className="btn-bare"
                    >
                      Type
                    </button>
                  </th>
                  <th scope="col">
                    <button
                      onClick={e => handleFilter("adminApproval")}
                      className="btn-bare"
                    >
                      Status
                    </button>
                  </th>
                </tr>
              </thead>
            </table>

            <div
              style={{
                maxHeight: "70vh",
                overflowY: "auto",
                width: "100%"
              }}
              className="scroll-tab"
            >
              <table style={{ fontSize: "12px" }} className="table table-hover">
                <tbody>
                  {getSortedList(usersArr).map(user => {
                    const {
                      id,
                      frontendId,
                      userName,
                      role,
                      adminApproval
                    } = user;

                    return (
                      <tr
                        style={{
                          cursor: "pointer",
                          border: `${
                            id === activeId ? "2px dotted #000" : "none"
                          }`
                        }}
                        className={`${adminApproval ? "approved" : "pennding"}`}
                        key={id}
                        onClick={e =>
                          props.history.replace("/admin/users/all?user=" + id)
                        }
                      >
                        <th scope="row">
                          {id} #{frontendId}
                        </th>
                        <td>{userName}</td>
                        <td>{role}</td>
                        <td>{adminApproval ? "Approved" : "Pending"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        );
      }}
    </Query>
  );
};

export default AllUsersList;
