import gql from "graphql-tag";

export default gql`
  mutation signUp(
    $email: EmailAddress!
    $password: String!
    $userName: String
    $firstName: String
    $lastName: String
    $city: String
    $state: String
    $country: String
    $role: UserRole
    $about: String
    $picture: String
    $labelId: Int
    $links: [URL]
    $acceptUsage: Boolean
  ) {
    signUp(
      email: $email
      password: $password
      userName: $userName
      firstName: $firstName
      lastName: $lastName
      city: $city
      state: $state
      country: $country
      role: $role
      about: $about
      picture: $picture
      labelId: $labelId
      links: $links
      acceptUsage: $acceptUsage
    ) {
      token
      id
    }
  }
`;
