import React from 'react'
import {  Field, ErrorMessage } from 'formik';

const Fieldset = ({ name, label, labelClass, fieldClass, fieldContainerClass, ...rest }) => (
    <React.Fragment>
      <label className={labelClass} htmlFor={name}>{label}</label>
      <div className={fieldContainerClass}>
        <span className="input-error"> <ErrorMessage name={name} /></span>
      <Field className={fieldClass} id={name} name={name} {...rest} />
      </div>
    </React.Fragment>
  );

  const FieldsetRow = ({ name, label, labelClass, fieldClass, fieldContainerClass, rowClass, ...rest }) => (
    
      <div style={{marginTop: "15px"}} className={`row ${rowClass}`}>
      <label className={labelClass} htmlFor={name}>{label}</label>
      <div className={fieldContainerClass}>
        <span className="input-error"> <ErrorMessage name={name} /></span>
      <Field className={fieldClass} id={name} name={name} {...rest} />
      </div>
      </div>


  );

  export {Fieldset, FieldsetRow}