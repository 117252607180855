import React from "react";
import AppContext from "../context/app-context";
import { Tape, Close, Check } from "../shared/SvgIcons";

import { Link } from "react-router-dom";

class ReleaseLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      releases: [

      ]
    };
  }

  static contextType = AppContext;

  toggleLoader = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { expanded } = this.state;
    return (
      <div className="releaseLoader-container">
        {expanded ? (
          <>
            <div className="releaseLoader-header">
              <p>
                UPLOADING RELEASES
                <button onClick={e => this.toggleLoader()} className="btn-bare">
                  <Close />
                </button>
              </p>
            </div>
            {this.context.releases.map((r, i )=> (
              <div key={i} className="release-item">
                {r.ready ? (
                  <>
                    <p>
                      <Check />
                      <Link to={`/dashboard/release/${r.id}`}> {r.title}</Link>
                    </p>
                    <button
                      onClick={e =>
                        this.context.onRemoveReleaseFromList(r.title)
                      }
                      className="btn-bare hide-release"
                    >
                      hide
                    </button>
                  </>
                ) : (
                  <p>
                    <Tape />
                   { r.title}
                   </p>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className="releaseLoader-collapsed">
            <button onClick={e => this.toggleLoader()} className="btn-bare">
              <Tape className={`lg ${this.context.releases.some(r => r.ready === false) && "rolling" } `} />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default ReleaseLoader;
