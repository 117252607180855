import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "apollo-link-error";
import { withClientState } from "apollo-link-state";
import { ApolloLink, Observable } from "apollo-link";
import { AUTH_TOKEN } from "./constants";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";

const cache = new InMemoryCache();

const request = async operation => {
  const token = await localStorage.getItem(AUTH_TOKEN);
  if (token && token.length > 0) {
    operation.setContext({
      headers: {
        authorization: token
      }
    });
  }
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const wsLink = new WebSocketLink({
  uri: `wss://api.thevan.wtf/graphql`,

  options: {
    reconnect: true,
    fetchOptions: { mode: "cors" },
    connectionParams: async () => ({
      authToken: await localStorage.getItem(AUTH_TOKEN)
    })
  }
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  createUploadLink({
    uri: process.env.REACT_APP_API,
    fetchOptions: { mode: "cors" }
  })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.log(graphQLErrors);
      }
      if (networkError) {
        console.log(networkError);
      }
    }),
    requestLink,
    withClientState({
      defaults: {
        isConnected: true,
        isAuth: false
      },
      resolvers: {
        Mutation: {
          updateNetworkStatus: (_, { isConnected }, { cache }) => {
            cache.writeData({ data: { isConnected } });
            return null;
          }
        }
      },
      cache
    }),
    link
  ]),
  cache
});

export default client;
