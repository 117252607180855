import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import coverImg from "../../../images/release.png";
import { Dots } from "../../shared/SvgIcons";
import DeleteArtistModal from "./DeleteArtistModal";

const IndexArtistItem = props => {
  const [artistDeleteModal, showArtistDeleteModal] = React.useState(false);

  const {
    id,
    userName,
    email,
    picture,
    city,
    state,
    label,
    onShowContact
  } = props;
  return (
    <div className="row">
      <div className="indexItem">
        <div className="indexItem-cover">
          <img src={picture} alt="" />
        </div>

        <div className="indexItem-details">
          <p className="indexItem-details__title">{userName} </p>
          <p className="indexItem-details__date">
            {state}, {city}
          </p>
          <p className="indexItem-details__date">#{id}</p>
        </div>
        <div className="indexItem-btn">
          <button className="btn-bare">
            {" "}
            <Dots />
          </button>
          <div className="indexItem-contextMenu">
            <Link to={`/admin/app/index/artist/${id}`} className="btn-bare">
              Edit artist
            </Link>
            <hr />
            <button
              onClick={e => onShowContact({ name: userName, email, label })}
              className="btn-bare"
            >
              Contact Admin
            </button>
            <hr />
            <button
              onClick={e => {
                showArtistDeleteModal(true);
              }}
              className="btn-red"
            >
              Delete User
            </button>
          </div>
        </div>
      </div>
      {artistDeleteModal && (
        <DeleteArtistModal
          id={id}
          title={userName}
          onCancelDelete={() => showArtistDeleteModal(false)}
        ></DeleteArtistModal>
      )}
    </div>
  );
};

IndexArtistItem.propTypes = {
  id: PropTypes.any.isRequired,
  userName: PropTypes.string.isRequired,
  picture: PropTypes.string,
  state: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  label: PropTypes.object
};

IndexArtistItem.defaultProps = {
  id: "999",
  userName: "artist name",
  picture: coverImg,
  state: "state",
  city: "city",
  label: { userName: "LabelName", email: "email" }
};

export default IndexArtistItem;
