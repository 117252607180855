import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import CreateArtistContainer from "../createArtist/CreateArtistContainer";

import { Query } from "react-apollo";

import CURRENT_USER_QUERY from "../queries/me";
import ReleaseContainer from "../addRelease/ReleaseContainer";
import Sidebar from "../dashboardSidebar/Sidebar";

import ReleasesList from "./ReleasesList";
import ArtistsList from "./ArtistsList";

import PleaseVerifyPage from "../home/PleaseVerifyPage";
import NotFound from "../errors/NotFound";
import SettingsContainer from "../userSettings/SettingsContainer";
import AddReleaseDetails from "../addRelease/AddReleaseDetails";
import AppContext from "../context/app-context";
import ReleaseLoader from "../releaseLoader/ReleaseLoader";
import ArtistContainer from "../editArtist/ArtistContainer";
// import LogOutBtn from "../login/LogOutBtn";
// import logo from "../../images/logo.png";
import DeletedUserSubscription from "../subscriptions/DeletedUserSubscription";
import SmallScreenBlockade from "./SmallScreenBlockade";

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = AppContext;

  render() {
    return (
      <Query fetchPolicy="network-only" query={CURRENT_USER_QUERY}>
        {({ loading, error, data, client }) => {
          if (loading) return "Loading...";
          if (error) {
            return <NotFound />;
          }

          if (window.innerWidth < 767) {
            return <SmallScreenBlockade />;
          }

          if (!data.me.verified) {
            return <PleaseVerifyPage />;
          }
          const { role, id } = data.me;

          return (
            <div className="dash-wrapper">
              <DeletedUserSubscription id={id} client={client} />
              <Sidebar history={this.props.history} />
              {this.context.releases.length >= 1 && <ReleaseLoader />}

              <div className="dash-main">
                <div className="dash-main__inner">
                  <Switch>
                    <Route
                      path="/dashboard/addrelease"
                      component={AddReleaseDetails}
                    />

                    <Route
                      path="/dashboard/release"
                      component={ReleaseContainer}
                    />

                    <Route
                      path="/dashboard/addArtist/"
                      render={props => (
                        <CreateArtistContainer
                          labelId={data.me.id}
                          name={data.me.userName}
                        />
                      )}
                    />

                    <Route
                      path="/dashboard/artist/:artistId"
                      component={ArtistContainer}
                    />
                    <Route
                      path="/dashboard/settings/"
                      component={SettingsContainer}
                    />
                    {role !== "Artist" && (
                      <Route
                        exact
                        path="/dashboard/artists"
                        component={ArtistsList}
                      />
                    )}

                    <Route
                      exact
                      render={props => <ReleasesList role={role} {...props} />}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default DashboardContainer;
