import React from "react";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { LoadingBtn } from "../../shared/Loading";

import GET_USER from "./getUserById";

const APPROVE_USER = gql`
  mutation adminUserApproval($userId: ID!) {
    adminUserApproval(userId: $userId) {
      id
      userName
      email
      zip
      city
      state
      country
      role
      about
      picture
      verified
      adminApproval
    }
  }
`;

const AdminApproveUser = props => {
  return (
    <Mutation
      mutation={APPROVE_USER}
      variables={{ userId: props.id }}
      refetchQueries={() => [{ query: GET_USER, variables: { id: props.id } }]}
    >
      {(adminUserApproval, { loading, error }) => {
        if (loading) return <LoadingBtn />;
        return (
          <button onClick={e => adminUserApproval()} className="btn-green">
            Approve user
          </button>
        );
      }}
    </Mutation>
  );
};

export default AdminApproveUser;
