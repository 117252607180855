import React, { Component } from "react";
import queryString from "query-string";
import moment from "moment";

import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Spinner } from "../../shared/Loading";
import TrackItem from "./TrackItem";

const GET_RELEASES = gql`
  query getReleasesQuery($publicationDate: DateTime) {
    getTracks(publicationDate: $publicationDate) {
      id
      title
      publicationDate
      release {
        id
        publicationDate
        title
        cover
        artist {
          id
          userName
        }
      }
    }
  }
`;

class NewTracksTrackList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPreReleased: true
    };
  }

  formatedPubDate = publicationDate =>
    moment(publicationDate, "YYYY-MM-DD").format("MMMM Do, YYYY");

  render() {
    const dateFromProps = queryString.parse(this.props.location.search).date;
    const today = moment(new Date().toISOString()).format("YYYY-MM-DD");

    // const urlFormat = "YYYY-MM-DD";
    // const displayFormat = "MMMM DD, YYYY";

    const selectedDate = () =>
      dateFromProps ? new Date(dateFromProps).toISOString() : today;

    return (
      <Query
        query={GET_RELEASES}
        variables={{ publicationDate: selectedDate() }}
      >
        {({ data, loading, error }) => {
          if (loading) return <Spinner />;
          if (error) return <p>Something goes wrong :( </p>;
          if (!data || !data.getTracks.length)
            return (
              <p>
                {`No tracks published on ${
                  dateFromProps
                    ? this.formatedPubDate(dateFromProps)
                    : this.formatedPubDate(new Date())
                }`}{" "}
              </p>
            );

          // return null;

          let tracksCollection = data.getTracks.map(t => {
            const {
              release: { artist, title, cover, territories, publicationDate }
            } = t;
            return {
              title: t.title,
              id: t.id,
              artist: artist,
              releaseTitle: title,
              cover: cover,
              publicationDate: t.publicationDate,
              releasePublicationDate: publicationDate,
              trackUrl: t.files ? t.files[1].url : null,
              territories: territories
            };
          });

          if (this.props.filterPreReleased) {
            tracksCollection = tracksCollection.filter(
              t => t.publicationDate !== t.releasePublicationDate
            );
          }

          return (
            <div style={{ paddingLeft: "15px" }} className="">
              {!tracksCollection.length && (
                <p>
                  {`No pre-released tracks published on ${
                    dateFromProps
                      ? this.formatedPubDate(dateFromProps)
                      : this.formatedPubDate(new Date())
                  }`}{" "}
                </p>
              )}
              {tracksCollection.flat().map(t => (
                <TrackItem key={t.id} {...t} />
              ))}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default NewTracksTrackList;
