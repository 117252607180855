import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

const USERS = gql`
  query getUsers {
    getUsers {
      id
      role
      verified
      country
      adminApproval
    }
  }
`;

class Users {
  constructor(usersArray) {
    this.users = usersArray;
  }

  formObject(type) {
    return this.users.reduce((acc, curr) => {
      if (!acc[curr[type]]) {
        acc[curr[type]] = {
          [type]: curr[type],
          num: 1
        };
      } else {
        acc[curr[type]] = {
          [type]: curr[type],
          num: acc[curr[type]].num + 1
        };
      }
      return acc;
    }, {});
  }

  formArray(object, type) {
    const array = [];
    const obj = object;
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        array.push({ [type]: key, num: obj[key].num });
      }
    }
    return array;
  }

  getStats(prop, sort) {
    const propObj = this.formObject(prop);
    let array = this.formArray(propObj, prop);

    if (sort) {
      array = array.sort((a, b) => (b[sort] > a[sort] ? 1 : -1));
    }
    return array;
  }

  roleByCountryOject(country) {
    return this.users
      .filter(u => u.country === country)
      .reduce((acc, curr) => {
        if (!acc[curr.role]) {
          acc[curr.role] = {
            role: curr.role,
            num: 1
          };
        } else {
          acc[curr.role] = {
            role: curr.role,
            num: acc[curr.role].num + 1
          };
        }

        return acc;
      }, {});
  }

  roleByCountryArray(country) {
    const array = [];
    const obj = this.roleByCountryOject(country);
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        array.push({ role: key, num: obj[key].num });
      }
    }
    return array;
  }

  countryByRoleObject(role) {
    return this.users
      .filter(u => u.role === role)
      .reduce((acc, curr) => {
        if (!acc[curr.country]) {
          acc[curr.country] = {
            country: curr.country,
            num: 1
          };
        } else {
          acc[curr.country] = {
            country: curr.country,
            num: acc[curr.country].num + 1
          };
        }

        return acc;
      }, {});
  }

  countryByRoleArray(role) {
    const array = [];
    const obj = this.countryByRoleObject(role);
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        array.push({ country: key, num: obj[key].num });
      }
    }
    return array;
  }

  get total() {
    return this.users.length;
  }

  get byCountries() {
    return {
      sorted: this.byCountriesArray().sort((a, b) => b.num - a.num)
    };
  }
}

const Statistics = props => {
  const [filter, setFilter] = React.useState("all");

  return (
    <Query query={USERS}>
      {({ loading, error, data }) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;

        const usersList = (filter = "all") => {
          let res;

          switch (filter) {
            case "all":
              res = data.getUsers;
              break;
            case "new":
              res = data.getUsers.filter(u => !u.verified);
              break;
            case "verified":
              res = data.getUsers.filter(u => u.verified);
              break;
            case "approved":
              res = data.getUsers.filter(u => u.adminApproval);
              break;
            case "verified and approved":
              res = data.getUsers.filter(u => u.verified && u.adminApproval);
              break;

            default:
              res = data.getUsers;
              break;
          }

          return res;
        };

        const usersData = new Users(usersList(filter));

        return (
          <div className="row">
            <div className="col-sm-12">
              <h2 style={{ margin: "20px 0 30px" }}>
                Users ({filter})
                <span
                  style={{ marginLeft: "15px" }}
                  className="badge badge-dark "
                >
                  {usersData.total}
                </span>
                <a
                  style={{ marginLeft: "40px" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://analytics.google.com"
                >
                  Google analytics
                </a>
              </h2>
              <div style={{ margin: "30px 0" }} class="btn-group" role="group">
                <button
                  onClick={() => setFilter("all")}
                  className={`btn ${
                    filter === "all" ? "btn-success" : "btn-dark"
                  }`}
                >
                  All
                </button>
                <button
                  onClick={() => setFilter("new")}
                  className={`btn ${
                    filter === "new" ? "btn-success" : "btn-dark"
                  }`}
                >
                  New
                </button>
                {/* <button
                  onClick={() => setFilter("verified")}
                  className={`btn ${
                    filter === "verified" ? "btn-success" : "btn-dark"
                  }`}
                >
                  Verified email
                </button>
                <button
                  onClick={() => setFilter("approved")}
                  className={`btn ${
                    filter === "approved" ? "btn-success" : "btn-dark"
                  }`}
                >
                  Approved by admin
                </button>
                <button
                  onClick={() => setFilter("verified and approved")}
                  className={`btn ${
                    filter === "verified and approved"
                      ? "btn-success"
                      : "btn-dark"
                  }`}
                >
                  verified and approved
                </button> */}
                {/* <button className="btn btn-dark">Approved & Verified</button> */}
              </div>
            </div>
            <div className="col-sm-6">
              <h4>Users by country </h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Country</th>
                    <th scope="col">Types</th>
                    <th scope="col">Total</th>
                    <th scope="col">%</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData.getStats("country", "num").map((country, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{country.country.substring(0, 22)}</td>
                        <td>
                          {usersData
                            .roleByCountryArray(country.country)
                            .map((c, j) => (
                              <span style={{ margin: "0 5px" }} key={j}>
                                {c.role} : {c.num}
                              </span>
                            ))}
                        </td>
                        <td>
                          <p className="font-weight-bold"> {country.num} </p>
                        </td>
                        <td>
                          {((country.num / usersData.total) * 100).toFixed(2)}%
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="col-sm-6">
              <h4>Users by type</h4>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">User type</th>
                    <th scope="col">Countries</th>
                    <th scope="col">Total</th>
                    <th scope="col">%</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData.getStats("role", "num").map((role, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{role.role.substring(0, 22)}</td>
                        <td>
                          {usersData
                            .countryByRoleArray(role.role)
                            .map((c, j) => (
                              <p style={{ margin: "0 5px" }} key={j}>
                                {c.country} : {c.num}
                              </p>
                            ))}
                        </td>
                        <td>
                          <p className="font-weight-bold">{role.num}</p>
                        </td>
                        <td>
                          {((role.num / usersData.total) * 100).toFixed(2)}%
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="col-sm-4" />
            <div className="col-sm-4" />
          </div>
        );
      }}
    </Query>
  );
};

export default Statistics;
