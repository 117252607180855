import React from "react";
import PropTypes from "prop-types";

import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { LoadingBtn } from "../../shared/Loading";
import GET_ALL_RELEASES from "./GET_ALL_RELEASES_QUERY";

const DELETE_USER = gql`
  mutation adminDeleteUser($userId: ID!) {
    adminDeleteUser(userId: $userId)
  }
`;

const DeleteArtistModal = props => {
  const { title, id, onCancelDelete } = props;
  return (
    <div className="IndexModal-fixed">
      <div className="indexModal-header">
        <h3>Delete Artist?</h3>
        <hr />
      </div>
      <div className="IndexModal-body">
        <p style={{ fontSize: "18px" }}>{title}</p>
        <br />
        <hr />
      </div>
      <div className="indexModal-footer">
        <button onClick={e => onCancelDelete()} className="btn-bare">
          Close
        </button>

        <Mutation
          mutation={DELETE_USER}
          refetchQueries={[{ query: GET_ALL_RELEASES }]}
          awaitRefetchQueries={true}
          onCompleted={data => {
            onCancelDelete(id);
          }}
        >
          {(deleteUser, { loading, error, data }) => {
            const variables = { userId: id };
            return (
              <>
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <button
                    onClick={e => deleteUser({ variables })}
                    type="button"
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                )}

                {error && (
                  <span style={{ position: "absolute", bottom: 0 }}>
                    Someting goes wrong :(
                  </span>
                )}
              </>
            );
          }}
        </Mutation>
      </div>
    </div>
  );
};

DeleteArtistModal.propTypes = {
  id: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  onCancelDelete: PropTypes.func.isRequired
};

export default DeleteArtistModal;
