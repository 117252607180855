import React, { Component } from "react";

// import { Mutation } from "react-apollo";
// import { gql } from "apollo-boost";

// import { AUTH_TOKEN } from "../../../constants";
import UserDeleteAccount from "./UserDeleteAccount";

// const DELETE_ACCOUNT = gql`
//   mutation deleteUser($userId: ID!) {
//     deleteUser(userId: $userId)
//   }
// `;

class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteOpen: false,
      name: ""
    };
  }
  render() {
    return (
      <div className="delete-account-wrapper">
        <button
          onClick={e =>
            this.setState(state => ({ isDeleteOpen: !state.isDeleteOpen }))
          }
          className="btn-red"
        >
          Delete account
        </button>
        <br />
        <br />

        {this.state.isDeleteOpen && (
          <UserDeleteAccount
            onCancelDelete={() => this.setState({ isDeleteOpen: false })}
            title="are you sure?"
            id={this.props.id}
          />
        )}
      </div>
    );
  }
}

export default DeleteAccount;
