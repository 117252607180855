import React from "react";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { LoadingBtn } from "../../shared/Loading";

const EDIT_ADMIN_NOTES = gql`
  mutation editAdminNotes($trackId: ID!, $additionalAdminInfo: String) {
    editTrack(
      trackId: $trackId
      track: { additionalAdminInfo: $additionalAdminInfo }
    ) {
      id
      additionalAdminInfo
    }
  }
`;

const EditAdminNotes = ({ notes, trackId }) => {
  const [adminNotes, setAdminNotes] = React.useState(notes);

  return (
    <div className="row">
      <div className="col-sm-12">
        <textarea
          style={{ minHeight: "100px", width: "100%" }}
          onChange={e => setAdminNotes(e.target.value)}
          value={adminNotes ? adminNotes : ""}
        />
      </div>
      <div className="col-sm-12">
        <Mutation
          variables={{ trackId: trackId, additionalAdminInfo: adminNotes }}
          //refetchQueries={[{ query: GET_NEW_RELEASES }]}
          mutation={EDIT_ADMIN_NOTES}
        >
          {(editTrack, { data, loading, error }) => {
            if (loading) return <LoadingBtn />;
            if (error) return `${error.message}`;
            return (
              <button onClick={editTrack} className="btn-black">
                Save
              </button>
            );
          }}
        </Mutation>
      </div>
    </div>
  );
};

const AdminTrackNotes = ({ trackId, additionalAdminInfo, title, cover }) => {
  return (
    <div className="row">
      <div className="col-sm-6">
        <img style={{ width: "120px" }} src={cover} alt="" />
        <p>
          <span>Title: </span> {title}
        </p>
      </div>
      <div className="col-sm-6">
        <p className="text-bold">
          <span>Id: </span> {trackId}
        </p>
      </div>
      <div className="col-sm-12">
        <hr />
        <p>Admin Notes</p>
        <EditAdminNotes trackId={trackId} notes={additionalAdminInfo} />
      </div>
    </div>
  );
};

export default AdminTrackNotes;
