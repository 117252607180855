import { gql } from "apollo-boost";

export default gql`
  query getCustomerReport {
    getCustomerReport {
      id
      merchantIdInPayPal
      type
      description
      paypalOrderId
      paypalStatus
      createdAt
      updatedAt
      artist
    }
  }
`;
