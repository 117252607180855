import React from "react";
import { Route, Switch } from "react-router-dom";

import AdminAppPageNav from "./AdminAppPageNav";
import AdminUs from "./us/AdminUs";
import IndexContainer from "./index/IndexContainer";
import HomeEditContainer from "./home/HomeEditContainer";
import FourByFourContainer from "./4x4/4x4Container";
import NewTracksContainer from "./newTracks/NewTracksContainer";
import NewReleasesContainer from "./newReleases/NewReleasesContainer";

class AdminAppPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="container-fluid adminApp-container">
        <AdminAppPageNav />
        <Switch>
          <Route path="/admin/app/new-tracks" component={NewTracksContainer} />
          <Route
            path="/admin/app/new-releases"
            component={NewReleasesContainer}
          />
          <Route path="/admin/app/4x4" component={FourByFourContainer} />
          <Route path="/admin/app/homepage" component={HomeEditContainer} />
          <Route path="/admin/app/us" component={AdminUs} />

          <Route component={IndexContainer} />
        </Switch>
      </div>
    );
  }
}

export default AdminAppPage;
