import React from "react";

import { CheckSquare } from "../shared/SvgIcons";

import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import GET_RELEASE from "../queries/getReleaseById";

const EDIT_TRACK = gql`
  mutation editTrackMutation($trackId: ID, $preRelease: Boolean) {
    editTrack(trackId: $trackId, track: { preRelease: $preRelease }) {
      id
      title
      trackOrder
      preRelease
    }
  }
`;

const EditTrackPrerelease = props => {
  return (
    <div className="preRelease-edit">
      <span style={{ paddingTop: "15px" }} className="pre flex-grow-1">
        Pre-Release:
        <Mutation
          mutation={EDIT_TRACK}
          variables={{ trackId: props.id, preRelease: !props.preRelease }}
          update={(cache, { data }) => {
            const { getRelease } = cache.readQuery({
              query: GET_RELEASE,
              variables: { releaseId: props.releaseId }
            });
            const theTracks = getRelease.tracks;
            const upload = theTracks.map(track => {
              if (track.id === props.id) {
                return { ...track, preRelease: data.editTrack.preRelease };
              }
              return track;
            });

            props.updateTracks(upload);

            cache.writeQuery({
              query: GET_RELEASE,
              variables: { releaseId: props.releaseId },
              data: { getRelease: { ...getRelease, tracks: upload } }
            });
          }}
        >
          {(editTrack, { loading, error }) => {
            if (loading)
              return (
                <button
                  style={{ minWidth: "65px" }}
                  className="btn-bare no-shadow"
                >
                  ...
                </button>
              );

            return (
              <button
                style={{ boxShadow: "none" }}
                onClick={e => e.preventDefault()}
                type="button"
                className="btn-bare no-shadow"
              >
                <CheckSquare active={props.preRelease} />
              </button>
            );
          }}
        </Mutation>
      </span>
    </div>
  );
};

export default EditTrackPrerelease;
