import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./bootstrap/dist/css/bootstrap.css";
import "./styles/styles.scss";

import AppContext from "./components/context/app-context";

import RegisterArtist from "./components/register/RegisterArtist";
import RegisterLabel from "./components/register/RegisterLabel";
import LabelDasboard from "./components/userDashboard/DashboardContainer";

import Terms from "./components/terms/Terms";
import userList from "./components/usersList/UsersList";

import NewPasswordForm from "./components/login/NewPasswordForm";
import Home from "./components/home/Home";
import NotFound from "./components/errors/NotFound";

import AdminDashboard from "./components/admin/AdminDashboard";
import StripeAuth from "./components/stripeAuth/StripeAuth";
import Uploader2 from "./Uploader2";
import CookiesContainer from "./components/cookies/Cookies";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      releases: [],
    };
  }

  componentDidMount() {}

  onHideReleaseLoader = () => this.setState({ releases: [] });

  onRemoveReleaseFromList = (title) => {
    const releasesList = this.state.releases.filter((r) => r.title !== title);
    this.setState({ releases: releasesList });
  };

  onAddReleaseToList = (release) => {
    this.setState((state) => ({ releases: [...state.releases, release] }));
  };

  setReleaseReady = (title, id) => {
    const releasesList = this.state.releases.map((r) => {
      if (r.title === title) {
        return { ...r, id: id, ready: true };
      }
      return r;
    });

    this.setState({ releases: releasesList });
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          releases: this.state.releases,
          onRemoveReleaseFromList: this.onRemoveReleaseFromList,
          onAddReleaseToList: this.onAddReleaseToList,
          setReleaseReady: this.setReleaseReady,
          hideReleaseLoader: this.onHideReleaseLoader,
        }}
      >
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/register" component={Home} />
            <Route exact path="/register/artist" component={RegisterArtist} />
            <Route exact path="/register/label" component={RegisterLabel} />
            <Route exact path="/reset-password" component={NewPasswordForm} />
            <Route exact path="/stripe-auth" component={StripeAuth} />
            <Route exact path="/verify" component={Home} />
            <Route exact path="/us/terms" component={Terms} />
            <Route path="/users" component={userList} />
            <Route path="/upload" component={Uploader2} />

            <Route path="/dashboard/label/:id" component={LabelDasboard} />
            <Route path="/dashboard/artist/:id" component={LabelDasboard} />
            <Route path="/dashboard" component={LabelDasboard} />
            <Route path="/admin" component={AdminDashboard} />

            <Route component={NotFound} />
          </Switch>
        </Router>
        <CookiesContainer />
      </AppContext.Provider>
    );
  }
}

export default App;
