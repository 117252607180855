import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import LogOutBtn from "../login/LogOutBtn";

const SidebarLinks = props => {
  return (
    <>
      <NavLink
        exact
        to="/dashboard"
        activeStyle={{ fontWeight: "bold" }}
        className="sidebar-link btn-bare"
      >
        Inventory
      </NavLink>
      <NavLink
        to="/dashboard/settings/details"
        activeStyle={{ fontWeight: "bold" }}
        className="sidebar-link btn-bare"
      >
        Settings
      </NavLink>
      <LogOutBtn history={props.history} />
    </>
  );
};

SidebarLinks.propTypes = {
  history: PropTypes.any.isRequired
};

export default SidebarLinks;
