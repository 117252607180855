import React from "react";
import queryString from "query-string";
import image from "../../../images/artistCover.jpg";

import { Query } from "react-apollo";
import { Spinner } from "../../shared/Loading";

import AdminDeleteUser from "./AdminDeleteUser";
import AdminApproveUser from "./AdminApproveUser";
import { Link } from "react-router-dom";

import GET_USER from "./getUserById";

const PendingUserDetails = props => {
  const id = queryString.parse(props.location.search).user;
  return (
    <div>
      {id && (
        <Query query={GET_USER} variables={{ id: id }}>
          {({ loading, error, data }) => {
            if (loading) return <Spinner />;
            if (error) return `Error! ${error.message}`;
            if (!data.getUser) return "user not found";

            const {
              id,
              userName,
              firstName,
              lastName,
              email,
              city,
              state,
              country,
              role,
              about,
              picture,
              links,
              adminApproval,
              frontendId
            } = data.getUser;
            return (
              <>
                <div className="row">
                  <div className="col-sm-6">
                    <p>
                      <span className="font-weight-bold">Id: </span>
                      {id} #{frontendId}
                    </p>
                    <p>
                      <span className="font-weight-bold">Account type: </span>
                      {role}
                    </p>
                    <p>
                      <span className="font-weight-bold">User name: </span>
                      {userName}
                    </p>
                    <p>
                      <span className="font-weight-bold">First name: </span>
                      {firstName}
                    </p>
                    <p>
                      <span className="font-weight-bold">Last name: </span>
                      {lastName}
                    </p>
                    <p>
                      <span className="font-weight-bold">Country: </span>
                      {country}
                    </p>
                    <p>
                      <span className="font-weight-bold">State: </span>
                      {state}
                    </p>
                    <p>
                      <span className="font-weight-bold">City: </span>
                      {city}
                    </p>
                    <p>
                      <span className="font-weight-bold">Email: </span>
                      {email}
                    </p>
                    <p>
                      <span className="font-weight-bold">Sign up date: </span>
                    </p>
                    <p>
                      <span className="font-weight-bold">Social links: </span>
                    </p>
                    <p>
                      <span className="font-weight-bold">Bio / About: </span>
                      {about}
                    </p>
                    <p>
                      <span className="font-weight-bold">Social Links: </span>
                      {links &&
                        links.map((link, i) => <span key={i}>{link}, </span>)}
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <img
                      style={{ padding: "10px", maxWidth: "270px" }}
                      src={picture || image}
                      alt=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    style={{
                      marginTop: "20px",
                      paddingTop: "10px",
                      borderTop: "1px solid #ccc"
                    }}
                    className="col-sm-7 d-flex justify-content-between"
                  >
                    {!adminApproval && <AdminApproveUser id={id} />}

                    <a
                      className="btn-black"
                      href={`mailto:${email}`}
                      target="_top"
                    >
                      Contact
                    </a>

                    <AdminDeleteUser history={props.history} id={id} />

                    <Link to={`/admin/users/user/${id}`} className="btn-black">
                      Edit User
                    </Link>
                  </div>
                </div>
              </>
            );
          }}
        </Query>
      )}
    </div>
  );
};

export default PendingUserDetails;
