import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import FourByFourPage from "./4x4";

const GET4x4 = gql`
  query get4x4Query {
    get4x4(date: "2019-04-15T00:00:00.000Z") {
      id
      releases {
        id
        title
        cover
        type
        publicationDate
        territories
        artist {
          id
          userName
        }
      }
    }
  }
`;

class FourByFourContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Query query={GET4x4}>
        {({ data, loading, error }) => {
          if (loading) return "loading";
          if (error) return "error";
          const releases = data.get4x4 ? data.get4x4.releases : [];

          return (
            <FourByFourPage
              isCollectionCreated={data.get4x4}
              releases={releases}
              {...this.props}
            />
          );
        }}
      </Query>
    );
  }
}

export default FourByFourContainer;
