import React from "react";
import queryString from "query-string";
import { Link } from "react-router-dom";

import { Mutation, Query } from "react-apollo";
import { gql } from "apollo-boost";

import CURRENT_USER_QUERY from "../queries/me";

import { LoadingBtn } from "../shared/Loading";
import logo from "../../images/logo.png";

const ADD_STRIPE_CODE = gql`
  mutation addStripeCode($code: String!) {
    addStripeCode(code: $code) {
      id
    }
  }
`;

const resetPassStyles = {
  marginTop: "11%",
  maxWidth: "90vw",
  width: "600px"
};

const RequireLoggIn = props => (
  <div className="container d-flex justify-content-center align-content-center ">
    <div
      style={{ height: "40vh" }}
      className="row justify-content-center align-content-center"
    >
      <div className="col text-center">
        <h3>You must be logged in</h3>
        <br />
        <Link className="btn-black" to={props.backTo}>
          LOGIN
        </Link>
      </div>
    </div>
  </div>
);

class StripeAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      succes: false
    };
  }
  render() {
    const token = queryString.parse(this.props.location.search).token;

    return (
      <Query fetchPolicy="network-only" query={CURRENT_USER_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return "";
          if (error) {
            return <RequireLoggIn backTo={"/" + this.props.location.search} />;
          }

          return (
            <Mutation
              onCompleted={data => {
                this.setState({ succes: "true" });
              }}
              mutation={ADD_STRIPE_CODE}
            >
              {(addStripeCode, { loading, error }) => (
                <div style={resetPassStyles} className="container">
                  <div className="row">
                    <div className="col">
                      <img src={logo} alt="" />
                      <p>Confirm your account</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <br />
                      {loading ? (
                        <div style={{ float: "left", width: "40px" }}>
                          <LoadingBtn />
                        </div>
                      ) : (
                        <button
                          className="btn-black"
                          onClick={e => {
                            addStripeCode({ variables: { code: token } });
                          }}
                        >
                          CONFIRM
                        </button>
                      )}
                      <br />
                      {error ? (
                        <p style={{ color: "red" }}>
                          Something went wrong :( Try again later
                        </p>
                      ) : (
                        ""
                      )}
                      {this.state.succes ? (
                        <p style={{ color: "green" }}>Succes! </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default StripeAuth;
