import React from "react";
import history from "../../router/history";
// import CloseImg from "../../images/close.png";
import { ChevronLeft } from "./SvgIcons";
import { withRouter } from "react-router-dom";

const TopBar = props => {
  const onCancel = () => {
    if (props.onCancel) props.onCancel();
    else history.goBack();
  };

  const isDetails = props.history.location.pathname.includes("details");
  const isCredits = props.history.location.pathname.includes("credits");
  const isTrackCredits = props.history.location.pathname.includes("track");
  const isSummary =
    props.match.path === "/dashboard/release" &&
    !props.history.location.pathname.includes("credits") &&
    !props.history.location.pathname.includes("track") &&
    !props.history.location.pathname.includes("details");

  return (
    <div className="row register-top align-items-center justify-content-between no-gutters">
      <div className="col-sm-10">
        <h4 className="register-top__title">
          {`${props.title.length ? props.title + ":" : ""}`}{" "}
          {props.bold ? props.step : <span>{props.step}</span>}{" "}
          {isDetails && <span>Details</span>}
          {isCredits && <span>Credits</span>}
          {isSummary && <span>Summary</span>}
          {isTrackCredits && <span>Track Credits</span>}
        </h4>
      </div>
      <div className="col">
        <button
          style={{ paddingRight: "50px" }}
          onClick={onCancel}
          className="btn-ico btn-back float-right"
        >
          <ChevronLeft width="35" />
        </button>
      </div>
    </div>
  );
};

export default withRouter(TopBar);
