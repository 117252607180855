import React from "react";
import ReactDom from "react-dom";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import GET_RELEASE from "../queries/getReleaseById";
import TextError from "../shared/Errors";

const EDIT_RELEASE_STATUS = gql`
  mutation editReleaseDetailsMutation($releaseId: ID, $status: StatusType) {
    editRelease(releaseId: $releaseId, release: { status: $status }) {
      id
      title
      status
    }
  }
`;

const modalRoot = document.getElementById("modal-root");

class PublishModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  el = document.createElement("div");
  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDom.createPortal(
      <div clasname="vanModal-wrapper">
        <div className="vanModal-backdrop">
          <div className="vanModal-container">
            <div className="vanModal">
              <div className="vanModal-header">
                <h5>Publish release</h5>
                <hr />
              </div>
              <div className="vanModal-body">
                {" "}
                <p>
                  before publishing, check avail dates on release & track
                  levels, and all metadata
                </p>
                <p>
                  publishing dates cannot be modified after a release is
                  published
                </p>
                <hr />
                <br />
              </div>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="vanModal-footer"
              >
                <button className="btn-black" onClick={this.props.onHide}>
                  cancel
                </button>

                <Mutation
                  mutation={EDIT_RELEASE_STATUS}
                  onCompleted={data => {
                    this.props.onHide();
                  }}
                  refetchQueries={[
                    {
                      query: GET_RELEASE,
                      variables: { releaseId: this.props.releaseId }
                    }
                  ]}
                >
                  {(editReleaseStatus, { loading, error, data }) => {
                    if (error) return <TextError error={error} />;
                    return (
                      <button
                        style={{
                          minWidth: "120px",
                          backgroundColor: "green"
                        }}
                        onClick={() =>
                          editReleaseStatus({
                            variables: {
                              releaseId: this.props.releaseId,
                              status: "Published"
                            }
                          })
                        }
                        className="btn-black  float-right"
                      >
                        {loading ? `...` : "PUBLISH"}
                      </button>
                    );
                  }}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
      </div>,

      this.el
    );
  }
}

export default PublishModal;
