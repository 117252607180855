import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { Fieldset } from "../shared/formFields";
import { LoadingBtn } from "../shared/Loading";
import { getUrlParameter } from "../../utils/utils";
import { FRONT_WEBSITE } from "../../constants";
import logo from "../../images/logo.png";

const RESET_PASS = gql`
  query resetPasswordForm(
    $email: EmailAddress!
    $newPassword: String!
    $token: String!
  ) {
    resetPasswordForm(email: $email, newPassword: $newPassword, token: $token)
  }
`;

const resetPassStyles = {
  marginTop: "11%",
  maxWidth: "90vw",
  width: "600px"
};

class NewPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      runQuery: false,
      error: false,
      loading: false,
      message: null
    };
  }

  render() {
    const { runQuery, error, loading } = this.state;
    const isCreatedbyLabel = getUrlParameter("new");

    return (
      <div style={resetPassStyles} className="container">
        <img
          style={{
            width: "30px",
            position: "absolute",
            right: "30px",
            top: "30px"
          }}
          src={logo}
          alt=""
        />
        <div className="row">
          <div className="col">
            {isCreatedbyLabel ? (
              <h1>Set your password</h1>
            ) : (
              <h1>Reset your password</h1>
            )}
          </div>
        </div>

        <div className="login-form__container">
          <Formik
            initialValues={{
              email: "",
              newPass: ""
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email address")
                .required("Required"),
              newPass: Yup.string()
                .required("Password is required")
                .min(6, "Password is too short - should be 6 chars minimum.")
            })}
            onSubmit={values => {
              this.setState({ runQuery: true });
            }}
            render={({ isSubmitting, handleReset, values, errors }) => (
              <>
                <Form>
                  <div className="form-group row">
                    <Fieldset
                      name="email"
                      type="email"
                      label="email"
                      fieldClass="form-control"
                      labelClass="col-sm-3 col-form-label login"
                      fieldContainerClass="col-sm-9"
                    />
                  </div>
                  <div className="form-group row">
                    <Fieldset
                      name="newPass"
                      type="password"
                      label="new pw"
                      fieldClass="form-control"
                      labelClass="col-sm-3 col-form-label  login"
                      fieldContainerClass="col-sm-9"
                    />
                  </div>
                  <div className="row">
                    <div className="col">
                      {loading ? (
                        <LoadingBtn />
                      ) : (
                        <button
                          disabled={false}
                          className="btn-black float-right"
                          type="submit"
                        >
                          Submit
                        </button>
                      )}
                      {this.state.message && (
                        <span style={{ color: "green", fontSize: "12px" }}>
                          password updated
                        </span>
                      )}
                      {error && <span>Something goes wrong</span>}
                    </div>
                  </div>
                </Form>

                {runQuery && (
                  <Query
                    query={RESET_PASS}
                    variables={{
                      email: values.email,
                      newPassword: values.newPass,
                      token: getUrlParameter("token")
                    }}
                    onCompleted={data => {
                      this.setState({
                        runQuery: false,
                        message: "Your password has been updated"
                      });

                      if (getUrlParameter("role") === "User")
                        setTimeout(() => {
                          window.location = FRONT_WEBSITE;
                        }, 3000);
                      else
                        setTimeout(() => {
                          this.props.history.push("/");
                        }, 3000);
                    }}
                  >
                    {({ data, Qerror, Qloading }) => {
                      if (Qloading) return this.setState({ loading: true });
                      if (Qerror) return this.setState({ error: true });

                      return null;
                    }}
                  </Query>
                )}
              </>
            )}
          />
        </div>
      </div>
    );
  }
}

export default NewPasswordForm;
