import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Spinner } from "../../shared/Loading";
import NewTracksPage from "./NewTracks";

const GETNEWTRACKS = gql`
  query newTracks($date: DateTime) {
    getNewTracks(date: $date) {
      id
      tracks {
        id
        publicationDate
        release {
          id
          territories
        }
      }
    }
  }
`;

const NewTracksContainer = props => {
  return (
    <Query
      query={GETNEWTRACKS}
      variables={{ date: "2019-04-15T00:00:00.000Z" }}
    >
      {({ data, loading, error }) => {
        if (loading) return <Spinner />;
        if (error) return "Get new tracks collection error";

        const tracks = data.getNewTracks
          ? data.getNewTracks.tracks.map(t => t.id)
          : [];

        return (
          <NewTracksPage
            isListCreated={data.getNewTracks}
            tracks={tracks}
            {...props}
          />
        );
      }}
    </Query>
  );
};

export default NewTracksContainer;
