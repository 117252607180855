import React from "react";
import queryString from "query-string";
import LoginForm from "./LoginForm";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";

import { AUTH_TOKEN } from "../../constants";

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: EmailAddress!, $password: String!) {
    login(email: $email, password: $password) {
      token
      id
      role
      email
      verified
    }
  }
`;

const LoginUser = props => {
  const back = queryString.parse(props.location.search).token;

  const _confirm = async data => {
    const { token, verified, /* email, */ role } = data.login;
    await _saveUserData(token);

    if (role === "User") {
      props.history.push(`?message="nope"`);
      return;
    }

    if (role === "Admin") {
      props.history.push(`/admin/`);
      return;
    }

    if (verified || verified) {
      if (back) {
        props.history.push(`/stripe-auth?token=${back}`);
      } else {
        props.history.push(`/dashboard`);
      }
    } else {
      props.history.push(`/verify`);
    }
  };

  const _saveUserData = token => {
    localStorage.setItem(AUTH_TOKEN, token);
  };

  return (
    <Mutation
      mutation={LOGIN_MUTATION}
      onCompleted={data => {
        _confirm(data);
      }}
    >
      {(loginUser, { loading, error }) => (
        <>
          {back && <p>{back}</p>}
          <LoginForm
            showReset={props.showReset}
            loading={loading}
            error={error}
            handleSubmit={submitedData => {
              loginUser({ variables: { ...submitedData } });
            }}
          />
        </>
      )}
    </Mutation>
  );
};

export default LoginUser;
